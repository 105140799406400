import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { get_ticket_id, getrole } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-collector-collect',
  templateUrl: './collector-collect.component.html',
  styleUrls: ['./collector-collect.component.css']
})
export class CollectorCollectComponent implements OnInit {

  constructor(private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService, private Activated_route: ActivatedRoute) { }

  closed: boolean = false;
  collectable: any

  ngOnInit(): void {
    this.get_ticket()
  }

  validateCode(code) {
    const regex = /^[A-Z0-9]{7}$/;
    return regex.test(code);
  };

  get_ticket() {
    Swal.fire({
      title: 'Enter ticket number',
      input: 'text',
      inputAttributes: {
      },
      inputLabel: "Beekeeper's Ticket Number",
      showCancelButton: false,
      confirmButtonText: "Look up",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      inputValidator: (value) => {
        if (!this.validateCode(value)) {
          return 'Invalid Ticket Id!'
        }
      },
      preConfirm: (login) => {
        this.closed = true
        this.appCoreService.list_collectables_by_id(login).subscribe(data => {
          this.collectable = data
        })
      },
      didClose: () => {
        if (!this.closed) {
          console.log("hi")
          if (getrole() === 'admin') {
            this.router.navigateByUrl('/tickets/collector')
          } else {
            this.router.navigateByUrl('/collector')
          }
        } else {
          Swal.showLoading()
          this.closed = false
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          Swal.close()
          let message = this.collectable.message
          if (this.collectable.status === 200) {
            this.collectable = this.collectable.data[0]
            Swal.fire({
              icon: 'success',
              title: `${message}`
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: `${message}`
            }).then(() => this.get_ticket())
          }

        }, 2000);
      } else if (result.isDenied) {
        Swal.fire({
          title: "Please Enter valid ticket!",
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "retry",
          cancelButtonColor: '#880000',
          cancelButtonText: "go back"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.close()
            this.get_ticket()
          } else {
            Swal.close()
            if (getrole() === 'admin') {
              this.router.navigateByUrl('/tickets/collector')
            } else {
              this.router.navigateByUrl('/collector')
            }
          }
        })
      }
    })
  }


  collect() {
    let sendable = {
      ticket_id: get_ticket_id(),
      collectable_ticket_id: this.collectable.ticket_id
    }
    this.appCoreService.collect_colelctables(sendable).subscribe((data) => {
      if (data.status === 200) {
        Swal.fire({
          title: data.message,
          icon: 'success'
        }).then(() => {
          if (getrole() === 'admin') {
            this.router.navigateByUrl('/tickets/collector')
          } else {
            this.router.navigateByUrl('/collector')
          }
        })
      } else {
        Swal.fire({
          title: data.message,
          icon: 'error'
        })
      }
    })
  }
}
