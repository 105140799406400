export function getToken(): any {
  const data = localStorage.getItem('accessToken');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getcollectable(): any {
  const data = localStorage.getItem('collectable');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function setcollectable(val: string): void {
  localStorage.setItem('collectable', val);
}

export function get_ticket(): any {
  const data = localStorage.getItem('ticket');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function set_ticket(val: string): void {
  localStorage.setItem('ticket', val);
}
export function set_ticket_id(val: string): void {
  localStorage.setItem('ticket_id', val);
}
export function setToken(token: string): void {
  localStorage.setItem('accessToken', token);
}
export function setguest(token: string): void {
  localStorage.setItem('guest', 'true');
}
export function setUser(val: string): void {
  localStorage.setItem('username', val);
}
export function setUserId(val: string): void {
  localStorage.setItem('user_Id', val);
}
export function setRole(val: string): void {
  localStorage.setItem('role', val);
}
export function setProfile_pic(val: string): void {
  localStorage.setItem('profile', val);
}
export function setProfile(val: string): void {
  console.warn('setting profile')
  localStorage.setItem('c_profile', val);
}
export function getUser(): string | null {
  const data = localStorage.getItem('username');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}

export function get_ticket_id(): string | null {
  const data = localStorage.getItem('ticket_id');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getguest(): string | null {
  const data = localStorage.getItem('guest');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getrole(): string | null {
  const data = localStorage.getItem('role');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getUserId(): any | null {
  const data = localStorage.getItem('user_Id');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getProfile_pic(): any | null {
  const data = localStorage.getItem('profile');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}
export function getprofile(): any | null {
  const data = localStorage.getItem('c_profile');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}

export function removeToken(): void {
  localStorage.removeItem('accessToken');
}
export function removeCollectable(): void {
  localStorage.removeItem('collectable');
}
export function remove_ticket(): void {
  localStorage.removeItem('ticket');
}
export function removeticket_id(): void {
  localStorage.removeItem('ticket_id');
}
export function getTokenType(): string | null {
  const data = localStorage.getItem('accessToken');
  if (data && data !== 'null') {
    return data;
  } else {
    return null;
  }
}

export function setTokenType(type: string): void {
  localStorage.setItem('accessToken', type);
}

export function removeTokenType(): void {
  localStorage.removeItem('accessToken');
}