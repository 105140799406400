<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">Honey Batch Analysis</h2>

        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span><br>

        <div style="padding: 0px 5vh;text-align: center;">
            <span *ngIf="current_ticket.page_6_section_1">{{page_6_section_1}}</span>
            <span *ngIf="!current_ticket.page_6_section_1" class="batch_title" style="font-size: 15px;">
                Previous lots have been collected, tested for authenticity then released for homogenization. Following
                are the analysis of the homogenized batch
            </span>
        </div>
        <div class="container" style="height: 999px;padding: 20px;cursor: pointer;" (click)="openModal()">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"
                                    style="display: flex; justify-content: baseline; align-items: baseline; flex-grow: 1;">
                                    Parameter </th>
                                <th role="columnheader" scope="col" tabindex="0">Value</th>
                                <th role="columnheader" scope="col" tabindex="0">Standard </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup" *ngIf="current_ticket">
                            <tr role="row">
                                <td>HMF</td>
                                <td>{{current_ticket.hmf}}</td>
                                <td>&lt; Max 10</td>
                            </tr>
                            <tr role="row">
                                <td>Moisture</td>
                                <td>{{current_ticket.moister}}%</td>
                                <td>18.5% - 19.5%</td>
                            </tr>
                            <tr role="row">
                                <td>Color</td>
                                <td>{{current_ticket.color}}</td>
                                <td>&lt; NA</td>
                            </tr>
                            <tr role="row">
                                <td colspan="3">Veiw more...</td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>