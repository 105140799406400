import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-four',
  templateUrl: './page-four.component.html',
  styleUrls: ['./page-four.component.css']
})
export class PageFourComponent implements OnInit {


  result: string[]
  ticket_id: number
  @Input() ticket: any;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticket'].currentValue) {
      this.result = this.ticket.media_used.split(',');
      console.log(this.result, "ticket four")
    }
  }


  ngOnInit(): void {
  }

}
