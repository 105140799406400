<div class="h-90">
  <div class="d-flex justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <form [formGroup]="loginform" (ngSubmit)="onlogin()">
              <h5 class="modal-title">
                <h4 class="mt-2">
                  <div>Welcome,</div>
                  <span>It only takes a <span class="" style="color:#987b59">few seconds</span> to create your account</span>
                </h4>
              </h5>
              <div class="divider"></div>
              <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                class="mb-3">
                <div>
                  <input id="exampleInput1" type="email" placeholder="Enter email..." formControlName="email"
                    required="required" aria-required="true" class="form-control"
                    aria-describedby="exampleInputGroup1__BV_description_">
                  <p class="text-danger" style="float: right;" *ngIf="!ismailValid() && submitted"> email is required *
                  </p>
                </div>
              </div>
              <div id="exampleInputGroup12" role="group" class="mb-3">
                <div>
                  <input id="exampleInput12" type="text" placeholder="Enter Full Name..." formControlName="fullname"
                    required="required" aria-required="true" class="form-control">
                  <p class="text-danger" style="float: right;" *ngIf="!isfullnameValid() && submitted"> fullname is
                    required
                    *</p>

                </div>
              </div>
              <div id="exampleInputGroup12" role="group" class="mb-3">
                <div>
                  <input id="exampleInput12" type="text" placeholder="Enter username..." formControlName="username"
                    required="required" aria-required="true" class="form-control">
                  <p class="text-danger" style="float: right;" *ngIf="!isusernameValid() && submitted">username is
                    required
                    *</p>

                </div>
              </div>
              <div id="exampleInputGroup12" role="group" class="mb-3">
                <b class="d-block mb-1">Select role</b>
                <ng-select [items]="roles" bindLabel="name" placeholder="Select city" [(ngModel)]="selectedrole"
                  [ngModelOptions]="{standalone: true}">
                </ng-select>
                <p class="text-danger" style="float: right;" *ngIf="!isroleValid() && submitted"> roles are required *
                </p>

              </div>
              <div class="row" style="margin-top: 40px;">
                <div class="col-md-6">
                  <div id="exampleInputGroup2" role="group" class="mb-3">
                    <div>
                      <input id="exampleInput2" type="password" placeholder="Enter password..."
                        formControlName="password" required="required" aria-required="true" class="form-control">
                      <p class="text-danger" *ngIf="!ispasswordValid() && submitted"> password is
                        required *</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div id="exampleInputGroup2" role="group" class="mb-3">
                    <div>
                      <input id="exampleInput2" type="password" placeholder="repeat password..."
                        formControlName="repeat_password" required="required" aria-required="true" class="form-control">
                      <p class="text-danger" *ngIf="!isrepeatValid() && submitted">Confirm password not valid *</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-control custom-checkbox form-check" style="display: inline-flex;justify-content: center;width:100%;padding:0px !important">
                <label for="exampleCheck" class="form-label form-check-label" style="text-align: center;">
                  by submitting this form you are agreeing to Accept our <br><a [routerLink]="">Terms and Conditions</a>.
                </label>
              </div>
              <div class="modal-footer d-block text-center">
                <button type="submit"
                  class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-lg"  style="background-color: #987b59;color: #fff;">Create
                  Account
                </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>