import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getrole } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  constructor(private route: Router) { }

  role: string = getrole()
  ngOnInit(): void {
    console.log(this.get_title())
  }

  get_title(): string[] {
    if (this.role === 'admin') {
      switch (this.route.url) {
        case "/tickets/farmer":
          return ["Bee keeper tickets", "Manage all your Beekeepers tickets here"]
        case "/tickets/farmer/init-collectable":
          return ["Create a ticket", "Create new tickets here!"]
        case "/tickets/farmer/update-collectable":
          return ["Update ticket", "Update created tickets here!"]
        case "/tickets/farmer/privacy":
          return ["Privacy & policy", "By using the following services you agree to these terms!"]
        default:
          return ["Unknown route", "Wrong url entered!"]
      }
    }
    switch (this.route.url) {
      case "/farmer":
        return ["Tickets Initiated", "Manage all your tickets here"] 
      case "/farmer/init-collectable":
        return ["Create a ticket", "Create new tickets here!"]
      case "/farmer/update-collectable":
        return ["Update ticket", "Update created tickets here!"]
      case "/farmer/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }

  }
}
