<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">Honey Lab Certificate</h2>
        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span><br>

        <div *ngIf="current_ticket" class="custom-scroll" style="overflow: scroll;height: 45vh;">
            <div *ngFor="let m of current_ticket.pdfs_collector" >
                <button class="btn btn-ghost">
                    <img *ngIf="!m.includes('.pdf')" [src]="m" alt="media" style="height: auto;min-width: 80vw;max-width: 80vw;">
                    <pdf-viewer *ngIf="m.includes('pdf')" [src]="m" [rotation]="0" [original-size]="false"
                        [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                        [autoresize]="true" [show-borders]="false" style="width:80vw;max-width: 100vw; height: 500px;">
                    </pdf-viewer>
                </button>
            </div>
        </div>
    </div>
</div>