<div>

</div>
<button class="btn bg-1" *ngIf="role != 'admin'"
    style="border-radius: 20px; font-size:medium;padding:10px 20px !important"
    routerLink="/farmer/init-collectable">Initiate new
    ticket
</button>
<div style="display: flex;flex-direction: column;">
    <div style="float: right; width:100%;text-align: right;margin-right: 5px; margin-bottom: 20px;">
        <input matInput class="form-control" style="width: 25%; float: right;" (keyup)="applyFilter($event)"
            placeholder="Filter...">
    </div>

    <div class="example-container mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource2" *ngIf="data">
            <ng-container matColumnDef="ticket_id">
                <mat-header-cell *matHeaderCellDef> Ticket ID</mat-header-cell>
                <mat-cell *matCellDef="let row" title="copy ticket Id">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;"
                        (click)="copy(row.ticket_id)">
                        <p style="color: #5c5757;font-size: medium;font-weight: 600;">{{row.ticket_id}}</p>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="farmer_id">
                <mat-header-cell *matHeaderCellDef> Beekeeper Id</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.farmer_id}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_origin">
                <mat-header-cell *matHeaderCellDef> Honey Origin</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.honey_origin}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_weight">
                <mat-header-cell *matHeaderCellDef>Honey Weight</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.honey_weight}} kg</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_kind">
                <mat-header-cell *matHeaderCellDef>Honey Type</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.honey_kind}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">
                            <button class="btn-shadow btn btn-pill ps-3"
                                [ngClass]="row.status==='000'?'bg-2':'bg-1'">{{row.status==="000"?"Available":"collected"}}</button>
                        </p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 0px;">
                        <button class="btn btn-danger" *ngIf="row.status === '000'"
                            (click)="delete_collectables(row.ticket_id)"><fa-icon [icon]="delete"></fa-icon></button>
                        <button class="btn bg-3" *ngIf="row.status==='000'" (click)="update_collectable(row)"><fa-icon
                                [icon]="edit"></fa-icon></button>
                        <button class="btn bg-3" *ngIf="row.status === '111'">Recorded on blockchain</button>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <mat-header-cell *matHeaderCellDef>Time Stamp</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{formattedTimestamp(row.timestamp)}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <h1 *ngIf="!data">{{table_status}}</h1>
        <div class="card main-card mb-3">
            <div class="card-body">
                <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>