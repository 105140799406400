<div class="h-100">
  <div class="row h-100 g-0">
    <div class="d-none d-lg-block col-lg-4" style="background-image: url('../../../assets/images/originals/abstract.jpg');background-position: center;
    background-size: cover;
    background-repeat: no-repeat;">
      <div class="slider-light">
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8" *ngIf="!token">
      <div class="mx-auto app-login-box col-sm-12 col-md-8 col-lg-6">
        <div class="app-logo"></div>

        <h4>
          <img src="../../../assets/images/Alshifa_Logo.png" alt="logo main" width="150px"
            style="position:relative;left:-15px ;">

          <div>Forgot your Password?</div>
          <span>Use the form below to recover it.</span>
        </h4>
        <div>
          <form class="card-form" [formGroup]="sendlink" (ngSubmit)="onsubmit()">
            <div class="row">
              <div class="col-md-12">
                <fieldset class="mb-3" id="__BVID__133">
                  <div tabindex="-1" role="group">
                    <label for="exampleEmail" class="form-label">Email</label>
                    <input id="exampleEmail" name="email" type="email" placeholder="Email here..."
                      formControlName="email" required class="form-control">
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <h6 class="mb-0"><a routerLink="/login" class="text-primary">Sign in existing account</a></h6>
              <div class="ms-auto">
                <input type="submit" class="btn btn-primary btn-lg" value='Recover Password' />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8"
      *ngIf="token && !expired">
      <div class="mx-auto app-login-box col-sm-12 col-md-8 col-lg-6">
        <div class="app-logo"></div>
        <h4>
          <div>Change password</div>
        </h4>
        <div>
          <form class="card-form" [formGroup]="reset" (ngSubmit)="reset_password()">
            <div class="row">
              <div class="col-md-12">
                <fieldset class="mb-3" id="__BVID__133">
                  <div tabindex="-1" role="group">
                    <label for="examplepass" class="form-label">New password</label>
                    <input id="examplepass" type="password" placeholder="Enter new password here..."
                      formControlName="password" required class="form-control">
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="mt-4 d-flex align-items-center">
              <h6 class="mb-0"><a routerLink="/login" class="text-primary">Sign in existing account</a></h6>
              <div class="ms-auto">
                <input type="submit" class="btn btn-primary btn-lg" value="reset Password" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>