<div class="div">
  <div class="center">
    <h2>Get your QR code</h2>
    <input type="text" class="input br" [(ngModel)]="qrId" />
    <button (click)="getImage()" class="btn bg input" style="font-size: 20px;">Get Image</button>
    <div *ngIf="imageUrl" style="display: flex; justify-content: center; align-items: center;">
      <a [href]="imageUrl" [download]="qrId+'.png'">
        <img [src]="imageUrl" alt="QR Code" />
      </a>
    </div>
  </div>
  <div class="frame" *ngIf="url_ticket">
    <iframe [src]="url_ticket" style="width: 384px;height: 90vh;"></iframe>
  </div>
</div>