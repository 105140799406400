import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { getUserId, getprofile } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.sass']
})
export class ProfileUpdateComponent implements OnInit {

  constructor(private router: Router, private formBuilder: FormBuilder, private Toastr: ToastrService, private appCoreservice: AppCoreService) { }

  loginform: FormGroup = new FormGroup({});
  profile: any
  ngOnInit(): void {
    this.profile = JSON.parse(getprofile())
    this.initForm()
  }

  // username   | user_id |     email      | fullname | suspended | company | phone | description 
  // -------------+---------+----------------+----------+-----------+---------+-------+-------------

  initForm() {
    this.loginform = this.formBuilder.group({
      username: new FormControl(this.profile.username, [Validators.required]),
      email: new FormControl(this.profile.email, [Validators.email, Validators.required]),
      fullname: new FormControl(this.profile.fullname ? this.profile.fullname : ''),
      company: new FormControl(this.profile.company ? this.profile.company : ''),
      phone: new FormControl(this.profile.phone ? this.profile.phone : ''),
      description: new FormControl(this.profile.description ? this.profile.description : ''),
    })
  }
  onsubmit() {
    if (this.loginform.valid) {
      this.appCoreservice.update_profile(this.profile.user_id, this.loginform.value).subscribe(data => {
        console.log(data)
      })
    }
    else {
      this.Toastr.error('Please enter fields properly')
    }
    Swal.fire({
      icon: 'success',
      text: 'Profile updated successfully',
      title: 'Congratulation!',
    })
    this.router.navigateByUrl('/')


  }

  goback() {
    this.router.navigateByUrl('/')
  }
}
