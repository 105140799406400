import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-read-qr',
  templateUrl: './read-qr.component.html',
  styleUrls: ['./read-qr.component.css']
})
export class ReadQrComponent implements OnInit {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  qrId: string;
  imageUrl: string;

  url_2: string = '';
  url_ticket: SafeResourceUrl

  to: boolean; false
  getImage() {
    this.to = true
    const url = `https://server.sunbulah-hts.com/qr/${this.qrId}`;
    setTimeout(() => {
      // this.url_2 = '../../../assets/images/qr.jpeg'
    }, 1000);
    this.http.get(url, { responseType: 'text' }).subscribe(
      (data: any) => {
        this.imageUrl = data; // Assuming the server returns base64 image data as a string
        this.url_ticket = this.sanitizer.bypassSecurityTrustResourceUrl('https://sunbulah-hts.com/ticket/' + this.qrId);
      },
      (error) => {
        console.error('Error fetching image:', error);
      }
    );
  }


  ngOnInit(): void {

  }

}