<div class="container-fluid" *ngIf="collectable">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Origin:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" placeholder="Enter Honey Origin here..."
                [value]="collectable.honey_origin" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Weight (kg):</label></div>
        <div class="col-8"><input type="number" class="form-control" id="name" placeholder="Enter Honey Weight here..."
                [value]="collectable.honey_weight" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Kind:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" placeholder="Enter Honey Kind here..."
                [value]="collectable.honey_kind" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-12">
            <label for="search">Honey location:</label>
            <p>{{"lat: "+ collectable.lat+", lng: "+ collectable.lng}}</p>
            <div class="card main-card mb-3 mt-2">
                <div class="card-body">
                    <agm-map style="height: 450px;" [latitude]="collectable.lat" [longitude]="collectable.lng"
                        [zoom]="12" [disableDefaultUI]="false" [zoomControl]="true">

                        <agm-marker [latitude]="collectable.lat" [longitude]="collectable.lng"
                            [markerDraggable]="false">
                            <agm-info-window>
                                <strong>InfoWindow content</strong>
                            </agm-info-window>
                        </agm-marker>

                        <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" [radius]="5000" [fillColor]="'red'"
              [circleDraggable]="true" [editable]="true">
            </agm-circle> -->

                    </agm-map>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding: 10px; margin-top: 20px;">
        <button type="submit" class="btn bg-3" style="font-size: medium;font-weight: 400; float: right;"
            (click)="collect()">Collect</button>
    </div>

</div>