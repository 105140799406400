import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-nine',
  templateUrl: './page-nine.component.html',
  styleUrls: ['./page-nine.component.css']
})
export class PageNineComponent implements OnInit {
   
  ticket_id : number
  @Input() ticket: any;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticket'].currentValue) {
      console.log(this.ticket, "ticket ten")
    }
  }


  ngOnInit(): void {
  }

}
