<div class="container-fluid">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <form (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="dataform" (ngSubmit)="update()">
        <!-- hmf | moister  | ph | color | electrical_cunductivity | diastate -->
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Bol:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="textz" class="form-control" formControlName="bol" id="name"
                            placeholder="Enter bol here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        e.g (BL000525143254)
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Port :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="text" class="form-control" formControlName="port" id="name"
                            placeholder="Enter port here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        e.g. (Veracruz)
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Shipping Duration (approximate):</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="text" class="form-control" formControlName="approx_transit_time" id="name"
                            placeholder="Enter approx shipping duration here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        e.g. (45 days)
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Shipping line :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="text" class="form-control" formControlName="shipping_line" id="name"
                            placeholder="Enter shipping line here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        e.g. (MAERSK)
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Certificate:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;" *ngFor="let i of current_vals.pdfs_shipper">
                        <img style="max-width: 50vw;" *ngIf="i && i !== null && !i.includes('.pdf')" [src]="i" alt="Not yet added">
                        <pdf-viewer *ngIf="i && i !== null && i.includes('.pdf')" [src]="i" [rotation]="0" [original-size]="false" [show-all]="true"
                            [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false"
                            [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
                            [show-borders]="false" style="width: 40vw; height: 80vh;">
                        </pdf-viewer>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <button type="submit" class="btn bg-3" style="font-size: medium;font-weight: 400; float: right;"
                *ngIf="current_vals.status === 'shipping'">Update
                info</button>
        </div>
    </form>
</div>