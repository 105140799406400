<div class="mb-3 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title font-size-lg text-capitalize fw-normal">
            <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
            coming soon...
        </div>
    </div>
    <!-- <div class="g-0 row">
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
                    <i class="lnr-user text-dark opacity-8"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Total Users</div>
                    <div class="widget-numbers">1,7M</div>
                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
                    <i class="lnr- text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Total farmers</div>
                    <div class="widget-numbers"><span>9M</span></div>

                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>
        <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="card no-shadow rm-border bg-transparent widget-chart text-start">
                <div class="icon-wrapper rounded-circle">
                    <div class="icon-wrapper-bg opacity-9 bg-success"></div>
                    <i class="lnr-graduation-hat text-white"></i>
                </div>
                <div class="widget-chart-content">
                    <div class="widget-subheading">Total Collectors</div>
                    <div class="widget-numbers"><span>9M</span></div>

                </div>
            </div>
            <div class="divider m-0 d-md-none d-sm-block"></div>
        </div>

    </div> -->
    <!-- coming soon... -->

</div>