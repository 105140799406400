import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faExpeditedssl, faFacebookF, faInstagram, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCross, faHome, faMapMarkerAlt, faMarker, faSkullCrossbones, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { AppCoreService } from 'src/app/services/app-core.service';
import { setToken } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ticket-mobile',
  templateUrl: './ticket-mobile.component.html',
  styleUrls: ['./ticket-mobile.component.css', "../../../../node_modules/keen-slider/keen-slider.min.css"]
})
export class TicketMobileComponent implements AfterViewInit, OnInit {

  homeicon = faHome
  location_icon = faMapMarkerAlt
  fb = faFacebookF
  ticket_id: string;
  insta = faInstagram
  twitter = faXTwitter
  modal: any[] = [false]
  farmers: any;
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  current_ticket: any;

  disableTouchControls: boolean = false;

  currentSlide: number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null
  slider_stopped: boolean = true;

  constructor(private route: ActivatedRoute, private appCoreService: AppCoreService) { }

  ngOnInit(): void {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }

    setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySW5mbyI6eyJ1c2VybmFtZSI6ImtpbGx1YSIsInJvbGVzIjpbIjAwMDAiXSwidXNlcklkIjoxNjV9LCJpYXQiOjE2OTMwNDQ3NDksImV4cCI6MTcyNDU4MDc0OX0.SqSYr8avUndC8D_HTq_RTW48xS_SUZdZZfnIjn0vEMY')
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 

      this.ticket_id = id
      this.appCoreService.get_ticket(id).subscribe(data => {
        console.log(data, "data1")
        if (data.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'invalid ticket number entered'
          })
        }
        else if (data.blockchain !== 'confirmed') {
          Swal.fire({
            icon: 'error',
            title: 'Ticket not ready yet'
          })
        }
        if (data.ticket_id) {
          console.log(data, "data")
          this.current_ticket = data
        }
      })
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    })
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }
  slider_stop(bool) {
    this.slider_stopped = bool
    this.disableTouchControls = !this.disableTouchControls
    console.log(this.slider_stopped, "position")
  }
  save(e) {
    this.slider.moveToIdx(e)
    this.currentSlide = e
  }
  stop(e) {
    e.preventDefault()
    console.log(e)
  }

}
