import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { getUserId, getrole, setProfile, setRole, setToken } from 'src/app/utils/preserved-storage';
import { AuthService } from 'src/app/services/auth.service';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;

  constructor(public globals: ThemeOptions, private appCoreService: AppCoreService, private activatedRoute: ActivatedRoute, private authService: AuthService, private router: Router) { }

  @select('config') public config$: Observable<any>;

  public config: PerfectScrollbarConfigInterface = {};
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';
  role: string = getrole()

  setUser_farmer() {
    this.appCoreService.list_farmers(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);
    })
  }
  setUser_collector() {
    this.appCoreService.list_collectors(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);

    })
  }
  setUser_shipper() {
    this.appCoreService.list_shippers(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);

    })
  }
  setUser_quality() {
    this.appCoreService.list_inspectors(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);
    })
  }
  setUser_botteling() {
    this.appCoreService.list_botteling(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);
    })
  }
  setUser_admins() {
    this.appCoreService.list_admins(getUserId()).subscribe(data => {
      setProfile(JSON.stringify(data[0]))
      this.router.navigate(['/profile/update']);

    })
  }
  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
  logout() {
    this.authService.logout().subscribe(data => {
      setToken('')
      setRole('guest')
      this.router.navigate(['/login'])
    })
  }
}
