import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AppCoreService } from 'src/app/services/app-core.service';
import { faEdit, faTrash, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { getUserId, getrole, set_ticket, setcollectable } from 'src/app/utils/preserved-storage';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { HttpClient } from '@angular/common/http';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface FoodNode {
  name: string;
  value?: any,
  children?: FoodNode[];
}

@Component({
  selector: 'app-table-history',
  templateUrl: './table-history.component.html',
  styleUrls: ['./table-history.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableHistoryComponent implements OnInit {

  edit = faEdit
  data: any = [];
  beekeeper_entry: boolean;
  displayedColumns = ['ticket_id', 'farmer_id', 'collector_id', 'honey_origin', 'honey_weight', 'honey_kind', "updated", 'status', 'edit'];

  right: any = faChevronRight
  left: any = faChevronLeft
  dataSource2: MatTableDataSource<any>;

  items: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private http: HttpClient, private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService, private datePipe: DatePipe) { }

  table_status: string = 'Loading...';
  role: string = getrole();
  id: number = getUserId()
  delete = faTrash
  tree_node: any[] = []
  tree: boolean;
  img_url: string;
  paginatedData: any[]; // Data to be displayed in the table
  pageSize: number = 10; // Number of items per page
  pageIndex: number = 0; // Current page index

  ngOnInit() {
    this.get_collectables();

    this.dataSource2.paginator = this.paginator; // Link the paginator to the data source
  }

  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  formattedTimestamp(stamp): string {
    const formattedDate = this.datePipe.transform(stamp, 'medium');
    return formattedDate || '';
  }

  update_current_ticket(el) {
    set_ticket(JSON.stringify(el))
    this.router.navigateByUrl('/botteling/update')
  }

  go_blockchain_real(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You won\'t be able to alter the batch information anymore',
      showCancelButton: true,
      cancelButtonColor: "#880000"
    }).then(data => {
      if (data.isConfirmed) {
        this.appCoreService.update_blockchain_real(id.ticket_id).subscribe(data => {
          if (data.status === 200) {
            Swal.fire({
              title: data.message,
              icon: 'success'
            })
            this.get_collectables()
          } else {
            Swal.fire({
              title: data.message,
              icon: 'error'
            })
          }

        })
      } else {
        this.toastr.info("canceled blockchain initiation")
      }
    })
  }

  showReason(reason) {
    Swal.fire({
      text: reason
    })
  }

  cancel(ticket_id, cancelled) {
    Swal.fire({
      title: 'Enter a reason to contiue',
      input: 'textarea', // Use 'textarea' instead of 'text'
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonColor: '#444',
      confirmButtonColor: '#821630',
      confirmButtonText: !cancelled ? 'Pause Ticket' : 'Resume Ticket',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value && result.value.trim() !== '') {
          this.appCoreService.cancel_ticket({ ticket_id: ticket_id, reason: result.value }).subscribe(i => {
            Swal.fire({
              title: i.message,
              icon: i.icon
            }).then(k => {
              if (i.status === 200) {
                window.location.reload()
              }
            });
          })

        } else {
          Swal.fire({
            title: 'Please enter a reason to continue',
            icon: 'error'
          });
        }
      }
    });
  }

  getdata(element) {
    navigator.clipboard.writeText(element.ticket_id);
    this.toastr.success('ticket Id copied!')
  
    this.appCoreService.list_ticket_final(element.ticket_id).subscribe(result => {
      console.log(result.data.collector_node)
      if (result.data) {  
        this.tree_node = []
        this.table_status = ''
        const farmerNode = result.data.beekeeper_node.map(item => {
          const newObj = {
            name: item.keeper,
            children: [
              { name: `Beekeeper ID: ${item.farmer_id}` },
              { name: `Ticket ID: ${item.ticket_id}` },
              { name: `Timestamp: ${new Date(item.timestamp).toLocaleString()}` },
              { name: `Updated: ${new Date(item.updated).toLocaleString()}` },
              { name: `Honey Origin: ${item.honey_origin}` },
              { name: `Honey Weight: ${item.honey_weight}` },
              { name: `Honey Kind: ${item.honey_kind}` },
              { name: `Honey latitude: ${item.lat}` },
              { name: `Honey longitude: ${item.lng}` },
            ]
          };
          return newObj;
        });

        this.tree_node.push({ name: 'Beekeepers', children: farmerNode });

        const collector_Arr = Object.entries(result.data.collector_node).map(([key, value]) => ({ name: `${key}: ${value}` }));

        this.tree_node.push({ name: 'Collector', children: collector_Arr });

        if (result.data.shipper_node) {
          console.log("shipper exists")
          const shipper_Arr = Object.entries(result.data.shipper_node).map(([key, value]) => ({ name: `${key}: ${value}` }));

          this.tree_node.push({ name: 'Shipper', children: shipper_Arr });
        }
        if (result.data.inspector_node) {
          console.log("inspec exists")
          
          const inspection_Arr = Object.entries(result.data.inspector_node).map(([key, value]) => ({ name: `${key}: ${value}` }));

          this.tree_node.push({ name: 'Quality Inspection', children: inspection_Arr });

        }

        if (result.data.botteling_node) {
          console.log("botteling exists")

          const botteling_Arr = Object.entries(result.data.botteling_node).map(([key, value]) => ({ name: `${key}: ${value}` }));

          this.tree_node.push({ name: 'Bottling', children: botteling_Arr });
        }

        this.dataSource.data = this.tree_node;
        this.tree = true;
      } else {
        Swal.fire({
          title: 'Error loading data',
          icon: "error"
        })
      }
    })
    this.getImage(element.ticket_id);
    return
  }

  // Function to format column names
  formatColumnName(column: string): string {
    if (column === 'honey_kind') {
      return 'Honey Type';
    } else if (column === 'farmer_id') {
      return 'Beekeeper ID';
    } else {
      return column.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  }

  getImage(Id) {
    const url = `https://server.sunbulah-hts.com/qr/${Id}`;
    this.http.get(url, { responseType: 'text' }).subscribe(
      (data: any) => {
        this.img_url = 'loading...'
        setTimeout(() => {
          this.img_url = data; // Assuming the server returns base64 image data as a string          
        }, 500);
      },
      (error) => {
        this.img_url = 'Error fetching image:';
      }
    );
  }

  get_collectables() {
    if (this.role.includes('admin')) {
      this.appCoreService.list_tickets_all().subscribe(result => {
        if (result.data?.length) {
          this.table_status = ''
          this.data = result.data

          if (this.router.url === '/tickets/confirmed') {
            this.data = this.data.filter(item => item.status === "confirmed");
          }

          if (this.router.url === '/tickets/blockchain') {
            this.data = this.data.filter(item => item.status === "confirmed");

            this.data.forEach(item => {
              item.status = "verified"
            })
          }
          this.data.sort((a: any, b: any) => {
            const dateA = new Date(a.updated);
            const dateB = new Date(b.updated);
            return dateB.getTime() - dateA.getTime();
          });
          this.dataSource2 = new MatTableDataSource(this.data)
          this.dataSource.data = this.tree_node;
          this.paginateData();
        }
        else {
          this.table_status = 'No Tickets Initiated yet!'
          // console.log(this.table_status)
        }
      })
    }

  }

  paginateData() {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedData = this.data.slice(start, end);
    this.dataSource2.data = this.paginatedData;
  }

  nextPage() {
    if ((this.pageIndex + 1) * this.pageSize >= this.data.length) {
      return;
    }
    this.pageIndex++;
    this.paginateData();
  }

  previousPage() {
    if (this.pageIndex === 0) {
      return;
    }
    this.pageIndex--;
    this.paginateData();
  }

  setLimit(event) {
    this.pageSize = event;
    this.paginateData();
  }

  delete_collectables(id) {
    Swal.fire(
      {
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete ticket!'
      }).then((result) => {
        if (result.value) {
          this.appCoreService.delete_collectable(id).subscribe(data => {
            if (data.status === 200) {
              this.toastr.success("ticket deleted changed Successfully")
              this.get_collectables()
            } else if (data.status === 401) {
              console.log('this action is not allowed by the user role farmer')
              this.toastr.success("user suspended Successfully")
            } else {
              this.toastr.error(data.message)
            }
          })
        }
      }
      )
  }

  copy(value) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
    this.toastr.success('ticket Id copied!')
  }

  applyFilter(Value: any) {
    let filterValue = Value.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }

  update_collectable(el) {
    if (!el.farmer_id) {
      Swal.fire({
        title: "Error! Invalid ticket",
        text: 'Please contact the admins or try again later!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
    else {
      // console.log(el)
      set_ticket(JSON.stringify(el))
      this.router.navigateByUrl('/edit/ticket')
    }
  }
  ngAfterViewInit(): void {

  }
}

