import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TableComponent } from './shared/table/table.component';
import { AuthguardGuard } from './shared/auth.guard';
import { RegisterBoxedComponent } from './components/register-boxed/register-boxed.component';
import { TicketsComponent } from './components/farmer/tickets.component';
import { InitCollectableComponent } from './components/farmer/init-collectable/init-collectable.component';
import { ListTicketsComponent } from './components/farmer/list-tickets/list-tickets.component';
import { UpdateCollectableComponent } from './components/farmer/update-collectable/update-collectable.component';
import { CollectorComponent } from './components/collector/collector.component';
import { CollectorListTicketsComponent } from './components/collector/collector-list-tickets/collector-list-tickets.component';
import { CollectorCollectComponent } from './components/collector/collector-collect/collector-collect.component';
import { TicketsControllerComponent } from './components/admin/tickets-controller/tickets-controller.component';
import { MainControllerComponent } from './components/admin/main-controller/main-controller.component';
import { TicketMobileComponent } from './components/ticket-mobile/ticket-mobile.component';
import { UpdateTicketComponent } from './components/collector/update-ticket/update-ticket.component';
import { ShipperComponent } from './components/shipper/shipper.component';
import { ShipperListTicketsComponent } from './components/shipper/shipper-list-tickets/shipper-list-tickets.component';
import { ConfirmComponent } from './components/shipper/confirm/confirm.component';
import { ReadQrComponent } from './components/read-qr/read-qr.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { UploadComponent } from './components/farmer/upload/upload.component';
import { UpdateShipmentComponent } from './components/shipper/update-shipment/update-shipment.component';
import { PackagingComponent } from './components/packaging/packaging.component';
import { ListPackagingComponent } from './components/packaging/list-packaging/list-packaging.component';
import { ConfirmPackagingComponent } from './components/packaging/confirm-packaging/confirm-packaging.component';
import { UpdatePackagingComponent } from './components/packaging/update-packaging/update-packaging.component';
import { HistoryComponent } from './components/admin/history/history.component';
import { ProfileComponent } from './components/admin/profile/profile.component';
import { ListBottelingComponent } from './components/botteling/list-botteling/list-botteling.component';
import { ConfirmBottelingComponent } from './components/botteling/confirm-botteling/confirm-botteling.component';
import { UpdateBottelingComponent } from './components/botteling/update-botteling/update-botteling.component';
import { BottelingComponent } from './components/botteling/botteling.component';
import { ProfileUpdateComponent } from './shared/profile-update/profile-update.component';
import { UpdateFinalComponent } from './components/admin/history/update-final/update-final.component';
import { BlockchainComponent } from './components/admin/history/blockchain/blockchain.component';
const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['admin'],
    },
    children: [
      {
        path: 'get_qr',
        component: ReadQrComponent,
      }
      , {
        path: 'tickets/farmer', 
        children: [{
          path: '',
          component: TicketsComponent,
          children: [
            {
              path: '',
              component: ListTicketsComponent
            }, {
              path: 'privacy',
              component: PrivacyPolicyComponent
            }, {
              path: 'init-collectable',
              component: InitCollectableComponent
            }, {
              path: 'upload',
              component: UploadComponent
            }, {
              path: 'update-collectable',
              component: UpdateCollectableComponent
            }]
        }]
      },
      {
        path: 'tickets/collector', 
        children: [{
          path: '',
          component: CollectorComponent,
          children: [
            {
              path: '',
              component: CollectorListTicketsComponent
            }, {
              path: 'collect-collectable',
              component: CollectorCollectComponent
            }, {
              path: 'privacy',
              component: PrivacyPolicyComponent
            }, {
              path: 'add',
              component: CollectorCollectComponent
            }, {
              path: 'update',
              component: UpdateTicketComponent
            }]
        }]
      }, {
        path: 'tickets/shipper', 
        children: [{
          path: '',
          component: ShipperComponent,
          children: [
            {
              path: '',
              component: ShipperListTicketsComponent
            }, {
              path: 'privacy',
              component: PrivacyPolicyComponent
            }, {
              path: 'confirm',
              component: ConfirmComponent
            }, {
              path: 'update',
              component: UpdateShipmentComponent
            }],
        }]
      }, {
        path: 'tickets/inspector', 
        children: [{
          path: '',
          component: PackagingComponent,
          children: [
            {
              path: '',
              component: ListPackagingComponent
            }, {
              path: 'privacy',
              component: PrivacyPolicyComponent
            }, {
              path: 'confirm',
              component: ConfirmPackagingComponent
            }, {
              path: 'update',
              component: UpdatePackagingComponent
            }],
        }]
      }, {
        path: 'tickets/botteling',  
        children: [{
          path: '',
          component: BottelingComponent,
          children: [
            {
              path: '',
              component: ListBottelingComponent
            }, {
              path: 'privacy',
              component: PrivacyPolicyComponent
            }, {
              path: 'confirm',
              component: ConfirmBottelingComponent
            }, {
              path: 'update',
              component: UpdateBottelingComponent
            },],
        }]
      },
      {
        path: '',
        redirectTo: 'users/farmers',
        pathMatch: 'full'
      }, {
        path: 'users/profile',
        component: ProfileComponent
      },
      {
        path: 'register',
        component: RegisterBoxedComponent,
      }, {
        path: 'tickets/history',
        component: HistoryComponent
      }, {
        path: 'tickets/confirmed',
        component: HistoryComponent
      },  {
        path: 'tickets/blockchain',
        component: HistoryComponent
      }, {
        path: 'users/farmers',
        component: TableComponent
      }, {
        path: 'users/collectors',
        component: TableComponent
      }, {
        path: 'users/shippers',
        component: TableComponent
      }, {
        path: 'users/botteling',
        component: TableComponent
      }, {
        path: 'users/inspectors',
        component: TableComponent
      }, {
        path: 'users/admins',
        component: TableComponent
      }, {
        path: 'edit/ticket',
        component: UpdateFinalComponent
      }
    ]
  }, {
    path: 'profile/update',
    component: BaseLayoutComponent,
    children: [{
      path: '',
      component: ProfileUpdateComponent
    }]
  },
  {
    path: 'farmer',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['farmer', 'admin'],
    },
    children: [{
      path: '',
      component: TicketsComponent,
      children: [
        {
          path: '',
          component: ListTicketsComponent
        }, {
          path: 'privacy',
          component: PrivacyPolicyComponent
        }, {
          path: 'init-collectable',
          component: InitCollectableComponent
        }, {
          path: 'upload',
          component: UploadComponent
        }, {
          path: 'update-collectable',
          component: UpdateCollectableComponent
        }]
    }]
  }, {
    path: 'collector',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['collector', 'admin'],
    },
    children: [{
      path: '',
      component: CollectorComponent,
      children: [
        {
          path: '',
          component: CollectorListTicketsComponent
        }, {
          path: 'collect-collectable',
          component: CollectorCollectComponent
        }, {
          path: 'privacy',
          component: PrivacyPolicyComponent
        }, {
          path: 'add',
          component: CollectorCollectComponent
        }, {
          path: 'update',
          component: UpdateTicketComponent
        }]
    }]
  }, {
    path: 'shipper',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['shipper', 'admin'],
    },
    children: [{
      path: '',
      component: ShipperComponent,
      children: [
        {
          path: '',
          component: ShipperListTicketsComponent
        }, {
          path: 'privacy',
          component: PrivacyPolicyComponent
        }, {
          path: 'confirm',
          component: ConfirmComponent
        }, {
          path: 'update',
          component: UpdateShipmentComponent
        }],
    }]
  }, {
    path: 'inspector',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['inspector', 'admin'],
    },
    children: [{
      path: '',
      component: PackagingComponent,
      children: [
        {
          path: '',
          component: ListPackagingComponent
        }, {
          path: 'privacy',
          component: PrivacyPolicyComponent
        }, {
          path: 'confirm',
          component: ConfirmPackagingComponent
        }, {
          path: 'update',
          component: UpdatePackagingComponent
        }],
    }]
  }, {
    path: 'botteling',
    component: BaseLayoutComponent,
    canActivate: [AuthguardGuard],
    data: {
      role: ['botteling', 'admin'],
    },
    children: [{
      path: '',
      component: BottelingComponent,
      children: [
        {
          path: '',
          component: ListBottelingComponent
        }, {
          path: 'privacy',
          component: PrivacyPolicyComponent
        }, {
          path: 'confirm',
          component: ConfirmBottelingComponent
        }, {
          path: 'update',
          component: UpdateBottelingComponent
        },],
    }]
  },
  {
    path: 'ticket/:ticket_id',
    component: TicketMobileComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forget_password',
    component: ForgotPasswordComponent
  },
  {
    path: 'forget_password/:token',
    component: ForgotPasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
