<div class="modal-custom" [ngStyle]="{'display': modal[0] === true && modal[1] === 'farmers'? 'flex' : 'none'}">
    <div class="modal-inn">
        <div class="goback">
            <button class="btn btn-ghost" (click)="modal[0] = false "> <i>&#10006;</i></button>
        </div>
        <div class="container mdl-container">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"
                                    style="display: flex; justify-content: baseline; align-items: baseline;"> Lot No.
                                </th>
                                <th role="columnheader" scope="col" tabindex="0">Honey Type</th>
                                <th role="columnheader" scope="col" tabindex="0">Qty, Kg </th>
                                <th role="columnheader" scope="col" tabindex="0">Bee keeper </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="farmers">
                            <tr *ngFor="let ticket of farmers; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ ticket.honey_kind }}</td>
                                <td>{{ ticket.honey_weight }}</td>
                                <td>{{ ticket.keeper }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-custom" [ngStyle]="{'display': modal[0] === true && modal[1] === 'analysis'? 'flex' : 'none'}">
    <div class="modal-inn">
        <div class="goback">
            <button class="btn btn-ghost" (click)="modal[0] = false "> <i>&#10006;</i></button>
        </div>
        <div class="container  mdl-container">
            <div class="row">
                <div class="col-12" style="width: 90vw;">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"
                                    style="display: flex; justify-content: baseline; align-items: baseline; flex-grow: 1;">
                                    Parameter </th>
                                <th role="columnheader" scope="col" tabindex="0">Value</th>
                                <th role="columnheader" scope="col" tabindex="0">Standard </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup" *ngIf="current_ticket">
                            <tr role="row">
                                <td>HMF</td>
                                <td>{{current_ticket.hmf}}</td>
                                <td>&lt; 80</td>
                            </tr>
                            <tr role="row">
                                <td>Moisture</td>
                                <td>{{current_ticket.moister}}</td>
                                <td>18.5% - 19.5%</td>
                            </tr>
                            <tr role="row">
                                <td>Color</td>
                                <td>{{current_ticket.color}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Fg Ratio</td>
                                <td>{{current_ticket.fg_ratio}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Invert Sugar</td>
                                <td>{{current_ticket.invert_sugar}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Suctose</td>
                                <td>{{current_ticket.suctose}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Ash</td>
                                <td>{{current_ticket.ash}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Appearance</td>
                                <td>{{current_ticket.appearence}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Fermentation</td>
                                <td>{{current_ticket.fermentation}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Taste</td>
                                <td>{{current_ticket.taste}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Odder</td>
                                <td>{{current_ticket.odder}}</td>
                                <td>&lt; Na</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-custom" [ngStyle]="{'display': modal[0] === true && modal[1] === 'botteling'? 'flex' : 'none'}">
    <div class="modal-inn">
        <div class="goback">
            <button class="btn btn-ghost" (click)="modal[0] = false "> <i>&#10006;</i></button>
        </div>
        <div class="container  mdl-container">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"
                                    style="display: flex; justify-content: baseline; align-items: baseline; flex-grow: 1;">
                                    Parameter
                                </th>
                                <th role="columnheader" scope="col" tabindex="0">Value</th>
                                <th role="columnheader" scope="col" tabindex="0">Standard </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup" *ngIf="current_ticket">
                            <tr role="row">
                                <td>HMF</td>
                                <td>{{current_ticket.hmf}}</td>
                                <td>&lt; 80</td>
                            </tr>
                            <tr role="row">
                                <td>Moisture</td>
                                <td>{{current_ticket.moister}}</td>
                                <td>18.5% - 19.5%</td>
                            </tr>
                            <tr role="row">
                                <td>Color</td>
                                <td>{{current_ticket.color}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Fg Ratio</td>
                                <td>{{current_ticket.fg_ratio}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Invert Sugar</td>
                                <td>{{current_ticket.invert_sugar}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Suctose</td>
                                <td>{{current_ticket.suctose}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Ash</td>
                                <td>{{current_ticket.ash}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Appearance</td>
                                <td>{{current_ticket.appearence}}</td>
                                <td>&lt; Na</td>
                            </tr> 
                            <tr role="row">
                                <td>Fermentation</td>
                                <td>{{current_ticket.fermentation}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Taste</td>
                                <td>{{current_ticket.taste}}</td>
                                <td>&lt; Na</td>
                            </tr>
                            <tr role="row">
                                <td>Odder</td>
                                <td>{{current_ticket.odder}}</td>
                                <td>&lt; Na</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-custom" [ngStyle]="{'display': modal[0] === true && modal[1] === 'image'? 'flex' : 'none'}">
    <div class="modal-inn">
        <div class="goback">
            <button class="btn btn-ghost" (click)="modal[0] = false "> <i>&#10006;</i></button>
        </div>
        <div class="container  mdl-container">
            <img [src]="current_ticket.pdf_collector" alt="certicate" style="width: 100%;">
        </div>
    </div>
</div>
<div class="modal-custom" [ngStyle]="{'display': modal[0] === true && modal[1] === 'image2'? 'flex' : 'none'}">
    <div class="modal-inn">
        <div class="goback">
            <button class="btn btn-ghost" (click)="modal[0] = false "> <i>&#10006;</i></button>
        </div>
        <div class="container  mdl-container">
            <img [src]="current_ticket.pdf_botteling" alt="certicate" style="width: 100%;">
        </div>
    </div>
</div>

<div class="modal-bg" [ngStyle]="{'display': modal[0] === true? 'block' : 'none'}"></div>
<div class="navigation-wrapper" [class.disable-touch]="disableTouchControls">
    <div #sliderRef [ngClass]="currentSlide !== 0?'keen-slider keen-slider-2':'keen-slider'">
        <div class="keen-slider__slide number-slide1">
            <app-page-one (curr_slider)="save($event)" [batch]="ticket_id" [ticket]="current_ticket"></app-page-one>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-two (curr_slider)="save($event)" [current_ticket]="current_ticket"
                (moveable)="slider_stop($event)"></app-page-two>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-three [ticket]="current_ticket"></app-page-three>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-four [ticket]="current_ticket"></app-page-four>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-five (modal)="modal = $event;" (farmers)="farmers = $event;"
                [current_ticket]="current_ticket"></app-page-five>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-six (modal)="modal = $event;" [current_ticket]="current_ticket"></app-page-six>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-seven [current_ticket]="current_ticket" (modal)="modal = $event;"></app-page-seven>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-eight [current_ticket]="current_ticket"></app-page-eight>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-nine (modal)="modal = $event;" [ticket]="current_ticket"></app-page-nine>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-ten [current_ticket]="current_ticket" (modal)="modal = $event;"></app-page-ten>
        </div>
        <div class="keen-slider__slide number-slide1">
            <app-page-eleven (modal)="modal = $event;" [ticket]="current_ticket"></app-page-eleven>
        </div>
    </div>
    <div class="dots" *ngIf="currentSlide !== 0">
        <button class="btn btn-ghost" (click)='slider.moveToIdx(1)'><fa-icon class="icon-footer" [icon]="location_icon"
                style="font-size: 30px;"></fa-icon></button>
        <div class="dot-container">
            <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
        </div>
        <button class="btn btn-ghost" (click)='slider.moveToIdx(0)'><fa-icon class="icon-footer"
                style="font-size: 30px;" [icon]="homeicon"></fa-icon></button>
    </div>
    <div class="footer_corosel" *ngIf="currentSlide === 0">
        <div class="footer_logo" style="max-height: 25vh;">
            <img style="max-height: 10vh;" src="../../../../assets/images/footer_be_well.png" alt="footer be well!">
        </div>
        <div class="footer_home">
            <div class="social">
                <span>
                    <b>
                        Follow us
                    </b>
                </span>
                <div class="btn-group gap-1">
                    <a class="btn btn-custom-1 rounded-circle" href="https://www.facebook.com/AlShifaKSA"
                        target="_blank"><fa-icon [icon]="fb"></fa-icon></a>
                    <a class="btn btn-custom-1 rounded-circle" href="https://www.instagram.com/alshifaksa"
                        target="_blank"><fa-icon [icon]="insta"></fa-icon></a>
                    <a class="btn btn-custom-1 rounded-circle" href="https://twitter.com/AlShifaHoney"
                        target="_blank"><fa-icon [icon]="twitter"></fa-icon></a>
                </div>
            </div>
            <div class="blockchain">
                <img src="../../../assets/images/Blockchain_Icon.png" alt="yes we use blockchain">
                <span>powered by</span><br>
                <span>Block-chain technology</span>
            </div>
        </div>
    </div>
</div>