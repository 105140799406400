<div class="container-fluid" *ngIf="ticket">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <!-- // time_stamp     | honey_origin | honey_weight | hmf | moister | ph | color | electrical_cunductivity | id | diastate | blockchain |  status
    // | ticket_id | inspector_id | shipper_id | collector_id | farmer_id |          updated           | honey_kind |
    // locations | pdf_collector | collector_lat | collector_lng | shipper_lng | shipper_lat | inspection_lat | inspection_lng |
    //  fg_ratio | invert_sugar | suctose | ash | appearence | cleanliness | odder | taste | fermentation | history_states |
    //  port | bol | approx_transit_time | shipping_line | pdf_shipper -->
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Origin:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" placeholder="Enter Honey Origin here..."
                [value]="ticket.honey_origin" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Weight:</label></div>
        <div class="col-8"><input type="number" class="form-control" id="name" placeholder="Enter Honey Weight here..."
                [value]="ticket.honey_weight" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Type:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.honey_kind"
                disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey H.M.F:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.hmf" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Acidity:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.ph" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey color:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.color" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Diastate:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.diastate" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Fg ratio:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.fg_ratio" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Invert sugar:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.invert_sugar"
                disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey suctose:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.suctose" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey ash:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.ash" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey appearence:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.appearence"
                disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey cleanliness:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.cleanliness"
                disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Odder:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.odder" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Taste:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.taste" disabled="true">
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-4"><label for="name">Honey Fermentation:</label></div>
        <div class="col-8"><input type="text" class="form-control" id="name" [value]="ticket.fermentation"
                disabled="true">
        </div>
    </div>
    <div class="row" style="padding: 10px; margin-top: 20px;">
        <button type="submit" class="btn bg-3" style="font-size: medium;font-weight: 400; float: right;"
            (click)="collect()">Collect</button>
    </div>

</div>