<div class="container-fluid">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <form (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="dataform" (ngSubmit)="update()">
        <!-- hmf | moister  | ph | color | electrical_cunductivity | diastate -->
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">H.M.F :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="text" class="form-control" min="" formControlName="hmf" id="name"
                            placeholder="Enter hmf here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        mg/kg
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Moisture :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="moister" id="name"
                            placeholder="Enter Moisture here..." disableMousewheel disableMousewheel>
                    </div>
                    <div class="c-border">
                        %
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Diastase :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="diastate" id="name"
                            placeholder="Enter Diastase here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        schade
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Acidity:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="ph" id="name"
                            placeholder="Enter acidity here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        mg/kg
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Color:</label></div>
            <div class="col-8">
                <ng-select [items]="color" bindLabel="name" placeholder="Select city" [(ngModel)]="selectedcolor"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fg ratio:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="fg_ratio" id="name"
                    placeholder="Enter FG ratio here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Invert sugar:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="invert_sugar" id="name"
                    placeholder="Enter invert sugar here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Suctose:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="suctose" id="name"
                    placeholder="Enter Suctose here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Ash:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="ash" id="name"
                    placeholder="Enter ash here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Appearence:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="appearence" id="name"
                    placeholder="Enter appearence here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Cleanliness:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 75%;">
                        <input type="text" class="form-control" formControlName="cleanliness" id="name"
                            placeholder="Enter cleanliness here..." disableMousewheel>
                    </div>
                    <div class="c-border" style="width: 25%;">
                        Free from foreign materials
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4">
                <label for="name">
                    Odder:
                </label>
            </div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="odder" id="name"
                    placeholder="Enter odder here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Taste:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="taste" id="name"
                    placeholder="Enter taste here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fermentation:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="fermentation" id="name"
                    placeholder="Enter fermentation here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Lot Number:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="lot_no" id="name"
                    placeholder="Enter Lot number here...">
            </div>
        </div>
        <div class="imgs">
            <br><br>
            <label for='media'>Media uploded for the ticket:</label>
            <br><br>
            <div id="media" class="gallery"
                style="display: flex; width: 100%;max-height: 300px;overflow-y: scroll;flex-direction: row;gap:20px">
                <div *ngFor="let m of medias">
                    <img *ngIf="!m.includes('.pdf')" [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+m"
                        alt="media" style="max-height: 300px;">
                    <pdf-viewer *ngIf="m.includes('pdf')" [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+m"
                        [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1"
                        [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                        [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                        style="width:auto; height: 40vh;">
                    </pdf-viewer>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <label for="search">Honey location:</label>
                <p *ngIf="latitude">{{"lat: "+latitude+", lng: "+longitude}}</p>
                <input style="margin-top: 20px;" id="search" #search autocorrect="off" autocapitalize="off"
                    spellcheck="off" type="text" class="form-control" *ngIf="current_vals.status === 'collecting'">
                <div class="card main-card mb-3 mt-2">
                    <div class="card-body">
                        <agm-map style="height: 450px;" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                            [disableDefaultUI]="false" [zoomControl]="true" (mapClick)="mapClicked($event)">
                            <agm-marker *ngFor="let m of markers; let i = index"
                                (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat" [longitude]="m.lng"
                                [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">
                                <agm-info-window>
                                    <strong>InfoWindow content</strong>
                                </agm-info-window>
                            </agm-marker>
                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <button type="submit" class="btn bg-3" style="font-size: medium; font-weight: 400; float: right;"
                [ngStyle]="{'display': disable ? 'none' : 'block'}"
                *ngIf="current_vals.status==='collecting' || current_vals.status==='000' ">Update</button>
        </div>
    </form>
</div>