<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;" *ngIf="ticket">
        <h2 class="title" style="margin-top: 10px;">{{ticket.honey_kind}}</h2>
        <div class="img-container">
            <img *ngIf="image" [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/ticket_media/honey_bottles/'+ticket.honey_kind.replace(' ','_')+'.png'"
                alt="honey_bottle">
            <img *ngIf="!image" src="../../../../assets/images/honey_bottle.png" alt="honey_bottle">

        </div>
        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{ticket.batch_no}}</span><br>
        <div style="padding: 0px 5vh;text-align: center;">
            <span class="batch_title" style="font-size: 15px;"
                *ngIf="ticket.page_3_section_1">{{ticket.page_3_section_1}}</span>
            <span *ngIf="!ticket.page_3_section_1" class="batch_title" style="font-size: 15px;">
                Al-Shifa is bringing you this honey from
                the tropical west coast area of {{ticket.honey_origin}}, from the state of Oaxaca,
                where honeybees collected the nectar from flowers growing on mountains rises up to <span class="batch"
                    style="font-size: small;">3000 meters altitude</span>
                above the see level. The bees continue to collect the sweet nectar from the flowers all the way to the
                top
                and produce this unique type of honey.</span>
        </div>
    </div>
</div>