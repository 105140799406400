import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { SharedService } from "../shared/shared.service";
import { ErrorDataHandler } from "../utils/ErrorDataHandler"
import { ApiUrl } from "../data/api-urls"
import { catchError, tap } from "rxjs/operators";
import { getToken } from "../utils/preserved-storage";
import { Observable } from "rxjs";
import {
  GlobalConfig,
  ToastrService,
  ToastContainerDirective,
} from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public httpOptions = {
    headers: { 'Content-Type': 'application/json' },

    Credentials: 'include',
    withCredentials: true,
  };


  options: GlobalConfig;

  constructor(private sharedService: SharedService, private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  authenticate(data: any): Observable<any> {
    const url: string = ApiUrl.login();
    console.log(data)
    return this.sharedService.post(url, data, this.httpOptions).pipe(
      tap(data => {
        return data
      }), catchError(async err => { if (err.body === 'admin access only') { this.toastr.error('admin access only') } else { this.toastr.error('Wrong Username or password'); console.log(err) } }
      ))
  }

  refreshtoken(): Observable<any> {
    const url: string = ApiUrl.refresh();
    return this.sharedService.get(url, this.httpOptions).pipe(

      tap(rest => {
        console.log(this.httpOptions)
        return rest
      }), catchError(async err => { if (err.status === 403) { this.toastr.error('You\'r session has Expired. please login again.'); } else if (err.status === 401) { } else { this.toastr.error('an error occured login again.') } })
    )
  }
  logout(): Observable<any> {
    const url: string = ApiUrl.logout();
    return this.sharedService.get(url, this.httpOptions);
  }
  reset_password(data: any): Observable<any> {
    const url: string = ApiUrl.mailpasswordlink();
    return this.sharedService.post(url, data, this.httpOptions);
  }
  reset_password_confirm(data: any, token: any): Observable<any> {
    const url: string = `${ApiUrl.reset_password()}/${token}`;
    return this.sharedService.post(url, data, this.httpOptions);
  }
  identifyRole(roles: any): string {
    console.log(roles)

    if (roles.includes("0000")) {
      return 'farmer'
    }
    else if (roles.includes("1111")) {
      return 'collector'
    }
    else if (roles.includes("2222")) {
      return 'shipper'
    }
    else if (roles.includes("3333")) {
      return 'inspector'
    } else if (roles.includes("4444")) {
      return 'botteling'
    }
    else if (roles.includes("9999")) {
      return 'admin'
    }
    else {
      return 'consumer'
    }
  }
  isLoggedIn = (): boolean => {
    const token: any = getToken()
    if (token !== 'undefined' && token !== null) {
      const expired = this.tokenExpired(token)

      if (!expired) {
        return true
      }
      else {
        return false
      }
    }
    return false
  }
  tokenExpired(token: any) {
    if (token) {
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      //   // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    // return throwError(errorMessage);
    return new ErrorDataHandler(err).handleError({});
  }

}
