<div class="h-100">
  <div class="row h-100 g-0">
    <div class="d-none d-lg-block col-lg-4" style="background-image: url('../../../assets/images/originals/abstract.jpg');background-position: center;
background-size: cover;
background-repeat: no-repeat;">
      <div class="slider-light">
      </div>
    </div>
    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
      <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
        <!-- <div class="app-logo"></div> -->
        <h4 class="mb-0">
          <img src="../../../assets/images/Alshifa_Logo.png" alt="logo main" width="150px" style="position:relative;left:-15px ;">
          <h2>WELCOME TO HTS</h2>
          <span>Honey Tracing system with blockchain</span>
          <br>
          <br>
          <div>Please sign in to your account.</div>
        </h4>
        <h6 class="mt-3">
          <!-- No account? -->
          <!-- <a routerLink="/register" class="text-primary">Sign up now</a>-->
        </h6>
        <div class="divider"></div>
        <div>
          <form class="form" [formGroup]="loginform" (ngSubmit)="onlogin()">
            <div class="row">
              <div class="col-md-6">
                <fieldset class="mb-3">
                  <div tabindex="-1" role="group">
                    <label for="exampleEmail" class="form-label">Email</label>
                    <input id="exampleEmail" type="email" formControlName="email" placeholder="Email Address"
                      class="form-control">
                  </div>
                </fieldset>
              </div>
              <div class="col-md-6">
                <fieldset class="">
                  <div tabindex="-1" role="group"><label for="examplePassword" class="form-label">Password</label><input
                      id="examplePassword" name="password" type="password" formControlName="password"
                      placeholder="Password" class="form-control"></div>
                </fieldset>
              </div>
            </div>
            <div class="custom-control custom-checkbox form-check">
            </div>
            <div class="divider"></div>
            <div class="d-flex align-items-center">
              <div class="ms-auto"><a routerLink="/forget_password" class="btn-lg btn btn-link"
                  style="color: #987b59;">Recover
                  Password</a>
                <button type="submit" class="btn btn-lg" style="background-color: #987b59;color: #fff;">Login to
                  Dashboard</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>