<div style="display: flex;flex-direction: column;">
  <div style="float: right; width:100%;text-align: right;margin-right: 5px; margin-bottom: 20px;">
    <input matInput class="form-control" style="width: 25%; float: right;" (keyup)="applyFilter($event)"
      placeholder="Filter...">
  </div>
  <div class="example-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource2" matSort *ngIf="data">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="navigateToUserProfile(row)"> {{row.user_id}} </mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="profile">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Profile</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="navigateToUserProfile(row)">
          <div class="img">
            <img style="height: 40px; width: 40px;"
              src="https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png" alt='user profile' />
          </div>
        </mat-cell>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Username</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="navigateToUserProfile(row)"> {{row.username}} </mat-cell>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="fullname">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fullname</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="navigateToUserProfile(row)"> {{row.fullname}} </mat-cell>
      </ng-container>
      <!-- Symbol Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> E-mail</mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="navigateToUserProfile(row)"> {{row.email}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Edit</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div style="display: flex;flex-direction: row;gap: 10px;">
            <!-- <button class="btn btn-success"><fa-icon [icon]="edit"></fa-icon></button> -->
            <button class="btn" [ngClass]="!row.suspended?'btn-success':'btn-warning'"
              (click)="suspend_user(row)"><fa-icon [icon]="suspend"></fa-icon></button>
            <button class="btn btn-danger" (click)="delete_user(row.user_id)"><fa-icon [icon]="bin"></fa-icon></button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <h2 *ngIf="!data && !sec">Loading...</h2>
    <h1 *ngIf="!data && sec">No users added for this role yet!</h1>
    <div class="card main-card mb-3">
      <div class="card-body">
        <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>