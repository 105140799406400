import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule

// BOOTSTRAP COMPONENTS

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule } from 'ngx-loading';
// import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { CountUpModule } from 'countup.js-angular2';
import { AgmCoreModule } from '@agm/core';
import { MatListModule } from '@angular/material/list';
// import { ImageCropperModule } from 'ngx-image-cropper';
// import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { TextMaskModule } from 'angular2-text-mask';
// import { ClipboardModule } from 'ngx-clipboard';
// import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
// import { ColorPickerModule } from 'ngx-color-picker';
// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// ANGULAR MATERIAL COMPONENTS

// import { MatCheckboxModule } from '@angular/material/checkbox';

// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { ThemeOptions } from './theme-options';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

import {MatDialogModule} from '@angular/material/dialog'; 
// FOOTER
import { FooterComponent } from './Layout/Components/footer/footer.component';
import { FooterMenuComponent } from './Layout/Components/footer/elements/footer-menu/footer-menu.component';

// import { TrendModule } from 'ngx-trend';
import { LoginComponent } from './components/login/login.component'
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TableComponent } from './shared/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RegisterBoxedComponent } from './components/register-boxed/register-boxed.component';
import { TicketsComponent } from './components/farmer/tickets.component';
import { ListTicketsComponent } from './components/farmer/list-tickets/list-tickets.component';
import { MatSortModule } from '@angular/material/sort';
import { InitCollectableComponent } from './components/farmer/init-collectable/init-collectable.component';
import { UpdateCollectableComponent } from './components/farmer/update-collectable/update-collectable.component';
import { CollectorComponent } from './components/collector/collector.component';
import { CollectorListTicketsComponent } from './components/collector/collector-list-tickets/collector-list-tickets.component';
import { CollectorCollectComponent } from './components/collector/collector-collect/collector-collect.component';
import { TicketsControllerComponent } from './components/admin/tickets-controller/tickets-controller.component';
import { MainControllerComponent } from './components/admin/main-controller/main-controller.component';
import { TicketMobileComponent } from './components/ticket-mobile/ticket-mobile.component';
import { PageTwoComponent } from './components/ticket-mobile/page-two/page-two.component';
import { PageOneComponent } from './components/ticket-mobile/page-one/page-one.component';

import { MatIconModule } from '@angular/material/icon';
 
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { PageThreeComponent } from './components/ticket-mobile/page-three/page-three.component';
import { PageFourComponent } from './components/ticket-mobile/page-four/page-four.component';
import { PageFiveComponent } from './components/ticket-mobile/page-five/page-five.component';
import { PageSixComponent } from './components/ticket-mobile/page-six/page-six.component';
import { PageSevenComponent } from './components/ticket-mobile/page-seven/page-seven.component';
import { PageEightComponent } from './components/ticket-mobile/page-eight/page-eight.component';
import { PageNineComponent } from './components/ticket-mobile/page-nine/page-nine.component';
import { PageTenComponent } from './components/ticket-mobile/page-ten/page-ten.component';
import { PageElevenComponent } from './components/ticket-mobile/page-eleven/page-eleven.component';
import { UpdateTicketComponent } from './components/collector/update-ticket/update-ticket.component';
import { ShipperComponent } from './components/shipper/shipper.component';
import { ShipperListTicketsComponent } from './components/shipper/shipper-list-tickets/shipper-list-tickets.component';
import { ConfirmComponent } from './components/shipper/confirm/confirm.component';
import { ReadQrComponent } from './components/read-qr/read-qr.component';
import { PackagingComponent } from './components/packaging/packaging.component';
import { UploadComponent } from './components/farmer/upload/upload.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { UpdateShipmentComponent } from './components/shipper/update-shipment/update-shipment.component';
import { ListPackagingComponent } from './components/packaging/list-packaging/list-packaging.component';
import { ConfirmPackagingComponent } from './components/packaging/confirm-packaging/confirm-packaging.component';
import { UpdatePackagingComponent } from './components/packaging/update-packaging/update-packaging.component';
import { HistoryComponent } from './components/admin/history/history.component';
import { TableHistoryComponent } from './components/admin/history/table-history/table-history.component'; 
import { FileDragNDropDirective } from './data/file-drag-n-drop.directive';
import { ProfileComponent } from './components/admin/profile/profile.component';
import { BottelingComponent } from './components/botteling/botteling.component';
import { ConfirmBottelingComponent } from './components/botteling/confirm-botteling/confirm-botteling.component';
import { ListBottelingComponent } from './components/botteling/list-botteling/list-botteling.component';
import { UpdateBottelingComponent } from './components/botteling/update-botteling/update-botteling.component';
import { ProfileUpdateComponent } from './shared/profile-update/profile-update.component'; 
import { UpdateFinalComponent } from './components/admin/history/update-final/update-final.component';
import { DisableMousewheelDirective } from './utils/disable-mousewheel.directive';
import { BlockchainComponent } from './components/admin/history/blockchain/blockchain.component';
import { DialogComponent } from './components/collector/dialog/dialog.component'; 
// Angular Material

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    AppsLayoutComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UpdateFinalComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,
    FooterMenuComponent,

    // Auth
    LoginComponent,
    ForgotPasswordComponent,
    RegisterBoxedComponent,

    // table
    TableComponent,
    TicketsComponent,
    ListTicketsComponent,
    InitCollectableComponent,
    UpdateCollectableComponent,
    CollectorComponent,
    CollectorListTicketsComponent,
    CollectorCollectComponent,
    TicketsControllerComponent,
    MainControllerComponent,
    TicketMobileComponent,
    PageTwoComponent,
    PageOneComponent,
    PageThreeComponent,
    PageFourComponent,
    PageFiveComponent,
    PageSixComponent,
    PageSevenComponent,
    PageEightComponent,
    PageNineComponent,
    PageTenComponent,
    PageElevenComponent,
    UpdateTicketComponent,
    ShipperComponent,
    ShipperListTicketsComponent,
    ConfirmComponent,
    ReadQrComponent,
    PackagingComponent,
    UploadComponent,
    PrivacyPolicyComponent,
    UpdateShipmentComponent,
    ListPackagingComponent,
    ConfirmPackagingComponent,
    UpdatePackagingComponent,
    HistoryComponent,
    TableHistoryComponent, 
    FileDragNDropDirective,
    ProfileComponent,
    BottelingComponent,
    ConfirmBottelingComponent,
    ListBottelingComponent,
    UpdateBottelingComponent,
    ProfileUpdateComponent,
    DisableMousewheelDirective,
    BlockchainComponent,
    DialogComponent, 
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components


    PdfViewerModule,
    PerfectScrollbarModule,
    MatTableModule,
    MatSortModule,
    // MatSnackBarModule,
    // MatDialogModule,
    NgbModule,
    FontAwesomeModule,
    LaddaModule,
    MatTreeModule, 
    FormsModule,
    MatIconModule,
    MatListModule,
    MatListModule, MatButtonModule, MatGridListModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    PdfViewerModule,
    MatDialogModule,
    MatPaginatorModule,
    ToastrModule.forRoot(),
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // CountUpModule,
    // CountUpModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyCVGVkOP0qKyRmtne4QOI2iJs33C3zHk3M', libraries: ["places"]
    }),
    // ImageCropperModule,
    // NouisliderModule,
    NgSelectModule,
    // SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    
    // JwBootstrapSwitchNg2Module,
    // AngularEditorModule,
    HttpClientModule,
    // TextMaskModule,
    // ClipboardModule,
    // TextareaAutosizeModule,
    // ColorPickerModule,
    // DropzoneModule,

    // Charts 

    // TrendModule,

    // Angular Material Components

    // MatCheckboxModule

  ],
  providers: [
    DatePipe,
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    ThemeOptions
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]
    );

  }
}
