import { Component, ElementRef, ViewChild, NgZone, AfterViewInit, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { AppCoreService } from 'src/app/services/app-core.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getUser, getUserId } from 'src/app/utils/preserved-storage';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-init-collectable',
  templateUrl: './init-collectable.component.html',
  styleUrls: ['./init-collectable.component.css']
})
export class InitCollectableComponent implements AfterViewInit, OnInit {

  // google maps zoom level
  zoom = 8;
  //set latitude, longitude and zoom
  latitude: number
  longitude: number
  id = getUserId()

  selectedOrigin: any;
  origins = [
    { name: "Mexico" },
    { name: "Argentina" },
    { name: "Spain" },
    { name: "Romania" },
    { name: "Hungary" },
    { name: "Greece" },
    { name: "New Zealand" },
    { name: "Australia" },
    { name: "Chile" },
    { name: "Brazil" },
    { name: "Saudi Arabia" },
    { name: "France" },
  ];

  selectedkind: any;
  kind = [
    { name: "Organic Mexican West Coast" },
    { name: "Organic Patagonia" },
    { name: "Black Forest" },
    { name: "Acacia" },
    { name: "Pine" },
    { name: "Chestnut" },
    { name: "Oak" },
    { name: "Orange" },
    { name: "Linden" },
    { name: "Organic Yucatan" },
    { name: "Ulmo" },
    { name: "Manuka" },
    { name: "Sidr" },
  ];

  constructor(private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }

  @ViewChild('search') searchElementRef: ElementRef;
  dataform: FormGroup = new FormGroup({});

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.lat = this.latitude
          this.lng = this.longitude
          this.zoom = 12;
          this.markers = [{
            lat: this.latitude,
            lng: this.longitude,
            label: 'A',
            draggable: true,
          }]
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.findAdress()
  }
  ngOnInit(): void {
    this.initForm()

  }
  // initial center position for the map
  lat = 25.1701904;
  lng = 55.3002024;

  markers: Marker[] = [];

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {

  }

  markerDragEnd(m: Marker, event: any) {
    console.log('dragEnd', m, event);
    this.latitude = event.coords.lat
    this.longitude = event.coords.lng
  }

  initForm() {
    this.dataform = this.formBuilder.group({
      honey_weight: new FormControl('', [Validators.required]),
    })
  }

  initiate() {
    Swal.showLoading()
    if (this.dataform.valid && this.latitude && this.longitude && this.selectedOrigin && this.selectedkind) {

      console.log(this.selectedOrigin, this.selectedkind)
      this.appCoreService.initiate_collectable({ ...this.dataform.value, ...{ lat: this.latitude, lng: this.longitude, honey_origin: this.selectedOrigin.name, honey_kind: this.selectedkind.name, keeper: getUser() } }, this.id).subscribe(data => {
        Swal.close()
        if (data.status === 200) {
          Swal.fire({
            title: 'Ticket Initiated successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/farmer')
            } else {
              setTimeout(() => {
                this.router.navigateByUrl('/farmer')
              }, 500);
            }
          })
        } else {
          Swal.close()
          this.Toastr.error(data.message)
        }
      })
    }
    else {
      Swal.close()
      Swal.fire({
        icon: "error",
        title: 'Please enter fields properly',

      })
    }
  }
}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
