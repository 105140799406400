import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDataHandler } from "../utils/ErrorDataHandler";
import { catchError, tap } from "rxjs/operators";
import { ApiUrl } from '../data/api-urls';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {

  constructor(private sharedService: SharedService, private toastr: ToastrService) { }

  register(data: any): Observable<any> {
    const url: string = ApiUrl.register_user();
    console.log(data)
    return this.sharedService.post(url, data).pipe(
      tap(data => {
        return data
      }), catchError(async err => { if (err.body === 'admin access only') { this.toastr.error('admin access only') } else { this.toastr.error('Invalid creds provided '); console.log(err) } }
      ))
  }

  list_collectables(id?: string): Observable<any> {
    let url = ApiUrl.list_collectables();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  upload_media(ticket_id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_media(ticket_id);
    console.log(data)
    return this.sharedService.post(url, data)
  }

  upload_medi_shipper(ticket_id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_media_shipper(ticket_id);
    console.log(data)
    return this.sharedService.post(url, data)
  }
  upload_media_inspector(ticket_id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_media_inspector(ticket_id);
    console.log(data)
    return this.sharedService.post(url, data)
  }
  upload_media_botteling(ticket_id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_media_botteling(ticket_id);
    console.log(data)
    return this.sharedService.post(url, data)
  }

  upload_profile(id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_profile(id);
    console.log(data)
    return this.sharedService.post(url, data)
  }
  update_profile(id: string, data?: any): Observable<any> {
    let url = ApiUrl.update_profile(id);
    console.log(data)
    return this.sharedService.post(url, data)
  }


  upload_cert_shipper(id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_cert_shipper(id);
    console.log(data)
    return this.sharedService.post(url, data)
  }


  upload_cert_inspector(id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_cert_inspector(id);
    console.log(data)
    return this.sharedService.post(url, data)
  }

  upload_cert_botteling(id: string, data?: any): Observable<any> {
    let url = ApiUrl.upload_cert_botteling(id);
    console.log(data)
    return this.sharedService.post(url, data)
  }

  get_media(ticket_id: string): Observable<any> {
    let url = ApiUrl.get_media(ticket_id);
    return this.sharedService.get(url)
  }
  get_media_shipper(ticket_id: string): Observable<any> {
    let url = ApiUrl.get_media_shipper(ticket_id);
    return this.sharedService.get(url)
  }
  get_media_inspector(ticket_id: string): Observable<any> {
    let url = ApiUrl.get_media_inspector(ticket_id);
    return this.sharedService.get(url)
  }
  get_media_botteling(ticket_id: string): Observable<any> {
    let url = ApiUrl.get_media_botteling(ticket_id);
    return this.sharedService.get(url)
  }

  del_media(ticket_id: string, key: string, role?: string): Observable<any> {
    let url = ApiUrl.del_media();
    return this.sharedService.post(url, { ticket_id, key, role })
  }

  collect_colelctables(data: any): Observable<any> {
    let url = ApiUrl.collect_colelctables();
    return this.sharedService.post(url, data)
  }
  collect_shipments(data: any): Observable<any> {
    let url = ApiUrl.collect_shipments();
    return this.sharedService.post(url, data)
  }
  collect_inspector(data: any): Observable<any> {
    let url = ApiUrl.collect_inspector();
    return this.sharedService.post(url, data)
  }
  collect_botteling(data: any): Observable<any> {
    let url = ApiUrl.collect_botteling();
    return this.sharedService.post(url, data)
  }
  list_collectables_by_id(id: string): Observable<any> {
    let url = ApiUrl.list_collectables_by_id(id);
    return this.sharedService.get(url)
  }
  get_ticket(id: string): Observable<any> {
    let url = ApiUrl.get_ticket(id);
    return this.sharedService.get(url)
  }

  list_tickets(id?: string, ticket_id?: string): Observable<any> {
    let url = ApiUrl.list_tickets();
    if (id) {
      url = url + `/${id}`
    } if (ticket_id) {
      url = url + `/${ticket_id}`
    }
    return this.sharedService.get(url)
  }
  list_tickets_all(id?: string, ticket_id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_all();

    return this.sharedService.get(url)
  }
  list_tickets_shipper(id?: string, ticket_id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_shipper();
    if (id) {
      url = url + `/${id}`
    } if (ticket_id) {
      url = url + `/${ticket_id}`
    }
    return this.sharedService.get(url)
  }
  list_tickets_inspector(id?: string, ticket_id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_inspector();
    if (id) {
      url = url + `/${id}`
    } if (ticket_id) {
      url = url + `/${ticket_id}`
    }
    return this.sharedService.get(url)
  }
  list_tickets_inspector_temp(id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_inspector_temp();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }
  list_tickets_inspector_temp_ticket(id: string): Observable<any> {
    let url = ApiUrl.list_tickets_inspector_temp_ticket();
    url = url + `/${id}`
    return this.sharedService.get(url)
  }
  list_tickets_botteling_temp_ticket(id: string): Observable<any> {
    let url = ApiUrl.list_tickets_botteling_temp_ticket();
    url = url + `/${id}`
    return this.sharedService.get(url)
  }
  list_tickets_botteling_temp(id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_botteling_temp();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }
  list_tickets_botteling(id?: string, ticket_id?: string): Observable<any> {
    let url = ApiUrl.list_tickets_botteling();
    if (id) {
      url = url + `/${id}`
    } if (ticket_id) {
      url = url + `/${ticket_id}`
    }
    return this.sharedService.get(url)
  }
  list_ticket_final(id: string): Observable<any> {
    let url = ApiUrl.list_ticket_final();
    url = url + `/${id}`
    return this.sharedService.get(url)
  }
  get_profile(id?: string): Observable<any> {
    let url = ApiUrl.get_profile();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  list_farmers(id?: string[]): Observable<any> {
    let url = ApiUrl.list_farmers();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }
  list_shippers(id?: string[]): Observable<any> {
    let url = ApiUrl.list_shippers();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  list_collectors(id?: string[]): Observable<any> {
    let url = ApiUrl.list_collectors();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  list_User(id?: string): Observable<any> {
    let url = ApiUrl.list_User();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  list_inspectors(id?: string[]): Observable<any> {
    let url = ApiUrl.list_inspectors();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }
  list_botteling(id?: string[]): Observable<any> {
    let url = ApiUrl.list_botteling();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  list_admins(id?: string[]): Observable<any> {
    let url = ApiUrl.list_admins();
    if (id) {
      url = url + `/${id}`
    }
    return this.sharedService.get(url)
  }

  suspend(id: number): Observable<any> {
    let url = ApiUrl.suspend(id)
    return this.sharedService.get(url).pipe(
      tap(data => {
      }),
    );
  }

  initiate_collectable(data: any[], id: number): Observable<any> {
    let url = ApiUrl.initiate_collectables(id);
    return this.sharedService.post(url, data)
  }
  initiate_ticket(id: number): Observable<any> {
    let url = ApiUrl.initiate_ticket(id);
    return this.sharedService.post(url, {})
  }
  update_collectable(data: any): Observable<any> {
    let url = ApiUrl.update_collectable();
    return this.sharedService.put(url, data)
  }
  setcert(data: any): Observable<any> {
    let url = ApiUrl.set_cert();
    return this.sharedService.put(url, data)
  }
  remcert(data: any): Observable<any> {
    let url = ApiUrl.rem_cert();
    return this.sharedService.put(url, data)
  }
  update_ticket(data: any): Observable<any> {
    let url = ApiUrl.update_ticket();
    return this.sharedService.put(url, data)
  }
  update_ticket_shipper(data: any): Observable<any> {
    let url = ApiUrl.update_ticket_shipper();
    return this.sharedService.put(url, data)
  }
  update_ticket_inspector(data: any): Observable<any> {
    let url = ApiUrl.update_ticket_inspector();
    return this.sharedService.put(url, data)
  }
  update_ticket_botteling(data: any): Observable<any> {
    let url = ApiUrl.update_ticket_botteling();
    return this.sharedService.put(url, data)
  }
  update_blockchain(id: any): Observable<any> {
    let url = ApiUrl.update_ticket_blockchain(id);
    return this.sharedService.get(url)
  }
  update_blockchain_shipping(id: any): Observable<any> {
    let url = ApiUrl.update_ticket_blockchain_shipping(id);
    return this.sharedService.get(url)
  }
  update_blockchain_inspector(id: any): Observable<any> {
    let url = ApiUrl.update_ticket_blockchain_inspector(id);
    return this.sharedService.get(url)
  }
  update_blockchain_botteling(id: any): Observable<any> {
    let url = ApiUrl.update_ticket_blockchain_botteling(id);
    return this.sharedService.get(url)
  }

  update_blockchain_real(id: any): Observable<any> {
    let url = ApiUrl.update_ticket_real(id);
    return this.sharedService.get(url)
  }

  delete(id): Observable<any> {
    let url = ApiUrl.delete(id)
    return this.sharedService.delete(url).pipe(
      tap(data => {
      }),
    );
  }
  delete_collectable(id): Observable<any> {
    let url = ApiUrl.delete_collectable(id)
    return this.sharedService.delete(url).pipe(
      tap(data => {
      }),
    );
  }
  delete_ticket(id): Observable<any> {
    let url = ApiUrl.delete_ticket(id)
    return this.sharedService.delete(url).pipe(
      tap(data => {
      }),
    );
  }
  cancel_ticket(data): Observable<any> {
    let url = ApiUrl.cancel_ticket()
    return this.sharedService.post(url, data).pipe(
      tap(data => {
      }),
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      //   // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    // return throwError(errorMessage);
    return new ErrorDataHandler(err).handleError({});
  }

}
