<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">Honey Filling
            and Labeling</h2>
        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{ticket.batch_no}}</span><br>
        <div style="padding: 0px 5vh;text-align: center;">

            <span class="batch_title" *ngIf="ticket.page_9_section_1">{{ticket.page_9_section_1}}
                <br>
                <br>
                We filled from batch Number {{ticket.batch_no}} a total
                {{ticket.batch_bottles}} bottles of 500 gm
                <br>
                <br> {{ticket.page_9_section_2}}</span>
            <span class="batch_title" *ngIf="!ticket.page_9_section_1">After receiving the collected honey at our plant,
                we fill
                it and label it with ALSHIFA labels
                <br>
                <br>
                We filled from batch No {{ticket.batch_no}} a total
                {{ticket.batch_bottles}} bottles of 500 gm
                <br>
                <br>Our Plant has been awarded the below certificates for the high-quality standards we apply</span>
        </div>
        <div class="logos">
            <img src="../../../../assets/images/clients.png" alt="clients" style="width: 100%;margin: 40px 0;">
        </div>
    </div>
</div>