import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getrole } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-shipper',
  templateUrl: './shipper.component.html',
  styleUrls: ['./shipper.component.sass']
})
export class ShipperComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
    console.log(this.get_title())
  }

  get_title(): string[] {

    if (getrole() === 'admin') {
      switch (this.route.url) {
        case "/tickets/shipper":
          return ["Shipper Tickets", "Manage all your Shipper's tickets here"]
        case "/shipper/confirm":
          return ["Confirm Shipping", "Please clearfully read before collecting as it is irreversible task"]
        case "/shipper/update":
          return ["Add shipping Information", "Please clearfully read before adding data"]
        case "/shipper/privacy":
          return ["Privacy & policy", "By using the following services you agree to these terms!"]
        default:
          return ["Unknown route", "Wrong url entered!"]
      }
    }
    switch (this.route.url) {
      case "/shipper":
        return ["Tickets collected for shipping", "Manage all your shipments here"]
      case "/shipper/confirm":
        return ["Confirm Shipping", "Please clearfully read before collecting as it is irreversible task"]
      case "/shipper/update":
        return ["Add Shipping Information", "Please clearfully read before adding data"]
      case "/shipp  er/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }
  }
}
