import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {

  constructor(private service:AuthService) { }

  @Input() sidebar: boolean
  ngOnInit() {

  }

}
