<style>
  .img_cvr {
    width: 50px;
    height: 50px;
    border-radius: 20px;
  }
</style>
<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" style="background: #fff;"
                    [src]="profile?profile:'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png'"
                    alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner" style="background-color:#987b59;">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-start">
                    <div class="widget-content p-role">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left me-3">
                          <input type="file" style="display:none;" #img (change)="onFileSelected($event)"
                            accept=".png, .jpeg, .jpg">
                          <div class="img_cvr">
                            <img width="42"
                              [src]="profile?profile:'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png'"
                              alt="" class="rounded-circle" (click)="img.click()"
                              style="background: #fff;cursor: pointer;">
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading" style="text-transform: capitalize;">{{username}}</div>
                          <div class="widget-subheading opacity-8" style="text-transform: capitalize;">
                            {{role==="farmer"?"Beekeeper":role}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <li class="nav-item-header nav-item">Activity</li>
                    <li class="nav-item"><a (click)="setUser()" class="nav-link">Update Profile</a></li>
                    <li class="nav-item"><a routerLink="/forget_password" class="nav-link">Change password</a></li>
                    <li class="nav-item"><a (click)="logout()" class="nav-link">Logout</a></li>
                  </ul>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-content-left ms-3 header-user-info">
          <div class="widget-heading" style="text-transform: capitalize;">{{username}}</div>
          <div class="widget-subheading" style="text-transform: capitalize;">{{role==="farmer"?"Beekeeper":role}}</div>
        </div>
      </div>
    </div>
  </div>
</div>