import { Component, OnInit } from '@angular/core';
import { faCalendar, faCaretDown, faInfo, faTools } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../../../theme-options';
import { AuthService } from 'src/app/services/auth.service';
import { getUser, getUserId, getrole, setProfile, setProfile_pic, setRole, setToken } from 'src/app/utils/preserved-storage';
import { Router } from '@angular/router';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  faCalendar = faCalendar;

  id: number = getUserId();
  search = faInfo;
  profile: string
  gear = faTools
  caretd = faCaretDown
  username: string | null;
  role: string
  constructor(private authService: AuthService, private router: Router, private appCoreService: AppCoreService) { }

  ngOnInit(): void {
    this.username = getUser()
    this.role = getrole()

    this.appCoreService.list_User(this.id.toString()).subscribe(data => {
      setProfile_pic(data[0].profile)
      this.profile = data[0].profile;
    })
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      // Check if the selected file is of an allowed file type
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (allowedTypes.includes(file.type)) {
        // Create FormData object
        const formData = new FormData();
        formData.append('file', file);

        // Make the POST request to the API
        this.appCoreService.upload_profile(getUserId(), formData).subscribe(
          (response) => {
            console.log('File uploaded successfully.');
            if (response.status === 200)
              window.location.reload();
            console.log('Response:', response);
          },
          (error) => {
            console.error('File upload failed.');
            console.error('Error:', error);
          }
        );
      } else {
        console.error('Invalid file type. Please select a PNG, JPEG, or JPG file.');
      }
    }
  }

  setUser() {
    if (this.role === 'admin') {
      this.appCoreService.list_admins(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    }
    else if (this.role === 'farmer') {
      this.appCoreService.list_farmers(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    } else if (this.role === 'collector') {
      this.appCoreService.list_collectors(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    } else if (this.role === 'shipper') {
      this.appCoreService.list_shippers(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    } else if (this.role === 'botteling') {
      this.appCoreService.list_botteling(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    } else if (this.role === 'inspector') {
      this.appCoreService.list_inspectors(getUserId()).subscribe(data => {
        setProfile(JSON.stringify(data[0]))
        this.router.navigate(['/profile/update']);
      })
    }
    else {
      console.log("invalid action")
    }

  }


  logout() {
    this.authService.logout().subscribe(data => {
      setToken('')
      setRole('guest')
      setProfile_pic('')
      this.router.navigate(['/login'])
    })
  }
}
