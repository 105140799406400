<div style="display: flex; flex-direction: column;">
    <div style="float: right; width: 100%; text-align: right; margin-right: 5px; margin-bottom: 20px;">
        <input matInput class="form-control" style="width: 25%; float: right;" (keyup)="applyFilter($event)"
            placeholder="Filter...">
    </div>
    <div class="example-container mat-elevation-z8">
        <table mat-table [dataSource]="dataSource2" multiTemplateDataRows class="mat-elevation-z8"
            style="max-height: 30vh;overflow: scroll;">
            <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef class="mat-header-row">
                    {{formatColumnName(column)}}
                </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-row">
                    <button title="Confirm ticket" class="btn btn-danger" *ngIf="column === 'edit' && element.cancelled"
                        (click)="showReason(element.cancelled_reason)">Paused</button>
                    <button title="Confirm ticket" class="btn bg-3"
                        *ngIf="column === 'edit' && element.status ==='bottled' && !element.cancelled"
                        (click)="go_blockchain_real(element)">Confirm</button>
                    <button title="Start blockchain" class="btn bg-3"
                        *ngIf="column === 'edit' && element.status ==='bottled' && !element.cancelled"
                        (click)="update_collectable(element)"><fa-icon [icon]="edit"></fa-icon></button>
                    {{ (element === 'updated') ? formatTimestamp(element[column]) : element[column] || '' }}
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="mat-row">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="table_status && !tree.Beekeepers">{{table_status}}</div>
                        <mat-list role="list" *ngIf="tree">
                            <mat-tree style="min-height: 200px;" [dataSource]="dataSource" [treeControl]="treeControl">
                                <!-- This is the tree node template for leaf nodes -->
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                                    <mat-list-item>
                                        {{node.name}}
                                        <button mat-icon-button disabled></button>
                                    </mat-list-item>
                                </mat-tree-node>
                                <!-- This is the tree node template for expandable nodes -->
                                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                                    <mat-list-item>
                                        {{node.name}}
                                        <button mat-icon-button matTreeNodeToggle
                                            [attr.aria-label]="'toggle ' + node.name">
                                            <mat-icon class="mat-icon-rtl-mirror">
                                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-tree-node>
                            </mat-tree>
                        </mat-list>
                        <div style="position: absolute;right: 50px;top: 10px; z-index: 900; display: flex;flex-direction: column;"
                            *ngIf="element == expandedElement ">
                            <a [href]="img_url" [download]="element.ticket_id+'.png'">
                                <img [src]="img_url" [alt]="img_url">
                            </a>
                            <!-- <button *ngIf="img_url && element.status === 'confirmed'" class="btn btn-success"
                                style="color:#fff;width: 80%;margin-left: 15px;margin-top: 10px;">
                                verify
                            </button> -->
                            <button *ngIf="img_url && element.status !== 'confirmed' && element.status !== 'verified'"
                                class="btn btn-danger" style="color:#fff;width: 80%;margin-left: 15px;margin-top: 10px;"
                                (click)="cancel(element.ticket_id,element.cancelled)">
                                {{element.cancelled?'Resume Tikcet':'Pause Ticket'}}
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-row"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element;getdata(element)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <h2 style="padding: 20px;" *ngIf="!data">{{ table_status }}</h2>
        <div class="card main-card mb-3">
            <div class="card-body"
                style="padding: 10px; background-color: #f0f0f0; border: 1px solid #ddd; border-radius: 5px;display: inline-flex;justify-content: end;">
                <div
                    style="width: auto; margin-right: 20px;display: inline-flex;justify-content: space-between;align-items: center;">
                    <p style="align-items: center;vertical-align: middle;margin-top: 20px;margin-right: 20px;">
                        Page size:
                    </p>
                    <ng-select class="pl-bl" [items]="[10,20,40,60,120]" bindLabel="name" bindValue="id"
                        (change)="setLimit($event)" [placeholder]="pageSize"
                        style="margin-top: 10px;width:80px;"></ng-select>
                </div>
                <div style="display: flex;justify-content: center;align-items: center; width:100px">
                    <fa-icon (click)="previousPage()" [icon]="left" style="cursor: pointer;"></fa-icon>
                    <span *ngFor="let item of [1, 2, 3, 4, 5, 6]">
                        &nbsp;
                    </span>
                    <fa-icon (click)="nextPage()" [icon]="right" style="cursor: pointer;"></fa-icon>
                </div>
            </div>
        </div>
    </div>