import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AppCoreService } from 'src/app/services/app-core.service';
import { faEdit, faTrash, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { setProfile } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})

export class TableComponent implements OnInit, AfterViewInit {

  edit = faEdit
  bin = faTrash
  suspend = faUserAltSlash
  data: any[];
  displayedColumns = ['id', 'profile', 'username', "fullname", "email", "edit"];
  dataSource2: MatTableDataSource<any>;
  sec: boolean = false

  profile: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService) { }

  role: string;
  ngOnInit() {
    if (this.router.url.includes('/farmers')) {
      this.getusers("farmer")
      this.role = "farmer"
    } else if (this.router.url.includes('/collectors')) {
      console.log("getting c")
      this.getusers("collector")
      this.role = "collector"
    } else if (this.router.url.includes('/shippers')) {
      console.log("getting s")
      this.getusers("shipper")
      this.role = "shipper"
    } else if (this.router.url.includes('/inspectors')) {
      console.log("getting i")
      this.getusers("quality_inspection")
      this.role = "quality_inspection"
    } else if (this.router.url.includes('/botteling')) {
      console.log("getting i")
      this.getusers("botteling")
      this.role = "botteling"
    } else if (this.router.url.includes('/admins')) {
      console.log("getting ad")
      this.getusers("admins")
      this.role = "admins"
    }
  }

  getprofile(id) {
    this.appCoreService.get_profile(id).subscribe(data => {
      console.log(data.data)
      if (data.error) {
        return 'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png'
      } else {
        return data.data
      }
    })
  }

  navigateToUserProfile(row) {
    console.log(row)
    // Replace '/users/profile' with the actual URL of the user's profile page.
    setProfile(JSON.stringify(row))
    this.router.navigate(['/users/profile']);
  }

  getusers(role: string) {
    if (role === 'farmer') {
      this.appCoreService.list_farmers().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    } else if (role === 'collector') {
      this.appCoreService.list_collectors().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    } else if (role === 'shipper') {
      this.appCoreService.list_shippers().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    } else if (role === 'quality_inspection') {
      this.appCoreService.list_inspectors().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    } else if (role === 'botteling') {
      this.appCoreService.list_botteling().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    } else if (role === 'admins') {
      this.appCoreService.list_admins().subscribe(data => {
        setTimeout(() => {
          this.data = data
          this.dataSource2 = new MatTableDataSource(this.data);
          this.dataSource2.paginator = this.paginator;
          this.sec = true
        }, 600);
      })
    }
    else {
      console.log(this.role, "role")
    }

  }

  suspend_user(row: any) {
    let msg = 'Yes, suspend user!'
    if (row.suspended === true) {
      msg = 'Yes, Unsuspend user!'
    }
    Swal.fire({
      title: 'Are you sure?',
      text: !row.suspended ? 'This action will prohibit the user from performing any actions on his panel!' : "This action will allow the user to perform any action on his panel!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: msg
    }).then((result) => {
      if (result.value) {
        this.appCoreService.suspend(row.user_id).subscribe(data => {
          if (data.status === 200) {
            this.toastr.success("user status changed Successfully")
            this.getusers(this.role)
          } else {
            this.toastr.error(data.message)
          }
        })
      }
    })
  }
  applyFilter(Value: any) {
    let filterValue = Value.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }
  delete_user(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete user!'
    }).then((result) => {
      if (result.value) {
        this.appCoreService.delete(id).subscribe(data => {
          if (data.status === 200) {
            this.getusers(this.role)
            Swal.fire(
              'Deleted!',
              'User has been deleted.',
              'success'
            );
          } else if (data.status === 401) {
            this.toastr.error("something went wrong please try again later!")
          } else {
            this.toastr.error("An error occurred try again later")
          }
        })
      }
    });

  }

  ngAfterViewInit(): void {

  }
}

