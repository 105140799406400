<div class="container-fluid">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <form (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="dataform" (ngSubmit)="update()">
        <!-- hmf | moister  | ph | color | electrical_cunductivity | diastate -->
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">H.M.F :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="hmf" id="name"
                            placeholder="Enter hmf here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        mg/kg
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Moisture :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="moister" id="name"
                            placeholder="Enter Moisture here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        %
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Diastase :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="diastate" id="name"
                            placeholder="Enter Diastase here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        schade
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Color:</label></div>
            <div class="col-8">
                <ng-select [items]="color" bindLabel="name" placeholder="Select Color" [(ngModel)]="selectedcolor"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Acidity:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="ph" id="name" placeholder="Enter pH here..." disableMousewheel>
            </div>
        </div>

        <!-- New fields -->
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Invert Sugar:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="invert_sugar" id="name"
                    placeholder="Enter Invert Sugar here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Suctose:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="suctose" id="name"
                    placeholder="Enter Suctose here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fg Ratio:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="fg_ratio" id="name"
                    placeholder="Enter Fg ratio here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Ash:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="ash" id="name"
                    placeholder="Enter Ash here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Appearance:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="appearence" id="name"
                    placeholder="Enter Appearance here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Cleanliness:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="cleanliness" id="name"
                    placeholder="Enter Cleanliness here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Odor:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="odder" id="name"
                    placeholder="Enter Odor here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Taste:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="taste" id="name"
                    placeholder="Enter Taste here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fermentation:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="fermentation" id="name"
                    placeholder="Enter Fermentation here..." disableMousewheel>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Certificate:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;" *ngFor="let i of current_vals.pdfs_inspector">
                        <img style="max-width: 50vw;" *ngIf="i && i !== null && !i.includes('.pdf')" [src]="i" alt="Not yet added">
                        <pdf-viewer *ngIf="i && i !== null && i.includes('.pdf')" [src]="i" [rotation]="0" [original-size]="false" [show-all]="true"
                            [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false"
                            [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
                            [show-borders]="false" style="width: 40vw; height: 80vh;">
                        </pdf-viewer>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <label for="search">Honey location:</label>
                <p *ngIf="latitude">{{"lat: "+latitude+", lng: "+longitude}}</p>
                <input style="margin-top: 20px;" id="search" #search autocorrect="off" autocapitalize="off"
                    spellcheck="off" type="text" class="form-control" *ngIf="current_vals.status === 'inspecting'">
                <div class="card main-card mb-3 mt-2">
                    <div class="card-body">
                        <agm-map style="height: 450px;" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                            [disableDefaultUI]="false" [zoomControl]="true" (mapClick)="mapClicked($event)">

                            <agm-marker *ngFor="let m of markers; let i = index"
                                (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat" [longitude]="m.lng"
                                [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">

                                <agm-info-window>
                                    <strong>InfoWindow content</strong>
                                </agm-info-window>

                            </agm-marker>

                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <button type="submit" class="btn bg-3" style="font-size: medium;font-weight: 400; float: right;"
                *ngIf="current_vals.status === 'inspecting'">Update
                info</button>
        </div>

    </form>
</div>