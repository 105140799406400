<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">Honey Shipping
            Details</h2>

        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span><br>

        <div style="padding: 0px 5vh;text-align: center;">
            <span class="batch_title" style="font-size: 15px;">

                This batch has been shipped by:</span>
        </div>

        <div class="container" style="height: 999px;padding: 20px;cursor: pointer;" (click)="openModal()">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <tbody role="rowgroup" *ngIf="current_ticket">
                            <tr role="row">
                                <th role="columnheader" scope="col" style="max-width: 50%;" tabindex="0">Sipping Line</th>
                                <th style="max-width: 50%;" role="columnheader" scope="col" tabindex="0">MAERSK</th>
                            </tr>
                            <tr role="row">
                                <td style="max-width: 50%;">Port of Discharge</td>
                                <td style="max-width: 50%;">{{current_ticket.port?current_ticket.port:"NA"}}</td>
                            </tr>
                            <tr role="row">
                                <td style="max-width: 50%;">BOL No.</td>
                                <td style="max-width: 50%;">{{current_ticket.bol?current_ticket.bol:"NA"}}</td>

                            </tr>
                            <tr role="row">
                                <td style="max-width: 50%;">Approximate Transit Time</td>
                                <td style="max-width: 50%;">{{current_ticket.approx_transit_time?current_ticket.approx_transit_time:"NA"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>