<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;" *ngIf="current_ticket">
        <h2 class="title" style="margin-top: 10px;">Honey Collection center</h2>

        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span><br>

        <div style="padding: 0px 5vh;text-align: center;">
            <span class="batch_title" style="font-size: 15px;"
                *ngIf="current_ticket.page_5_section_1">{{current_ticket.page_5_section_1}}</span>
            <span *ngIf="!current_ticket.page_5_section_1" class="batch_title" style="font-size: 15px;">This bottle of honey <span style="color: #CCA15B;">batch
                    number
                    {{current_ticket.batch_no}}</span> comes from
                several beekeepers and the honey collected was homogenized in one lot {{current_ticket.lot_no}} to
                ensure every bottle
                has honey with uniform content </span>
        </div>

        <div class="container" style="height: 999px;padding: 20px;cursor: pointer;" (click)="openModal()">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"
                                    style="display: flex; justify-content: baseline; align-items: baseline;"> Lot No.
                                </th>
                                <th role="columnheader" scope="col" tabindex="0">Honey Type</th>
                                <th role="columnheader" scope="col" tabindex="0">Qty, Kg </th>
                                <th role="columnheader" scope="col" tabindex="0">Bee keeper </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="collectableData">
                            <tr *ngFor="let ticket of collectableData; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ ticket.honey_kind }}</td>
                                <td>{{ ticket.honey_weight }}</td>
                                <td>{{ ticket.keeper }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>