import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getrole } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-botteling',
  templateUrl: './botteling.component.html',
  styleUrls: ['./botteling.component.css']
})
export class BottelingComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.get_title()
  }
  get_title(): string[] {

    if(getrole() === 'admin'){ 
      switch (this.route.url) {
        case "/tickets/botteling":
          return ["Botteling Tickets", "Manage all your botteling batches here"]
        case "/botteling/confirm":
          return ["confirm", "Please clearfully read before collecting as it is irreversible task"]
        case "/botteling/update":
          return ["Add botteling and packaging Information", "Please clearfully read before adding data"]
        case "/botteling/privacy":
          return ["Privacy & policy", "By using the following services you agree to these terms!"]
        default:
          return ["Unknown route", "Wrong url entered!"]
      }
    }
    switch (this.route.url) {
      case "/botteling":
        return ["Tickets collected from inspector", "Manage all your batches here"]
      case "/botteling/confirm":
        return ["confirm", "Please clearfully read before collecting as it is irreversible task"]
      case "/botteling/update":
        return ["Add botteling and packaging Information", "Please clearfully read before adding data"]
      case "/botteling/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }

  }
}