import { Component, ElementRef, ViewChild, NgZone, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { AppCoreService } from 'src/app/services/app-core.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getUserId, getcollectable, removeCollectable } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-collectable',
  templateUrl: './update-collectable.component.html',
  styleUrls: ['./update-collectable.component.css']
})
export class UpdateCollectableComponent implements AfterViewInit, OnInit, OnDestroy {

  current_vals: any;
  // google maps zoom level
  zoom = 8;
  //set latitude, longitude and zoom
  latitude: number
  longitude: number
  id = getUserId()
  // initial center position for the map
  lat: any;
  lng: any;
  markers: Marker[] = [];


  selectedOrigin: any;
  origins = [
    { name: "Mexico" },
    { name: "Argentina" },
    { name: "Spain" },
    { name: "Romania" },
    { name: "Hungary" },
    { name: "Greece" },
    { name: "New Zealand" },
    { name: "Australia" },
    { name: "Chile" },
    { name: "Brazil" },
    { name: "Saudi Arabia" },
    { name: "France" },
  ];

  selectedkind: any;
  kind = [
    { name: "Organic Mexican West Coast" },
    { name: "Organic Patagonia" },
    { name: "Black Forest" },
    { name: "Acacia" },
    { name: "Pine" },
    { name: "Chestnut" },
    { name: "Oak" },
    { name: "Orange" },
    { name: "Linden" },
    { name: "Organic Yucatan" },
    { name: "Ulmo" },
    { name: "Manuka" },
    { name: "Sidr" },
  ];

  constructor(private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }

  @ViewChild('search') searchElementRef: ElementRef;
  dataform: FormGroup = new FormGroup({});

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.lat = this.latitude
          this.lng = this.longitude
          this.zoom = 12;
          this.markers = [{
            lat: this.latitude,
            lng: this.longitude,
            label: 'A',
            draggable: true,
          }]
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.findAdress()
  }
  ngOnDestroy(): void {
    removeCollectable()
  }
  ngOnInit(): void {
    if (!getcollectable()) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'An error Occurred! please try again later',
        showConfirmButton: false,
        timer: 1000
      }).then(result => {
        this.router.navigateByUrl('/')
      })
    } else {
      this.current_vals = JSON.parse(getcollectable())
      this.selectedOrigin = this.current_vals.honey_origin
      this.selectedkind = this.current_vals.honey_kind
      this.latitude = this.current_vals.lat
      this.longitude = this.current_vals.lng
      this.initForm()
      this.markers = [{
        lat: this.latitude,
        lng: this.longitude,
        label: 'A',
        draggable: true,
      }]
    }
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {

  }

  markerDragEnd(m: Marker, event: any) {
    console.log('dragEnd', m, event);
    this.latitude = event.coords.lat
    this.longitude = event.coords.lng
  }

  initForm() {
    this.dataform = this.formBuilder.group({
      honey_origin: new FormControl(this.current_vals.honey_origin, [Validators.required]),
      honey_weight: new FormControl(this.current_vals.honey_weight, [Validators.required]),
      honey_kind: new FormControl(this.current_vals.honey_kind, [Validators.required])
    })
  }

  update() {
    Swal.showLoading()
    if (this.dataform.valid) {

      this.appCoreService.update_collectable({ ticket_id: this.current_vals.ticket_id, "updates": { ...this.dataform.value, ...{ lat: this.latitude, lng: this.longitude, honey_origin: this.selectedOrigin.name, honey_kind: this.selectedkind.name } } }).subscribe(data => {
        Swal.close()
        if (data.status === 200) {
          Swal.fire({
            title: 'Ticket Updated successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/farmer')
            } else {
              setTimeout(() => {
                this.router.navigateByUrl('/farmer')
              }, 500);
            }
          })
        } else {
          Swal.close()
          this.Toastr.error(data.message)
        }
      })
    }
    else {
      console.log(this.dataform.value)
      this.Toastr.error('Please enter fields properly')
    }
  }
}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

