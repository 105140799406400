import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { get_ticket } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-shipment',
  templateUrl: './update-shipment.component.html',
  styleUrls: ['./update-shipment.component.css']
})
export class UpdateShipmentComponent implements OnInit {

  constructor(private router: Router, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }


  current_vals: any;
  dataform: FormGroup = new FormGroup({});

  ngOnInit(): void {
    if (!get_ticket()) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'An error Occurred! please try again later',
        showConfirmButton: false,
        timer: 1000
      }).then(result => {
        this.router.navigateByUrl('/')
      })
    } else {
      this.current_vals = JSON.parse(get_ticket())
      console.log(this.current_vals)
      this.initForm()
    }
  }

  initForm() {
    this.dataform = this.formBuilder.group({
      bol: new FormControl(this.current_vals.bol, [Validators.required]),
      port: new FormControl(this.current_vals.port, [Validators.required]),
      approx_transit_time: new FormControl(this.current_vals.approx_transit_time, [Validators.required]),
      shipping_line: new FormControl(this.current_vals.shipping_line, [Validators.required])
    })
  }
  update() {
    Swal.showLoading()
    console.log({ ticket_id: this.current_vals.ticket_id, "updates": this.dataform.value })
    if (this.dataform.valid) {
      this.appCoreService.update_ticket_shipper({ ticket_id: this.current_vals.ticket_id, "updates": this.dataform.value }).subscribe(data => {
        Swal.close()
        if (data.status === 200) {
          Swal.fire({
            title: 'Ticket Updated successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/shipper')
            } else {
              setTimeout(() => {
                this.router.navigateByUrl('/shipper')
              }, 500);
            }
          })
        } else {
          Swal.close()
          this.Toastr.error(data.message)
        }
      })
    }
    else {
      console.log(this.dataform.value);

      // Find the invalid fields
      const invalidFields = [];
      for (const field in this.dataform.controls) {
        if (this.dataform.controls[field].invalid) {
          invalidFields.push(field);
        }
      }

      const errorMessage = `Please complete the following fields: ${invalidFields.join(", ")}`;
      Swal.close()

      this.Toastr.error(errorMessage);

    }
  }
}
