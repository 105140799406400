import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-ten',
  templateUrl: './page-ten.component.html',
  styleUrls: ['./page-ten.component.css']
})
export class PageTenComponent implements OnInit {
  ticket_id: number

  @Input() current_ticket: any;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current_ticket'].currentValue) {
      console.log(this.current_ticket, "ticket ten")
    }
  }

  @Output() modal = new EventEmitter<any[]>();

  ngOnInit(): void {
  }

  openModal() {
    this.modal.emit([true, 'botteling'])
  }
}

