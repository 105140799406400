<div class="container-fluid">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <form (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="dataform" (ngSubmit)="update()" *ngIf="current_vals">
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Honey Origin:</label></div>
            <div class="col-8">
                <ng-select [items]="origins" bindLabel="name" placeholder="Select city" [(ngModel)]="selectedOrigin"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>

        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Honey Weight (kg):</label></div>
            <div class="col-8"><input type="number" class="form-control" formControlName="honey_weight" id="name"
                    placeholder="Enter Honey Weight here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Honey Type:</label></div>
            <div class="col-8">
                <ng-select [items]="kind" bindLabel="name" placeholder="Select city" [(ngModel)]="selectedkind"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-12">
                <label for="search">Honey location:</label>
                <p *ngIf="latitude">{{"lat: "+latitude+", lng: "+longitude}}</p>
                <input style="margin-top: 20px;" id="search" #search autocorrect="off" autocapitalize="off"
                    spellcheck="off" type="text" class="form-control">
                <div class="card main-card mb-3 mt-2">
                    <div class="card-body">
                        <agm-map style="height: 450px;" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                            [disableDefaultUI]="false" [zoomControl]="true" (mapClick)="mapClicked($event)">

                            <agm-marker *ngFor="let m of markers; let i = index"
                                (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat" [longitude]="m.lng"
                                [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">

                                <agm-info-window>
                                    <strong>InfoWindow content</strong>
                                </agm-info-window>

                            </agm-marker>

                        </agm-map>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <button type="submit" class="btn bg-3"
                style="font-size: medium;font-weight: 400; float: right;">Update</button>
        </div>
    </form>

</div>