import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appFileDragNDrop]'
})
export class FileDragNDropDirective {

  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#ddd';
  @HostBinding('style.border-style') private background_border_style = 'dashed';
  @HostBinding('style.border-width') private background_border_width = '0.5px';
  @HostBinding('style.border-radius') private background_border_radius = '10px';

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#ddd'
  }

  @HostListener('drop', ['$event']) public onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.background = '#ddd';

    let files = e.dataTransfer.files;
    this.filesChangeEmiter.emit(Array.from(files));
  }

  @HostListener('click', ['$event']) public onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    // Trigger a click event on a hidden file input element with the "multiple" attribute
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true; // Allow multiple file selection
    fileInput.style.display = 'none';
    fileInput.accept = 'image/png, image/jpeg, image/jpg,application/pdf';
    fileInput.addEventListener('change', (event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        this.filesChangeEmiter.emit(Array.from(files));
      }
    });
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  }
}