import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-eleven',
  templateUrl: './page-eleven.component.html',
  styleUrls: ['./page-eleven.component.css']
})
export class PageElevenComponent implements OnInit {

  ticket_id : number
  @Input() ticket: any;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticket'].currentValue) {
      console.log(this.ticket, "ticket ten")
    }
  }

  @Output() modal = new EventEmitter<any[]>();
  ngOnInit(): void {
  }

  openModal() {
    this.modal.emit([true, 'image2'])
  }
}
