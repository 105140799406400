import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { get_ticket } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-botteling',
  templateUrl: './update-botteling.component.html',
  styleUrls: ['./update-botteling.component.css']
})
export class UpdateBottelingComponent implements OnInit {

  constructor(private router: Router, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }


  current_vals: any;
  dataform: FormGroup = new FormGroup({});

  selectedcolor: any;
  color = [
    { name: "Water White", value: "Water White" },
    { name: "Extra White", value: "Extra White" },
    { name: "White", value: "White" },
    { name: "Extra Light Amber", value: "Extra Light Amber" },
    { name: "Light Amber", value: "Light Amber" },
    { name: "Amber", value: "Amber" },
    { name: "Dark Amber", value: "Dark Amber" }
  ];

  ngOnInit(): void {
    if (!get_ticket()) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'An error Occurred! please try again later',
        showConfirmButton: false,
        timer: 1000
      }).then(result => {
        this.router.navigateByUrl('/')
      })
    } else {
      this.current_vals = JSON.parse(get_ticket())
      this.selectedcolor = this.current_vals.color
      console.log(this.current_vals)
      this.initForm()
    }
  }

  initForm() {
    // hmf | moister | acidity | ph | color | electrical_cunductivity | diastate
    this.dataform = this.formBuilder.group({
      hmf: new FormControl(this.current_vals.hmf, [Validators.required]),
      moister: new FormControl(this.current_vals.moister, [Validators.required]),
      ph: new FormControl(this.current_vals.ph, [Validators.required]),
      diastate: new FormControl(this.current_vals.diastate, [Validators.required]),
      fg_ratio: new FormControl(this.current_vals.fg_ratio, [Validators.required]),
      invert_sugar: new FormControl(this.current_vals.invert_sugar, [Validators.required]),
      suctose: new FormControl(this.current_vals.suctose, [Validators.required]),
      ash: new FormControl(this.current_vals.ash, [Validators.required]),
      appearence: new FormControl(this.current_vals.appearence, [Validators.required]),  // Replace with appropriate value or variable
      cleanliness: new FormControl(this.current_vals.cleanliness, [Validators.required]),  // Replace with appropriate value or variable
      odder: new FormControl(this.current_vals.odder, [Validators.required]),  // Replace with appropriate value or variable
      taste: new FormControl(this.current_vals.taste, [Validators.required]),  // Replace with appropriate value or variable
      fermentation: new FormControl(this.current_vals.fermentation, [Validators.required]), // Replace with appropriate value or variable
      batch_no: new FormControl(this.current_vals.batch_no, [Validators.required]),  // Replace with appropriate value or variable
      batch_bottles: new FormControl(this.current_vals.batch_bottles, [Validators.required])  // Replace with appropriate value or variable
    })
  }
  update() {
    Swal.showLoading()
    console.log({ ticket_id: this.current_vals.ticket_id, "updates": this.dataform.value })
    if (this.dataform.valid) {
      this.appCoreService.update_ticket_botteling({ ticket_id: this.current_vals.ticket_id, "updates": { ...this.dataform.value, color: this.selectedcolor.name } }).subscribe(data => {
        Swal.close()
        if (data.status === 200) {
          Swal.fire({
            title: 'Ticket Updated successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/inspector')
            } else {
              setTimeout(() => {
                this.router.navigateByUrl('/inspector')
              }, 500);
            }
          })
        } else {
          Swal.close()
          this.Toastr.error(data.message)
        }
      })
    }
    else {
      console.log(this.dataform.value);
      if (!this.selectedcolor) {
        const errorMessage = `Please complete the following fields: Color`;
        Swal.close()
        this.Toastr.error(errorMessage);

      }
      // Find the invalid fields
      const invalidFields = [];
      for (const field in this.dataform.controls) {
        if (this.dataform.controls[field].invalid) {
          invalidFields.push(field);
        }
      }

      const errorMessage = `Please complete the following fields: ${invalidFields.join(", ")}`;
      Swal.close()

      this.Toastr.error(errorMessage);

    }
  }
}
