import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'; 
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {

  reset: FormGroup = new FormGroup({});
  sendlink: FormGroup = new FormGroup({});
  loggedIn: boolean = false;
  token: any
  expired: boolean

  constructor(private _Activatedroute: ActivatedRoute, private formBuilder: FormBuilder, public Toastr: ToastrService, private authService: AuthService) { }

  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.expired = this.authService.tokenExpired(this.token)
      console.log(this.token)
    });
    if (this.token) {
      this.initreset();
    }
    if (!this.token) {
      console.error("Invalid Token!")
      this.initForm()
    }
  }

  initreset() {
    this.reset = this.formBuilder.group({
      password: new FormControl('', [Validators.required]),
    })
  }

  initForm() {
    this.sendlink = this.formBuilder.group({
      email: new FormControl('', [Validators.required]),
    })
  }

  onsubmit() {
    this.authService.reset_password(this.sendlink.value).subscribe(data => {
      console.log(data)
      this.Toastr.success("Link sent successfully")
      this.sendlink.reset()
    })
  }

  reset_password() {
    this.authService.reset_password_confirm(this.reset.value, this.token).subscribe(data => {
      console.log(data)
      this.Toastr.success("Successfully reseted new password")
      this.reset.reset()
    })
  }
}
