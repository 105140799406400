import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-register-boxed',
  styles: [],
  templateUrl: './register-boxed.component.html',
})
export class RegisterBoxedComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private authService: AppCoreService) { }

  loginform: FormGroup = new FormGroup({});
  submitted: boolean = false;
  selectedrole: any;

  roles = [
    { name: "BeeKeeper", value: { farmer: "0000" } },
    { name: "Collector", value: { collector: "1111" } },
    { name: "Shipper", value: { shipper: "2222" } },
    { name: "Quality_inspector", value: { quality_inspection: "3333" } },
    { name: "Botteling", value: { botteling: "4444" } },
    { name: "Admin", value: { admin: "9999" } },
  ];

  ngOnInit() {
    this.loginform = this.formBuilder.group({
      email: new FormControl([], [Validators.required, Validators.email]),
      username: new FormControl([], [Validators.required]),
      fullname: new FormControl([], [Validators.required]),
      password: new FormControl('', [Validators.required]),
      repeat_password: new FormControl('', [Validators.required])
    })
  }

  ismailValid() {
    return this.loginform.controls['email'].valid
  }
  isusernameValid() {
    return this.loginform.controls['username'].valid
  }
  isfullnameValid() {
    return this.loginform.controls['fullname'].valid
  }
  ispasswordValid() {
    return this.loginform.controls['password'].valid
  }
  isrepeatValid() {
    if (this.loginform.controls['repeat_password'].value !== null)
      return this.loginform.controls['repeat_password'].value === this.loginform.controls['password'].value && this.loginform.controls['repeat_password'].value.length
    else
      return false  
  }

  isroleValid() {
    return this.selectedrole?.value
  }

  onlogin() {
    this.submitted = true;

    if (this.loginform.valid && this.selectedrole?.value && this.isrepeatValid()) {
      // Normalize email to lowercase
      const email = this.loginform.value.email.toLowerCase();

      // Get other form values
      const username = this.loginform.value.username.toLowerCase();;
      const fullname = this.loginform.value.fullname;
      const password = this.loginform.value.password;

      // Create an object with the normalized email and other form values
      const data = {
        email,
        username,
        fullname,
        password,
        roles: this.selectedrole.value
      };

      this.authService.register(data).subscribe(el => {
        console.log(el);
        if (el.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'User has been created successfully saved',
            showConfirmButton: false,
            timer: 1000
          });
          setTimeout(() => {
            this.loginform.reset()
          }, 1000);
        } else {
          if (el.message.detail.includes('already exists'))
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'User already exists',
              showConfirmButton: false,
              timer: 1000
            })
        }
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  }
}
