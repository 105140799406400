<div class="container-fluid">
    <h2 style="margin-bottom:30px;font-size: large;">Enter your batch details below:</h2>
    <hr>
    <form (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="dataform" (ngSubmit)="update()">
        <!-- hmf | moister  | ph | color | electrical_cunductivity | diastate -->
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">H.M.F :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" min="" formControlName="hmf" id="name"
                            placeholder="Enter hmf here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        mg/kg
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Moisture :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="moister" id="name"
                            placeholder="Enter Moisture here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        %
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Diastase :</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="diastate" id="name"
                            placeholder="Enter Diastase here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        schade
                    </div>
                </div>

            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Acidity:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 80%;">
                        <input type="number" class="form-control" formControlName="ph" id="name"
                            placeholder="Enter acidity here..." disableMousewheel>
                    </div>
                    <div class="c-border">
                        mg/kg
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Color:</label></div>
            <div class="col-8">
                <ng-select [items]="color" bindLabel="name" placeholder="Select city" [(ngModel)]="selectedcolor"
                    [ngModelOptions]="{standalone: true}">
                </ng-select>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fg ratio:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="fg_ratio" id="name"
                    placeholder="Enter FG ratio here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Invert sugar:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="invert_sugar" id="name"
                    placeholder="Enter invert sugar here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Suctose:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="suctose" id="name"
                    placeholder="Enter Suctose here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Ash:</label></div>
            <div class="col-8">
                <input type="number" class="form-control" formControlName="ash" id="name"
                    placeholder="Enter ash here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Appearence:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="appearence" id="name"
                    placeholder="Enter appearence here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Cleanliness:</label></div>
            <div class="col-8">
                <div class="row">
                    <div style="width: 75%;">
                        <input type="text" class="form-control" formControlName="cleanliness" id="name"
                            placeholder="Enter cleanliness here..." disableMousewheel>
                    </div>
                    <div class="c-border" style="width: 25%;">
                        Free from foreign materials
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Odder:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="odder" id="name"
                    placeholder="Enter odder here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Taste:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="taste" id="name"
                    placeholder="Enter taste here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Fermentation:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="fermentation" id="name"
                    placeholder="Enter fermentation here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Lot number:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="lot_no" id="name"
                    placeholder="Enter Lot number here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Batch Number:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="batch_no" id="name"
                    placeholder="Enter Batch number here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="col-4"><label for="name">Total number of bottles:</label></div>
            <div class="col-8">
                <input type="text" class="form-control" formControlName="batch_bottles" id="name"
                    placeholder="Enter total number of bottles here..." disableMousewheel>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 1:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 100</label>
                        <textarea type="text" class="form-control" minlength="100" style="height:100px;"
                            formControlName="page_1_section_1" id="name"
                            placeholder="Enter the section 1 for page 1 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 3:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 100</label>
                        <textarea type="text" class="form-control" minlength="100" style="height:100px;"
                            formControlName="page_3_section_1" id="name"
                            placeholder="Enter the section 1 for page 3 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 5:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 100</label>
                        <textarea type="text" class="form-control" minlength="100" style="height:100px;"
                            formControlName="page_5_section_1" id="name"
                            placeholder="Enter the section 1 for page 5 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 6:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 50</label>
                        <textarea type="text" class="form-control" minlength="50" style="height:100px;"
                            formControlName="page_6_section_1" id="name"
                            placeholder="Enter the section 1 for page 6 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 9:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 50</label>
                        <textarea type="text" class="form-control" minlength="50" style="height:100px;"
                            formControlName="page_9_section_1" id="name"
                            placeholder="Enter the section 1 for page 9 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 2:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 50</label>
                        <textarea type="text" class="form-control" minlength="50" style="height:100px;"
                            formControlName="page_9_section_2" id="name"
                            placeholder="Enter the section 2 for page 9 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;"><label for="name"> <b>Page 10:</b></label>
            <div class="col-12"><br>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 1:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 50</label>
                        <textarea type="text" class="form-control" minlength="50" style="height:100px;"
                            formControlName="page_10_section_1" id="name"
                            placeholder="Enter the section 1 for page 10 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label style="margin: 20px;" for="name">Section 2:</label>
                    </div>
                    <div class="col-8">
                        <label style="margin-left:5px;">Minimum letters 100</label>
                        <textarea type="text" class="form-control" minlength="50" style="height:100px;"
                            formControlName="page_10_section_2" id="name"
                            placeholder="Enter the section 2 for page 10 here..." disableMousewheel>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="imgs">
            <br><br>
            <label for='media'>Media uploded for the ticket: (please select atleast 4 images for media to be
                shown)</label>
            <br><br>
            <div id="media" class="gallery"
                style="display: flex; width: 100%;max-height: 500px;overflow-y: scroll;flex-direction: row;">
                <div *ngFor="let m of medias">
                    <div class="checkbox-wrapper-31 absol" style="z-index: 30;" *ngIf="!m.includes('.pdf')">
                        <input type="checkbox" (change)="set_media(m)" [checked]="media_to_set.includes(m) === true" />
                        <svg viewBox="0 0 35.6 35.6">
                            <circle [ngClass]="!media_to_set.includes(m) ? 'background':'background background-check'"
                                cx="17.8" cy="17.8" r="17.8"></circle>
                            <circle [ngClass]="!media_to_set.includes(m) ? 'stroke':'stroke stroke-check'" cx="17.8"
                                cy="17.8" r="14.37"></circle>
                            <polyline [ngClass]="!media_to_set.includes(m) ? 'check':'check check-check'"
                                points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                        </svg>
                    </div>

                    <img [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+m" *ngIf="!m.includes('.pdf')"
                        alt="media"
                        style="max-width: 400px;padding-left: 20px;cursor: pointer !important;position: relative;right:30px;top:-40px;z-index: 20;">
                </div>

            </div>
        </div>
        <div class="imgs">
            <br><br>
            <label for='media'>Certificate uploaded from collector:</label>
            <br><br>
            <div id="media" class="gallery"
                style="display: flex; width: 100%;max-height: 500px;overflow-y: scroll;flex-direction: row;gap: 20px;">
                <div *ngFor="let m of current_vals.pdfs_collector">
                    <img *ngIf="!m.includes('.pdf')" [src]="m" alt="media" style="max-height: 300px;">
                    <pdf-viewer *ngIf="m.includes('pdf')" [src]="m" [rotation]="0" [original-size]="false"
                        [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                        [autoresize]="true" [show-borders]="false" style="width:500px;max-width: 400px; height: 500px;">
                    </pdf-viewer>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="imgs">
                <br><br>
                <label for='media'>Certificate uploaded from shipper:</label>
                <br><br>
                <div id="media" class="gallery"
                    style="display: flex; width: 100%;max-height: 500px;overflow-y: scroll;flex-direction: row;gap: 20px;">
                    <div *ngFor="let m of current_vals.pdfs_shipper">
                        <img *ngIf="!m.includes('.pdf')" [src]="m" alt="media" style="max-height: 300px;">
                        <pdf-viewer *ngIf="m.includes('pdf')" [src]="m" [rotation]="0" [original-size]="false"
                            [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                            [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                            [autoresize]="true" [show-borders]="false" style="width:500px;max-width: 400px; height: 500px;">
                        </pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="imgs">
                <br><br>
                <label for='media'>Certificate uploaded from inspection:</label>
                <br><br>
                <div id="media" class="gallery"
                    style="display: flex; width: 100%;max-height: 500px;overflow-y: scroll;flex-direction: row;gap: 20px;">
                    <div *ngFor="let m of current_vals.pdfs_inspector">
                        <img *ngIf="!m.includes('.pdf')" [src]="m" alt="media" style="max-height: 300px;">
                        <pdf-viewer *ngIf="m.includes('pdf')" [src]="m" [rotation]="0" [original-size]="false"
                            [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                            [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                            [autoresize]="true" [show-borders]="false" style="width:500px;max-width: 400px; height: 500px;">
                        </pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 20px;">
            <div class="imgs">
                <br><br>
                <label for='media'>Certificate uploaded from bottling:</label>
                <br><br>
                <div id="media" class="gallery"
                    style="display: flex; width: 100%;max-height: 500px;overflow-y: scroll;flex-direction: row;gap: 20px;">
                    <div *ngFor="let m of current_vals.pdfs_botteling">
                        <img *ngIf="!m.includes('.pdf')" [src]="m" alt="media" style="max-height: 300px;">
                        <pdf-viewer *ngIf="m.includes('pdf')" [src]="m" [rotation]="0" [original-size]="false"
                            [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
                            [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'"
                            [autoresize]="true" [show-borders]="false" style="width:500px;max-width: 400px; height: 500px;">
                        </pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <button type="submit" class="btn bg-3"
                style="font-size: medium; font-weight: 400; float: right;">Update</button>
        </div>
    </form>
</div>