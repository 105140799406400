import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import { getrole, setRole, setToken, setUser, setUserId } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  loginform: FormGroup = new FormGroup({});
  loggedIn: boolean = false;

  constructor(private router: Router, private formBuilder: FormBuilder, public Toastr: ToastrService, private appCore: AppCoreService, private authService: AuthService, private SharedService: SharedService) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
    if (this.loggedIn && getrole()?.includes('admin')) {
      this.router.navigate(['/'])
    } else if (this.loggedIn && getrole()?.includes('farmer')) {
      this.router.navigate(['/farmer'])
    } else if (this.loggedIn && getrole()?.includes('collector')) {
      this.router.navigate(['/collector'])
    } else if (this.loggedIn && getrole()?.includes('shipper')) {
      this.router.navigate(['/shipper'])
    } else if (this.loggedIn && getrole()?.includes('inspector')) {
      this.router.navigate(['/inspector'])
    } else if (this.loggedIn && getrole()?.includes('botteling')) {
      this.router.navigate(['/botteling'])
    }
    // collector: "1111",
    // shipper: "2222",
    // quality_inspection: "3333",
    // consumer: "4444",
    // // if (this.loggedIn && getrole()?.includes('School')) {
    // //   this.router.navigate(['schools'])
    // // }
    this.initForm()

  }

  initForm() {
    this.loginform = this.formBuilder.group({
      email: new FormControl([], [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

  onlogin() {
    if (this.loginform.valid) {
      // Normalize email to lowercase
      const email = this.loginform.value.email.toLowerCase();

      // Get the password as is
      const password = this.loginform.value.password;

      // Create an object with the normalized email and password
      const normalizedData = { email, password };
      this.authService.authenticate(normalizedData).subscribe(data => {
        this.authService.refreshtoken().subscribe(token => {

          const val = this.SharedService.decodeToken(token)

          const roles = val.UserInfo.roles

          const parsedroles = this.authService.identifyRole(roles)

          setToken(token)
          setUser(val.UserInfo.username)
          setRole(parsedroles)
          setUserId(val.UserInfo.userId)

          if (parsedroles.includes('farmer')) {
            this.router.navigate(['/farmer'])
          }
          else if (parsedroles.includes('admin')) {
            this.router.navigate(['/'])
          } else if (parsedroles.includes('collector')) {
            this.router.navigate(['/collector'])
          } else if (parsedroles.includes('shipper')) {
            this.router.navigate(['/shipper'])
          } else if (parsedroles.includes('inspector')) {
            this.router.navigate(['/inspector'])
          } else if (parsedroles.includes('botteling')) {
            this.router.navigate(['/botteling'])
          }
        })
      })
    }
    else {
      this.Toastr.error('Please enter fields properly')
    }
  }

}
