<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div class="content">
        <div>
            <h2
                style="text-align: center; margin-top: 15px;font-size: 20px;font-weight: 600;padding: 10px 20px; color: #000;">
                Explore where this special type of honey comes from and trace it back to its origin.</h2>
        </div>
        <div class="content">
            <div class="title">
                - Our Purpose -
            </div>

            <div class="param">
                <span *ngIf="ticket.page_1_section_1">{{ticket.page_1_section_1}}</span>
                <span *ngIf="!ticket.page_1_section_1">Encourage all generations to enjoy the benefits of honey while
                    taking care of the well-being of
                    the planet starting with the bee’s ecosystem</span>
            </div>
        </div>
        <div class="content gap-2">
            <button class="btn btn-success btn-custom btn-custom-1" (click)="slide()">
                <div style="width: 30%;"><fa-icon [icon]="marker"></fa-icon></div>
                <div style="width: 70%;">Trace the Origin</div>
            </button>
            <a href="https://www.alshifahoney.com/en/" style="text-decoration: none;color: #fff;" target="_blank"
                class="btn btn-success btn-custom btn-custom-2">
                <div style="width: 30%;"><fa-icon [icon]="cart"></fa-icon></div>
                <div style="width: 70%;">Shop Online</div>
            </a>
        </div>

    </div>
</div>