import { Component, OnInit } from '@angular/core';
import { faAngleDown, faAngleUp, faCalendarAlt, faCheck, faTh, faTrash, faUserAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main-controller',
  templateUrl: './main-controller.component.html',
  styleUrls: ['./main-controller.component.sass']
})
export class MainControllerComponent implements OnInit {
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faTh = faUserAlt;
  faCheck = faCheck;
  faTrash = faTrash;
  faCalendarAlt = faCalendarAlt;
  constructor() { }

  ngOnInit(): void {
  }

}
