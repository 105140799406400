import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { getUserId, get_ticket, remove_ticket } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-ticket',
  templateUrl: './update-ticket.component.html',
  styleUrls: ['./update-ticket.component.css']
})
export class UpdateTicketComponent implements OnInit {

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private router: Router, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }

  @ViewChild('search') searchElementRef: ElementRef;

  current_vals: any;
  dataform: FormGroup = new FormGroup({});
  // google maps zoom level
  zoom = 8;
  medias: string[]
  //set latitude, longitude and zoom
  latitude: number
  longitude: number
  id = getUserId()
  // initial center position for the map
  lat: any;
  lng: any;
  disable: boolean = true
  markers: Marker[] = [];
  color = [
    { name: "Water White", value: "Water White" },
    { name: "Extra White", value: "Extra White" },
    { name: "White", value: "White" },
    { name: "Extra Light Amber", value: "Extra Light Amber" },
    { name: "Light Amber", value: "Light Amber" },
    { name: "Amber", value: "Amber" },
    { name: "Dark Amber", value: "Dark Amber" }
  ];

  selectedcolor: any;
  ngAfterViewInit(): void {
    this.findAdress()
  }
  ngOnDestroy(): void {
    remove_ticket()
  }
  ngOnInit(): void {
    if (!get_ticket()) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'An error Occurred! please try again later',
        showConfirmButton: false,
        timer: 1000
      }).then(result => {
        this.router.navigateByUrl('/')
      })
    } else {
      this.current_vals = JSON.parse(get_ticket())
      this.selectedcolor = this.current_vals.color
      console.log(this.current_vals)
      this.latitude = parseFloat(this.current_vals.collector_lat)
      this.longitude = parseFloat(this.current_vals.collector_lng)
      this.appCoreService.get_media(this.current_vals.ticket_id).subscribe(data => {
        this.medias = data
      })
      if (this.current_vals.status === 'collecting' || this.current_vals.status === '111') {
        this.disable = false
      }
      this.initForm()
      this.markers = [{
        lat: this.latitude,
        lng: this.longitude,
        label: 'A',
        draggable: true,
      }]
    }
  }


  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {

  }

  markerDragEnd(m: Marker, event: any) {
    console.log('dragEnd', m, event);
    this.latitude = event.coords.lat
    this.longitude = event.coords.lng
  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.lat = this.latitude
          this.lng = this.longitude
          this.zoom = 12;
          this.markers = [{
            lat: this.latitude,
            lng: this.longitude,
            label: 'A',
            draggable: true,
          }]
        });
      });
    });
  }

  initForm() {
    if (this.current_vals.appearence === '' || this.current_vals.appearence === null) {
      this.current_vals.appearence = ''
    }
    this.dataform = this.formBuilder.group({
      hmf: new FormControl(this.current_vals.hmf, [Validators.required]),
      moister: new FormControl(this.current_vals.moister, [Validators.required]),
      ph: new FormControl(this.current_vals.ph, [Validators.required]),
      diastate: new FormControl(this.current_vals.diastate, [Validators.required]),
      fg_ratio: new FormControl(this.current_vals.fg_ratio, [Validators.required]),
      invert_sugar: new FormControl(this.current_vals.invert_sugar, [Validators.required]),
      suctose: new FormControl(this.current_vals.suctose, [Validators.required]),
      ash: new FormControl(this.current_vals.ash, [Validators.required]),
      appearence: new FormControl(this.current_vals.appearence, [Validators.required]),  // Replace with appropriate value or variable
      cleanliness: new FormControl(this.current_vals.cleanliness, [Validators.required]),  // Replace with appropriate value or variable
      odder: new FormControl(this.current_vals.odder, [Validators.required]),  // Replace with appropriate value or variable
      taste: new FormControl(this.current_vals.taste, [Validators.required]),  // Replace with appropriate value or variable
      fermentation: new FormControl(this.current_vals.fermentation, [Validators.required]),  // Replace with appropriate value or variable
      lot_no: new FormControl(this.current_vals.lot_no, [Validators.required])  // Replace with appropriate value or variable
    });
  }
  update() {
    Swal.showLoading()
    console.log(this.dataform, "ValidATION")
    console.log({ ticket_id: this.current_vals.ticket_id, "updates": this.dataform.value })
    if (this.dataform.valid && this.selectedcolor && this.latitude && this.longitude) {

      this.appCoreService.update_ticket({ ticket_id: this.current_vals.ticket_id, "updates": { ...this.dataform.value, ...{ collector_lat: this.latitude, collector_lng: this.longitude, color: this.selectedcolor.name } } }).subscribe(data => {
        Swal.close()
        if (data.status === 200) {
          Swal.fire({
            title: 'Ticket Updated successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/farmer')
            } else {
              setTimeout(() => {
                this.router.navigateByUrl('/farmer')
              }, 500);
            }
          })
        } else {
          Swal.close()
          this.Toastr.error(data.message)
        }
      })
    }
    else {

      console.log(this.dataform.value);
      if (!this.selectedcolor) {
        const errorMessage = `Please complete the following fields: Color`;
        Swal.close()
        this.Toastr.error(errorMessage);

      } if (!this.latitude || !this.longitude) {
        const errorMessage = `Please enter a valid location`;
        Swal.close()
        this.Toastr.error(errorMessage);

      }
      // Find the invalid fields
      const invalidFields = [];
      for (const field in this.dataform.controls) {
        if (this.dataform.controls[field].invalid) {
          invalidFields.push(field);
        }
      }

      const errorMessage = `Please complete the following fields: ${invalidFields.join(", ")}`;
      Swal.close()

      this.Toastr.error(errorMessage);

    }
  }

}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
