import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.css']
})
export class PageTwoComponent implements OnInit {

  @Output() curr_slider = new EventEmitter<number>();
  @Output() moveable = new EventEmitter<boolean>();
  @ViewChild('map') gmap!: any;
  @Input() current_ticket: any;
  //quality inspection 
  lat = 23.8859;
  lng = 45.0792;
  //collection center
  latb = 7.984034673299722;
  lngb = -81.56650135928363;
  //honey origin
  latA = 19.239461925594316;
  lngA = -90.8829076309421;
  zoom = 2
  map: any;
  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['current_ticket'].currentValue) {
      console.log(this.current_ticket, "ticket")
      this.latA = parseFloat(this.current_ticket.farmer_lat)
      this.lngA = parseFloat(this.current_ticket.farmer_lng)

      this.latb = parseFloat(this.current_ticket.collector_lat)
      this.lngb = parseFloat(this.current_ticket.collector_lng)

      this.lat = parseFloat(this.current_ticket.inspection_lat);
      this.lng = parseFloat(this.current_ticket.inspection_lng);
    }
  }

  slide(num: number) {
    this.curr_slider.emit(num);
  }
  mapReady(map) {
    this.map = map;
    if (map)
      map.setOptions({
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              { saturation: -100 },
              { lightness: 30 }
            ]
          }
          // Add more style rules as needed
        ]
      });
    var that = this;
    google.maps.event.addListener(map, "dragstart", function (event) {
      //do what you want
      that.moveable.emit(true);
    });
    that.map.addListener("dragend", function ($event) {
      //do what you want
      that.moveable.emit(false);
    });
  }

}
