<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">
            Honey Batch
            Origin</h2>

        <div class="img_gallery">
            <div class="flexy-col">
                <div *ngFor="let media of result">
                    <img class="gall" [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media" alt="pic1">
                </div>
            </div>
        </div>
    </div>