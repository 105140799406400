<style>@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
    @import url(https://fonts.googleapis.com/css?family=Karla:400,700);
    html {
        position: relative;
        min-height: 100%;
   }
    body {
        padding-bottom: 60px;
        overflow-x: hidden;
   }
    .metismenu {
        padding: 0;
   }
    .metismenu li {
        list-style: none;
   }
    .metismenu ul {
        padding: 0;
   }
    .metismenu ul li {
        width: 100%;
   }
    .metismenu .mm-collapse:not(.mm-show) {
        display: none;
   }
    .metismenu .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        -webkit-transition-timing-function: ease;
        transition-timing-function: ease;
        -webkit-transition-duration: 0.35s;
        transition-duration: 0.35s;
        -webkit-transition-property: height, visibility;
        transition-property: height, visibility;
   }
    .nav-second-level li a, .nav-thrid-level li a {
        padding: 8px 20px;
        color: #6c757d;
        display: block;
        position: relative;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
   }
    .nav-second-level li a:focus, .nav-second-level li a:hover, .nav-thrid-level li a:focus, .nav-thrid-level li a:hover {
        color: #71b6f9;
   }
    .nav-second-level li.mm-active > a, .nav-third-level li.mm-active > a {
        color: #71b6f9;
   }
    #wrapper {
        height: 100%;
        overflow: hidden;
        width: 100%;
   }
    .content-page {
        margin-left: 240px;
        overflow: hidden;
        padding: 0 12px 2px 12px;
        min-height: 80vh;
        margin-top: 70px;
   }
    .left-side-menu {
        width: 240px;
        background: #fff;
        bottom: 0;
        padding: 20px 0;
        position: fixed;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        top: 70px;
        -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        z-index: 99;
   }
    .left-side-menu .user-box .img-thumbnail {
        background-color: #fff;
        border-color: #f5f5f5;
   }
    #sidebar-menu > ul > li > a {
        color: #6c757d;
        display: block;
        padding: 11px 20px;
        position: relative;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        font-size: 0.925rem;
   }
    #sidebar-menu > ul > li > a:active, #sidebar-menu > ul > li > a:focus, #sidebar-menu > ul > li > a:hover {
        color: #71b6f9;
        text-decoration: none;
   }
    #sidebar-menu > ul > li > a > span {
        vertical-align: middle;
   }
    #sidebar-menu > ul > li > a i {
        display: inline-block;
        line-height: 1.0625rem;
        margin: 0 10px 0 3px;
        text-align: center;
        vertical-align: middle;
        width: 20px;
   }
    #sidebar-menu > ul > li > a .drop-arrow {
        float: right;
   }
    #sidebar-menu > ul > li > a .drop-arrow i {
        margin-right: 0;
   }
    #sidebar-menu > ul > li > a.active {
        color: #71b6f9;
   }
    #sidebar-menu > ul > li > ul {
        padding-left: 37px;
   }
    #sidebar-menu > ul > li > ul ul {
        padding-left: 20px;
   }
    #sidebar-menu .menu-arrow {
        -webkit-transition: -webkit-transform 0.15s;
        transition: -webkit-transform 0.15s;
        transition: transform 0.15s;
        transition: transform 0.15s, -webkit-transform 0.15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
   }
    #sidebar-menu .menu-arrow:before {
        content: "\F0142";
   }
    #sidebar-menu .badge {
        margin-top: 4px;
   }
    #sidebar-menu li.mm-active > a > span.menu-arrow {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
   }
    #sidebar-menu .menu-title {
        padding: 10px 20px;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: #adb5bd;
        font-weight: 500;
   }
    .enlarged .logo-box {
        width: 70px !important;
   }
    .enlarged .logo span.logo-lg {
        display: none;
   }
    .enlarged .logo span.logo-sm {
        display: block;
   }
    .enlarged .left-side-menu {
        position: absolute;
        padding-top: 0;
        width: 70px !important;
        z-index: 9;
   }
    .enlarged .left-side-menu .slimScrollDiv, .enlarged .left-side-menu .slimscroll-menu {
        overflow: inherit !important;
        height: auto !important;
   }
    .enlarged .left-side-menu .slimScrollBar {
        visibility: hidden;
   }
    .enlarged .left-side-menu #sidebar-menu .badge, .enlarged .left-side-menu #sidebar-menu .label, .enlarged .left-side-menu #sidebar-menu .menu-arrow, .enlarged .left-side-menu #sidebar-menu .menu-title {
        display: none !important;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li {
        position: relative;
        white-space: nowrap;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li > a {
        padding: 15px 20px;
        min-height: 54px;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li > a:active, .enlarged .left-side-menu #sidebar-menu > ul > li > a:focus, .enlarged .left-side-menu #sidebar-menu > ul > li > a:hover {
        color: #71b6f9;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li > a i {
        font-size: 1.125rem;
        margin-right: 20px;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li > a span {
        display: none;
        padding-left: 25px;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > a {
        position: relative;
        width: calc(190px + 70px);
        color: #71b6f9;
        background-color: #fafafa;
        -webkit-transition: none;
        transition: none;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > a span {
        display: inline;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover a.mm-active :after, .enlarged .left-side-menu #sidebar-menu > ul > li:hover a.open :after {
        display: none;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > ul {
        display: block;
        left: 70px;
        position: absolute;
        width: 190px;
        height: auto !important;
        -webkit-box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);
        box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > ul ul {
        -webkit-box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);
        box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, .2);
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > ul a {
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 8px 20px;
        position: relative;
        width: 190px;
        z-index: 6;
   }
    .enlarged .left-side-menu #sidebar-menu > ul > li:hover > ul a:hover {
        color: #71b6f9;
   }
    .enlarged .left-side-menu #sidebar-menu > ul ul {
        padding: 5px 0;
        z-index: 9999;
        display: none;
        background-color: #fff;
   }
    .enlarged .left-side-menu #sidebar-menu > ul ul li:hover > ul {
        display: block;
        left: 190px;
        margin-top: -36px;
        position: absolute;
        width: 190px;
   }
    .enlarged .left-side-menu #sidebar-menu > ul ul li > a span.pull-right {
        position: absolute;
        right: 20px;
        top: 12px;
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
   }
    .enlarged .left-side-menu #sidebar-menu > ul ul li.active a {
        color: #71b6f9;
   }
    .enlarged .content-page {
        margin-left: 70px !important;
   }
    .enlarged .footer {
        left: 70px !important;
   }
    .enlarged .user-box {
        display: none;
   }
    body.enlarged {
        min-height: 1200px;
   }
    @media (max-width: 767.98px) {
        body {
            overflow-x: hidden;
            padding-bottom: 80px;
       }
        .left-side-menu {
            display: none;
            z-index: 10 !important;
       }
        .sidebar-enable .left-side-menu {
            display: block;
       }
        .content-page, .enlarged .content-page {
            margin-left: 0 !important;
            padding: 0 10px;
       }
        .pro-user-name {
            display: none;
       }
        .logo-box {
            display: none;
       }
   }
    body[data-sidebar-size=small] .logo-box {
        width: 160px;
   }
    body[data-sidebar-size=small] .left-side-menu {
        width: 160px;
        text-align: center;
   }
    body[data-sidebar-size=small] .left-side-menu #sidebar-menu > ul > li > a > i {
        display: block;
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        margin: 0;
   }
    body[data-sidebar-size=small] .left-side-menu #sidebar-menu > ul ul {
        padding-left: 0;
   }
    body[data-sidebar-size=small] .left-side-menu #sidebar-menu > ul ul a {
        padding: 10px 20px;
   }
    body[data-sidebar-size=small] .left-side-menu .badge, body[data-sidebar-size=small] .left-side-menu .menu-arrow {
        display: none !important;
   }
    body[data-sidebar-size=small] .left-side-menu + .content-page {
        margin-left: 160px;
   }
    body[data-sidebar-size=small] .left-side-menu + .content-page .footer {
        left: 160px;
   }
    body[data-sidebar-size=small] .left-side-menu .menu-title {
        background-color: #f5f5f5;
   }
    body[data-sidebar-size=small].enlarged #wrapper .left-side-menu {
        text-align: left;
   }
    body[data-sidebar-size=small].enlarged #wrapper .left-side-menu ul li a i {
        display: inline-block;
        font-size: 18px;
        line-height: 17px;
        margin-left: 3px;
        margin-right: 15px;
        vertical-align: middle;
        width: 20px;
   }
    body[data-sidebar=dark] .logo-box {
        background-color: #38414a;
   }
    body[data-sidebar=dark] .logo.logo-dark {
        display: none;
   }
    body[data-sidebar=dark] .logo.logo-light {
        display: block;
   }
    body[data-sidebar=dark] .user-box .user-name {
        color: #f7f7f7;
   }
    body[data-sidebar=dark] .user-box .img-thumbnail {
        background-color: #ebeff2;
        border-color: #dee2e6;
   }
    body[data-sidebar=dark] .left-side-menu {
        background-color: #38414a;
        -webkit-box-shadow: none;
        box-shadow: none;
   }
    body[data-sidebar=dark] .left-side-menu .text-dark {
        color: #dee2e6 !important;
   }
    body[data-sidebar=dark] .left-side-menu #sidebar-menu > ul > li > a {
        color: #9097a7;
   }
    body[data-sidebar=dark] .left-side-menu #sidebar-menu > ul > li > a:active, body[data-sidebar=dark] .left-side-menu #sidebar-menu > ul > li > a:focus, body[data-sidebar=dark] .left-side-menu #sidebar-menu > ul > li > a:hover {
        color: #c8cddc;
   }
    body[data-sidebar=dark] .left-side-menu #sidebar-menu > ul > li > a.active {
        color: #fff;
        background-color: #3d4751;
        border-right-color: #fff;
   }
    body[data-sidebar=dark] .left-side-menu #sidebar-menu .menu-title {
        color: #adb5bd;
   }
    body[data-sidebar=dark] .left-side-menu .nav-second-level li a, body[data-sidebar=dark] .left-side-menu .nav-thrid-level li a {
        color: #9097a7;
   }
    body[data-sidebar=dark] .left-side-menu .nav-second-level li a:focus, body[data-sidebar=dark] .left-side-menu .nav-second-level li a:hover, body[data-sidebar=dark] .left-side-menu .nav-thrid-level li a:focus, body[data-sidebar=dark] .left-side-menu .nav-thrid-level li a:hover {
        background-color: transparent;
        color: #c8cddc;
   }
    body[data-sidebar=dark] .left-side-menu .nav-second-level li.mm-active > a, body[data-sidebar=dark] .left-side-menu .nav-third-level li.mm-active > a {
        color: #fff;
   }
    body[data-sidebar=dark].enlarged #wrapper .left-side-menu #sidebar-menu > ul > li:hover > a {
        background-color: #3d4751;
        color: #fff;
   }
    body[data-sidebar=dark].enlarged #wrapper .left-side-menu .nav-second-level li.mm-active > a, body[data-sidebar=dark].enlarged #wrapper .left-side-menu .nav-third-level li.mm-active > a {
        color: #71b6f9;
   }
    .user-box .user-name {
        color: #343a40;
   }
    .user-pro-dropdown {
        background-color: #f8f9fa;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 15px 5px;
        width: 90%;
        margin-left: 5%;
        margin-top: 10px;
   }
    .user-pro-dropdown .dropdown-item {
        border-radius: 3px;
   }
    .user-pro-dropdown .dropdown-item i {
        display: inline-block;
   }
    .user-pro-dropdown .dropdown-item:hover {
        background-color: #71b6f9;
        color: #fff;
   }
    .logo {
        display: block;
        line-height: 70px;
   }
    .logo span.logo-lg {
        display: block;
   }
    .logo span.logo-sm {
        display: none;
   }
    .logo.logo-dark {
        display: block;
   }
    .logo.logo-light {
        display: none;
   }
    .logo-box {
        height: 70px;
        width: 240px;
        float: left;
        background-color: #fff;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
   }
    .navbar-custom {
        background-color: #ebeff2;
        padding: 0 calc(24px / 2) 0 0;
        position: fixed;
        left: 0;
        right: 0;
        height: 70px;
        z-index: 100;
   }
    .navbar-custom .topnav-menu.topnav-menu-left {
        margin-left: 6px;
        display: inline-block;
   }
    .navbar-custom .topnav-menu > li {
        float: left;
   }
    .navbar-custom .topnav-menu .nav-link {
        padding: 0 15px;
        color: #6c757d;
        min-width: 32px;
        display: block;
        line-height: 70px;
        text-align: center;
        max-height: 70px;
   }
    .navbar-custom .dropdown.show .nav-link {
        background-color: rgba(108, 117, 125, .05);
   }
    .navbar-custom .app-search {
        overflow: hidden;
        height: 70px;
        display: table;
        max-width: 180px;
        margin-right: 20px;
   }
    .navbar-custom .app-search .app-search-box {
        display: table-cell;
        vertical-align: middle;
   }
    .navbar-custom .app-search .app-search-box input::-webkit-input-placeholder {
        font-size: 0.8125rem;
        color: #98a6ad;
   }
    .navbar-custom .app-search .form-control {
        border: none;
        height: 38px;
        padding-left: 20px;
        padding-right: 0;
        color: #323a46;
        background-color: #e2e6ea;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 30px 0 0 30px;
   }
    .navbar-custom .app-search .input-group-append {
        margin-left: 0;
        z-index: 4;
   }
    .navbar-custom .app-search .btn {
        background-color: #e2e6ea;
        border-color: transparent;
        color: #6c757d;
        border-radius: 0 30px 30px 0;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
   }
    .navbar-custom .button-menu-mobile {
        border: none;
        color: #323a46;
        display: inline-block;
        height: 70px;
        line-height: 70px;
        width: 60px;
        background-color: transparent;
        font-size: 24px;
        cursor: pointer;
   }
    .navbar-custom .button-menu-mobile.disable-btn {
        display: none;
   }
    .noti-scroll {
        max-height: 230px;
   }
    .notification-list {
        margin-left: 0;
   }
    .notification-list .noti-title {
        background-color: transparent;
        padding: 15px 20px;
   }
    .notification-list .noti-icon {
        font-size: 21px;
        vertical-align: middle;
   }
    .notification-list .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 10px;
   }
    .notification-list .notify-item {
        padding: 12px 20px;
   }
    .notification-list .notify-item .notify-icon {
        float: left;
        height: 36px;
        width: 36px;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        margin-right: 10px;
        border-radius: 50%;
        color: #fff;
   }
    .notification-list .notify-item .notify-details {
        margin-bottom: 5px;
        overflow: hidden;
        margin-left: 45px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #343a40;
   }
    .notification-list .notify-item .notify-details b {
        font-weight: 600;
   }
    .notification-list .notify-item .notify-details small {
        display: block;
   }
    .notification-list .notify-item .notify-details span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
   }
    .notification-list .notify-item .user-msg {
        margin-left: 45px;
        white-space: normal;
        line-height: 16px;
   }
    .notification-list .profile-dropdown .notify-item {
        padding: 7px 20px;
   }
    .profile-dropdown {
        width: 170px;
   }
    .profile-dropdown i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
   }
    .nav-user {
        padding: 0 12px !important;
   }
    .nav-user img {
        height: 32px;
        width: 32px;
   }
    .page-title-main {
        margin: 0;
        line-height: 70px;
        padding: 0 20px;
   }
    @media (max-width: 768px) {
        .button-menu-mobile.disable-btn {
            display: block !important;
       }
        .page-title-main {
            display: none;
       }
   }
    body[data-topbar=dark] .logo-box {
        background-color: #38414a;
   }
    body[data-topbar=dark] .logo.logo-dark {
        display: none !important;
   }
    body[data-topbar=dark] .logo.logo-light {
        display: block !important;
   }
    body[data-topbar=dark] .navbar-custom {
        background-color: #38414a;
   }
    body[data-topbar=dark] .navbar-custom .topnav-menu .nav-link {
        color: #dee2e6;
   }
    body[data-topbar=dark] .navbar-custom .dropdown.show .nav-link {
        background-color: rgba(255, 255, 255, .03);
   }
    body[data-topbar=dark] .navbar-custom .button-menu-mobile {
        color: #fff;
   }
    body[data-topbar=dark] .navbar-custom .page-title-main {
        color: #dee2e6;
   }
    body[data-topbar=dark] .navbar-custom .waves-effect .waves-ripple {
        background: rgba(255, 255, 255, .4);
   }
    body[data-topbar=dark] .navbar-custom .app-search input::-webkit-input-placeholder {
        color: rgba(255, 255, 255, .7) !important;
   }
    body[data-topbar=dark] .navbar-custom .app-search .form-control {
        color: #fff;
        background-color: #414b56;
        border-color: #414b56;
   }
    body[data-topbar=dark] .navbar-custom .app-search .btn {
        background-color: #414b56;
        color: rgba(255, 255, 255, .7);
   }
    body[data-topbar=dark] .content-page {
        margin-top: calc(70px + 24px);
   }
    .footer {
        bottom: 0;
        padding: 19px 15px 20px;
        position: absolute;
        right: 0;
        color: #6c757d;
        left: 240px;
        background-color: #e3e9ed;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
   }
    .footer .footer-links a {
        color: #6c757d;
        margin-left: 1.5rem;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
   }
    .footer .footer-links a:hover {
        color: #636b72;
   }
    .footer .footer-links a:first-of-type {
        margin-left: 0;
   }
    .footer-alt {
        left: 0 !important;
        text-align: center;
        background-color: transparent;
   }
    @media (max-width: 767.98px) {
        .footer {
            left: 0 !important;
            text-align: center;
       }
   }
    .right-bar {
        background-color: #fff;
        -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        display: block;
        position: fixed;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        width: 260px;
        z-index: 9999;
        float: right !important;
        right: -270px;
        top: 0;
        bottom: 0;
   }
    .right-bar .rightbar-title {
        background-color: #71b6f9;
        padding: 25.5px 25px;
        color: #fff;
   }
    .right-bar .right-bar-toggle {
        background-color: #414b5b;
        height: 24px;
        width: 24px;
        line-height: 24px;
        color: #f7f7f7;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;
   }
    .right-bar .right-bar-toggle:hover {
        background-color: #475364;
   }
    .right-bar .user-box {
        padding: 25px;
        text-align: center;
   }
    .right-bar .user-box .user-img {
        position: relative;
        height: 64px;
        width: 64px;
        margin: 0 auto 15px auto;
   }
    .right-bar .user-box .user-img .user-edit {
        position: absolute;
        right: -5px;
        bottom: 0;
        height: 24px;
        width: 24px;
        background-color: #fff;
        line-height: 24px;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 0 45px 0 rgba(0, 0, 0, .12);
   }
    .right-bar .user-box h5 {
        margin-bottom: 2px;
   }
    .right-bar .user-box h5 a {
        color: #323a46;
   }
    .rightbar-content {
        padding-bottom: 70px;
   }
    .rightbar-overlay {
        background-color: rgba(50, 58, 70, .55);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 9998;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
   }
    .right-bar-enabled .right-bar {
        right: 0;
   }
    .right-bar-enabled .rightbar-overlay {
        display: block;
   }
    @media (max-width: 767.98px) {
        .right-bar {
            overflow: auto;
       }
        .right-bar .slimscroll-menu {
            height: auto !important;
       }
   }
    @media (min-width: 1200px) {
        body[data-layout=horizontal] .container-fluid {
            max-width: 90%;
       }
   }
    body[data-layout=horizontal] .content-page {
        margin-left: 0;
        margin-top: 0;
        padding: 130px 12px 0 12px;
   }
    @media (max-width: 991.98px) {
        body[data-layout=horizontal] .content-page {
            padding-top: 70px;
       }
   }
    body[data-layout=horizontal] .footer {
        left: 0;
   }
    body[data-layout=horizontal] .logo-box {
        background-color: transparent;
        width: auto;
        padding-right: 40px;
   }
    @media (max-width: 767.98px) {
        body[data-layout=horizontal] .logo-box {
            display: block;
       }
   }
    body[data-layout=horizontal] .logo.logo-dark {
        display: block;
   }
    body[data-layout=horizontal] .logo.logo-light {
        display: none;
   }
    @media (max-width: 767.98px) {
        body[data-layout=horizontal] .logo .logo-lg {
            display: none;
       }
        body[data-layout=horizontal] .logo .logo-sm {
            display: block;
       }
   }
    body[data-layout=horizontal] .navbar-custom {
        padding: 0;
   }
    @media (min-width: 992px) {
        body[data-layout=horizontal] .navbar-custom {
            padding: 0 calc(24px / 2);
       }
   }
    body[data-layout=horizontal].enlarged .content-page {
        margin-left: 0 !important;
   }
    body[data-layout=horizontal].enlarged .footer {
        left: 0 !important;
   }
    body[data-layout=horizontal] .page-title-box .page-title {
        line-height: 75px;
        margin: 0;
   }
    @media (max-width: 575.98px) {
        body[data-layout=horizontal] .page-title-box .page-title {
            line-height: 70px;
       }
        body[data-layout=horizontal] .page-title-box .page-title-right {
            display: none;
       }
   }
    #topnav {
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1001;
        padding: 0 12px;
        -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
   }
    #topnav .arrow-down {
        display: inline-block;
   }
    #topnav .arrow-down:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: 0.4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 5px;
        -webkit-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%);
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        width: 0.4em;
   }
    #topnav .navbar-toggle {
        border: 0;
        position: relative;
        padding: 0;
        margin: 0;
        cursor: pointer;
   }
    #topnav .navbar-toggle .lines {
        width: 25px;
        display: block;
        position: relative;
        margin: 15px 20px 0 20px;
        padding-top: 13px;
        height: 44px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
   }
    #topnav .navbar-toggle span {
        height: 2px;
        width: 100%;
        background-color: #adb5bd;
        display: block;
        margin-bottom: 5px;
        transition: -webkit-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
   }
    #topnav .navbar-toggle.open span {
        position: absolute;
   }
    #topnav .navbar-toggle.open span:first-child {
        top: 18px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
   }
    #topnav .navbar-toggle.open span:nth-child(2) {
        visibility: hidden;
   }
    #topnav .navbar-toggle.open span:last-child {
        width: 100%;
        top: 18px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
   }
    #topnav .has-submenu.active > a {
        color: #71b6f9;
   }
    #topnav .has-submenu.active .submenu li.active > a {
        color: #71b6f9;
   }
    #topnav .topbar-menu {
        margin-top: 70px;
   }
    .navigation-menu {
        list-style: none;
        margin: 0;
        padding: 0;
   }
    .navigation-menu > li {
        float: left;
        position: relative;
   }
    .navigation-menu > li > a {
        display: block;
        color: #6c757d;
        font-size: 15px;
        font-family: Karla, sans-serif;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        line-height: 20px;
        padding: 20px;
        position: relative;
   }
    .navigation-menu > li > a i {
        font-size: 15px;
        margin-right: 8px;
   }
    .navigation-menu > li > a:focus, .navigation-menu > li > a:hover {
        background-color: transparent;
   }
    @media (min-width: 992px) {
        .navigation-menu > li > a:active, .navigation-menu > li > a:focus, .navigation-menu > li > a:hover {
            color: #71b6f9;
       }
        .navigation-menu > li:hover a {
            color: #71b6f9;
       }
        .navigation-menu > li:first-of-type > a {
            padding-left: 0;
       }
        .navigation-menu > li.last-elements .submenu {
            left: auto;
            right: 0;
       }
        .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: 10px;
       }
        .navigation-menu > li > ul > li.has-submenu:active > a, .navigation-menu > li > ul > li.has-submenu:hover > a {
            color: #71b6f9;
       }
        .navigation-menu > li .submenu {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            padding: 10px 0;
            list-style: none;
            min-width: 200px;
            text-align: left;
            visibility: hidden;
            opacity: 0;
            margin-top: 10px;
            border-radius: 4px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            background-color: #fff;
            -webkit-box-shadow: rgba(0, 0, 0, .13) 0 0 40px 0;
            box-shadow: rgba(0, 0, 0, .13) 0 0 40px 0;
       }
        .navigation-menu > li .submenu:before {
            left: 16px;
            top: -10px;
            content: "";
            display: block;
            position: absolute;
            background-color: transparent;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #fff;
            z-index: 9999;
       }
        .navigation-menu > li .submenu li {
            position: relative;
       }
        .navigation-menu > li .submenu li ul {
            list-style: none;
            padding-left: 0;
            margin: 0;
       }
        .navigation-menu > li .submenu li a {
            display: block;
            padding: 9px 25px;
            clear: both;
            white-space: nowrap;
            color: #6c757d;
       }
        .navigation-menu > li .submenu li a:hover {
            color: #71b6f9;
       }
        .navigation-menu > li .submenu li.has-submenu > a .arrow-down:after {
            right: 20px !important;
            -webkit-transform: rotate(-135deg) translateY(-50%) !important;
            transform: rotate(-135deg) translateY(-50%) !important;
            position: absolute;
       }
        .navigation-menu > li .submenu li .submenu {
            left: 100%;
            top: 0;
            margin-left: 20px;
            margin-top: -1px;
       }
        .navigation-menu > li .submenu li .submenu:before {
            left: -20px;
            top: 10px;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 12px solid #fff;
       }
        .navigation-menu > li .submenu.megamenu {
            white-space: nowrap;
            width: auto;
       }
        .navigation-menu > li .submenu.megamenu > li {
            overflow: hidden;
            width: 200px;
            display: inline-block;
            vertical-align: top;
       }
        .navbar-toggle {
            display: none !important;
       }
        #navigation {
            display: block !important;
       }
   }
    @media (min-width: 768px) {
        .navigation-menu > li.has-submenu:hover > .submenu {
            visibility: visible;
            opacity: 1;
            margin-top: 0;
       }
        .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
            visibility: visible;
            opacity: 1;
            margin-left: 10px;
            margin-right: 0;
       }
        .navigation-menu > li.has-submenu.last-elements > .submenu:before {
            right: 11px;
            left: auto;
       }
   }
    @media (max-width: 991px) {
        #topnav .navigation-menu {
            float: none;
            max-height: 400px;
            text-align: left;
       }
        #topnav .navigation-menu > li {
            display: block;
            float: none;
       }
        #topnav .navigation-menu > li > a {
            padding: 15px;
       }
        #topnav .navigation-menu > li > a i {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 0;
            vertical-align: inherit;
       }
        #topnav .navigation-menu > li > a:after {
            position: absolute;
            right: 15px;
       }
        #topnav .navigation-menu > li .submenu {
            display: none;
            list-style: none;
            padding-left: 20px;
            margin: 0;
       }
        #topnav .navigation-menu > li .submenu li a {
            display: block;
            position: relative;
            padding: 7px 20px;
            color: #6c757d;
       }
        #topnav .navigation-menu > li .submenu li a:hover {
            color: #71b6f9;
       }
        #topnav .navigation-menu > li .submenu.open {
            display: block;
       }
        #topnav .navigation-menu > li .submenu .submenu {
            display: none;
            list-style: none;
       }
        #topnav .navigation-menu > li .submenu .submenu.open {
            display: block;
       }
        #topnav .navigation-menu > li .submenu.megamenu > li > ul {
            list-style: none;
            padding-left: 0;
       }
        #topnav .navigation-menu > li.has-submenu.open > a {
            color: #71b6f9;
       }
        #topnav .has-submenu.active a {
            color: #71b6f9;
       }
        #topnav .has-submenu.active .submenu li.active > a {
            color: #71b6f9;
       }
        #topnav .arrow-down:after {
            right: 15px;
            position: absolute;
       }
        #topnav .navbar-header {
            float: left;
       }
        #navigation {
            position: absolute;
            top: 70px;
            left: 0;
            width: 100%;
            display: none;
            height: auto;
            padding-bottom: 0;
            overflow: auto;
            -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
            box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
            background-color: #fff;
       }
        #navigation.open {
            display: block;
            overflow-y: auto;
       }
   }
    @media (max-height: 375px) {
        .navigation-menu {
            max-height: 240px !important;
       }
   }
    body[data-layout=horizontal][data-topbar=light] #topnav {
        background: #38414a;
        -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
   }
    body[data-layout=horizontal][data-topbar=light] .navbar-custom {
        background: #fff;
   }
    body[data-layout=horizontal][data-topbar=light] .navbar-custom .app-search .btn, body[data-layout=horizontal][data-topbar=light] .navbar-custom .app-search .form-control {
        background-color: #f5f5f5;
   }
    @media (min-width: 992px) {
        body[data-layout=horizontal][data-topbar=light] .navigation-menu > li > a {
            color: #dee2e6;
       }
   }
    @media (min-width: 992px) {
        body[data-layout=horizontal][data-menu-align=center] #topnav .navigation-menu {
            text-align: center;
       }
        body[data-layout=horizontal][data-menu-align=center] #topnav .navigation-menu > li {
            display: inline-block;
            float: none;
       }
        body[data-layout=horizontal][data-menu-align=center] #topnav .navigation-menu > li > a {
            padding: 20px;
       }
        body[data-layout=horizontal][data-menu-align=center] #topnav .navigation-menu > li > a i {
            display: block;
            margin: 0 0 5px 0;
            float: none;
            text-align: center;
       }
        body[data-layout=horizontal][data-menu-align=center] #topnav .navigation-menu > li > a .arrow-down {
            display: none;
            margin: 0 0 5px 0;
       }
        body[data-layout=horizontal][data-menu-align=center] .content-page {
            padding: 156px 12px 0 12px;
       }
   }
    @media (min-width: 992px) {
        body[data-layout=horizontal][data-menu-dropdown=dark] #topnav .has-submenu.active .submenu li.active > a {
            color: #fff;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li .submenu {
            background-color: #414b56;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li .submenu:before {
            border-bottom: 12px solid #414b56;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li .submenu li a {
            color: #9097a7;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li .submenu li a:hover {
            color: #fff;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li .submenu li .submenu:before {
            border-right: 12px solid #414b56;
            border-bottom: 12px solid transparent;
       }
        body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li > ul > li.has-submenu:active > a, body[data-layout=horizontal][data-menu-dropdown=dark] .navigation-menu > li > ul > li.has-submenu:hover > a {
            color: #fff;
       }
   }
    @media (min-width: 992px) {
        body[data-layout=horizontal][data-layout-header=unsticky] #topnav, body[data-layout=horizontal][data-layout-header=unsticky] .navbar-custom {
            position: absolute;
       }
   }
    body[data-layout-size=boxed] {
        background-color: #dfe5ea;
   }
    body[data-layout-size=boxed] #wrapper {
        max-width: 1300px;
        margin: 0 auto;
        background-color: #ebeff2;
        -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, .15);
   }
    body[data-layout-size=boxed] .navbar-custom {
        max-width: 1300px;
        margin: 0 auto;
   }
    body[data-layout-size=boxed] .footer {
        margin: 0 auto;
        max-width: calc(1300px - 240px);
   }
    body[data-layout-size=boxed].enlarged .footer {
        max-width: calc(1300px - 70px);
   }
    body[data-layout=horizontal][data-layout-size=boxed] #wrapper, body[data-layout=horizontal][data-layout-size=boxed] .footer, body[data-layout=horizontal][data-layout-size=boxed] .navbar-custom {
        max-width: 100%;
   }
    body[data-layout=horizontal][data-layout-size=boxed] .container-fluid {
        max-width: 1300px;
   }
    .width-xs {
        min-width: 80px;
   }
    .width-sm {
        min-width: 100px;
   }
    .width-md {
        min-width: 120px;
   }
    .width-lg {
        min-width: 140px;
   }
    .width-xl {
        min-width: 160px;
   }
    .font-family-secondary {
        font-family: Karla, sans-serif;
   }
    .font-weight-medium {
        font-weight: 500;
   }
    .avatar-xs {
        height: 1.5rem;
        width: 1.5rem;
   }
    .avatar-sm {
        height: 2.25rem;
        width: 2.25rem;
   }
    .avatar-md {
        height: 3.5rem;
        width: 3.5rem;
   }
    .avatar-lg {
        height: 4.5rem;
        width: 4.5rem;
   }
    .avatar-xl {
        height: 6rem;
        width: 6rem;
   }
    .avatar-xxl {
        height: 7.5rem;
        width: 7.5rem;
   }
    .avatar-title {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
   }
    .avatar-group {
        padding-left: 12px;
   }
    .avatar-group .avatar-group-item {
        margin: 0 0 10px -12px;
        display: inline-block;
        border: 2px solid #fff;
        border-radius: 50%;
   }
    .logo .logo-dark {
        display: block;
   }
    .logo .logo-light {
        display: none;
   }
    .sp-line-1, .sp-line-2, .sp-line-3, .sp-line-4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
   }
    .sp-line-1 {
        -webkit-line-clamp: 1;
   }
    .sp-line-2 {
        -webkit-line-clamp: 2;
   }
    .sp-line-3 {
        -webkit-line-clamp: 3;
   }
    .sp-line-4 {
        -webkit-line-clamp: 4;
   }
    .social-list-item {
        height: 2rem;
        width: 2rem;
        line-height: calc(2rem - 2px);
        display: block;
        border: 2px solid #adb5bd;
        border-radius: 50%;
        color: #adb5bd;
        text-align: center;
   }
    .widget-box-2 .widget-detail-2 .badge {
        padding: 5px 10px;
   }
    .inbox-widget .inbox-item {
        border-bottom: 1px solid rgba(222, 226, 230, .5);
        overflow: hidden;
        padding: 0.75rem 0;
        position: relative;
   }
    .inbox-widget .inbox-item:last-child {
        border: none;
   }
    .inbox-widget .inbox-item .inbox-item-img {
        display: block;
        float: left;
        margin-right: 15px;
        width: 40px;
   }
    .inbox-widget .inbox-item .inbox-item-img img {
        width: 40px;
   }
    .inbox-widget .inbox-item .inbox-item-author {
        color: #323a46;
        display: block;
        margin-bottom: 3px;
   }
    .inbox-widget .inbox-item .inbox-item-text {
        color: #98a6ad;
        display: block;
        font-size: 0.8125rem;
        margin: 0;
        overflow: hidden;
   }
    .inbox-widget .inbox-item .inbox-item-date {
        color: #98a6ad;
        font-size: 0.6875rem;
        position: absolute;
        right: 5px;
        top: 10px;
   }
    .checkbox label {
        display: inline-block;
        padding-left: 8px;
        position: relative;
        font-weight: 400;
        cursor: pointer;
   }
    .checkbox label::before {
        background-color: #fff;
        border-radius: 3px;
        border: 2px solid #ced4da;
        content: "";
        display: inline-block;
        height: 18px;
        left: 0;
        margin-left: -18px;
        position: absolute;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        width: 18px;
        outline: 0 !important;
        top: 2px;
   }
    .checkbox label::after {
        color: #6c757d;
        display: inline-block;
        font-size: 11px;
        height: 18px;
        left: 0;
        margin-left: -18px;
        padding-left: 3px;
        padding-top: 2px;
        position: absolute;
        top: 0;
        width: 18px;
   }
    .checkbox input[type=checkbox] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: 0 !important;
   }
    .checkbox input[type=checkbox]:disabled + label {
        opacity: 0.65;
   }
    .checkbox input[type=checkbox]:focus + label::before {
        outline-offset: -2px;
        outline: 0;
   }
    .checkbox input[type=checkbox]:checked + label::after {
        content: "";
        position: absolute;
        top: 6px;
        left: 7px;
        display: table;
        width: 4px;
        height: 8px;
        border: 2px solid #6c757d;
        border-top-width: 0;
        border-left-width: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
   }
    .checkbox input[type=checkbox]:disabled + label::before {
        background-color: #f8f9fa;
        cursor: not-allowed;
   }
    .checkbox.checkbox-circle label::before {
        border-radius: 50%;
   }
    .checkbox.checkbox-inline {
        margin-top: 0;
   }
    .checkbox.checkbox-single input {
        height: 18px;
        width: 18px;
        position: absolute;
   }
    .checkbox.checkbox-single label {
        height: 18px;
        width: 18px;
   }
    .checkbox.checkbox-single label:before {
        margin-left: 0;
   }
    .checkbox.checkbox-single label:after {
        margin-left: 0;
   }
    .checkbox-primary input[type=checkbox]:checked + label::before {
        background-color: #71b6f9;
        border-color: #71b6f9;
   }
    .checkbox-primary input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-secondary input[type=checkbox]:checked + label::before {
        background-color: #6c757d;
        border-color: #6c757d;
   }
    .checkbox-secondary input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-success input[type=checkbox]:checked + label::before {
        background-color: #10c469;
        border-color: #10c469;
   }
    .checkbox-success input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-info input[type=checkbox]:checked + label::before {
        background-color: #35b8e0;
        border-color: #35b8e0;
   }
    .checkbox-info input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-warning input[type=checkbox]:checked + label::before {
        background-color: #f9c851;
        border-color: #f9c851;
   }
    .checkbox-warning input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-danger input[type=checkbox]:checked + label::before {
        background-color: #ff5b5b;
        border-color: #ff5b5b;
   }
    .checkbox-danger input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-light input[type=checkbox]:checked + label::before {
        background-color: #f8f9fa;
        border-color: #f8f9fa;
   }
    .checkbox-light input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-dark input[type=checkbox]:checked + label::before {
        background-color: #323a46;
        border-color: #323a46;
   }
    .checkbox-dark input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-pink input[type=checkbox]:checked + label::before {
        background-color: #ff8acc;
        border-color: #ff8acc;
   }
    .checkbox-pink input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-purple input[type=checkbox]:checked + label::before {
        background-color: #5b69bc;
        border-color: #5b69bc;
   }
    .checkbox-purple input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .checkbox-blue input[type=checkbox]:checked + label::before {
        background-color: #71b6f9;
        border-color: #71b6f9;
   }
    .checkbox-blue input[type=checkbox]:checked + label::after {
        border-color: #fff;
   }
    .radio label {
        display: inline-block;
        padding-left: 8px;
        position: relative;
        font-weight: 400;
        cursor: pointer;
   }
    .radio label::before {
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid #ced4da;
        content: "";
        display: inline-block;
        height: 18px;
        left: 0;
        margin-left: -18px;
        position: absolute;
        -webkit-transition: border 0.5s ease-in-out;
        transition: border 0.5s ease-in-out;
        width: 18px;
        outline: 0 !important;
        top: 2px;
   }
    .radio label::after {
        background-color: #6c757d;
        border-radius: 50%;
        content: " ";
        display: inline-block;
        height: 10px;
        left: 6px;
        margin-left: -20px;
        position: absolute;
        top: 6px;
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        width: 10px;
   }
    .radio input[type=radio] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: 0 !important;
   }
    .radio input[type=radio]:disabled + label {
        opacity: 0.65;
   }
    .radio input[type=radio]:focus + label::before {
        outline-offset: -2px;
        outline: 5px auto -webkit-focus-ring-color;
        outline: thin dotted;
   }
    .radio input[type=radio]:checked + label::after {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
   }
    .radio input[type=radio]:disabled + label::before {
        cursor: not-allowed;
   }
    .radio.radio-inline {
        margin-top: 0;
   }
    .radio.radio-single label {
        height: 17px;
   }
    .radio-primary input[type=radio] + label::after {
        background-color: #71b6f9;
   }
    .radio-primary input[type=radio]:checked + label::before {
        border-color: #71b6f9;
   }
    .radio-primary input[type=radio]:checked + label::after {
        background-color: #71b6f9;
   }
    .radio-secondary input[type=radio] + label::after {
        background-color: #6c757d;
   }
    .radio-secondary input[type=radio]:checked + label::before {
        border-color: #6c757d;
   }
    .radio-secondary input[type=radio]:checked + label::after {
        background-color: #6c757d;
   }
    .radio-success input[type=radio] + label::after {
        background-color: #10c469;
   }
    .radio-success input[type=radio]:checked + label::before {
        border-color: #10c469;
   }
    .radio-success input[type=radio]:checked + label::after {
        background-color: #10c469;
   }
    .radio-info input[type=radio] + label::after {
        background-color: #35b8e0;
   }
    .radio-info input[type=radio]:checked + label::before {
        border-color: #35b8e0;
   }
    .radio-info input[type=radio]:checked + label::after {
        background-color: #35b8e0;
   }
    .radio-warning input[type=radio] + label::after {
        background-color: #f9c851;
   }
    .radio-warning input[type=radio]:checked + label::before {
        border-color: #f9c851;
   }
    .radio-warning input[type=radio]:checked + label::after {
        background-color: #f9c851;
   }
    .radio-danger input[type=radio] + label::after {
        background-color: #ff5b5b;
   }
    .radio-danger input[type=radio]:checked + label::before {
        border-color: #ff5b5b;
   }
    .radio-danger input[type=radio]:checked + label::after {
        background-color: #ff5b5b;
   }
    .radio-light input[type=radio] + label::after {
        background-color: #f8f9fa;
   }
    .radio-light input[type=radio]:checked + label::before {
        border-color: #f8f9fa;
   }
    .radio-light input[type=radio]:checked + label::after {
        background-color: #f8f9fa;
   }
    .radio-dark input[type=radio] + label::after {
        background-color: #323a46;
   }
    .radio-dark input[type=radio]:checked + label::before {
        border-color: #323a46;
   }
    .radio-dark input[type=radio]:checked + label::after {
        background-color: #323a46;
   }
    .radio-pink input[type=radio] + label::after {
        background-color: #ff8acc;
   }
    .radio-pink input[type=radio]:checked + label::before {
        border-color: #ff8acc;
   }
    .radio-pink input[type=radio]:checked + label::after {
        background-color: #ff8acc;
   }
    .radio-purple input[type=radio] + label::after {
        background-color: #5b69bc;
   }
    .radio-purple input[type=radio]:checked + label::before {
        border-color: #5b69bc;
   }
    .radio-purple input[type=radio]:checked + label::after {
        background-color: #5b69bc;
   }
    .radio-blue input[type=radio] + label::after {
        background-color: #71b6f9;
   }
    .radio-blue input[type=radio]:checked + label::before {
        border-color: #71b6f9;
   }
    .radio-blue input[type=radio]:checked + label::after {
        background-color: #71b6f9;
   }
    .ribbon-box {
        position: relative;
   }
    .ribbon-box .ribbon {
        position: relative;
        clear: both;
        padding: 5px 12px;
        margin-bottom: 15px;
        -webkit-box-shadow: 2px 5px 10px rgba(50, 58, 70, .15);
        box-shadow: 2px 5px 10px rgba(50, 58, 70, .15);
        color: #fff;
        font-size: 13px;
        font-weight: 600;
   }
    .ribbon-box .ribbon:before {
        content: " ";
        border-style: solid;
        border-width: 10px;
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        margin-bottom: -10px;
        z-index: -1;
   }
    .ribbon-box .ribbon.float-left {
        margin-left: -30px;
        border-radius: 0 3px 3px 0;
   }
    .ribbon-box .ribbon.float-right {
        margin-right: -30px;
        border-radius: 3px 0 0 3px;
   }
    .ribbon-box .ribbon.float-right:before {
        right: 0;
   }
    .ribbon-box .ribbon.float-center span {
        margin: 0 auto 20px auto;
   }
    .ribbon-box .ribbon-content {
        clear: both;
   }
    .ribbon-box .ribbon-primary {
        background: #71b6f9;
   }
    .ribbon-box .ribbon-primary:before {
        border-color: #409df7 transparent transparent;
   }
    .ribbon-box .ribbon-secondary {
        background: #6c757d;
   }
    .ribbon-box .ribbon-secondary:before {
        border-color: #545b62 transparent transparent;
   }
    .ribbon-box .ribbon-success {
        background: #10c469;
   }
    .ribbon-box .ribbon-success:before {
        border-color: #0c9550 transparent transparent;
   }
    .ribbon-box .ribbon-info {
        background: #35b8e0;
   }
    .ribbon-box .ribbon-info:before {
        border-color: #1e9dc4 transparent transparent;
   }
    .ribbon-box .ribbon-warning {
        background: #f9c851;
   }
    .ribbon-box .ribbon-warning:before {
        border-color: #f7b820 transparent transparent;
   }
    .ribbon-box .ribbon-danger {
        background: #ff5b5b;
   }
    .ribbon-box .ribbon-danger:before {
        border-color: #ff2828 transparent transparent;
   }
    .ribbon-box .ribbon-light {
        background: #f8f9fa;
   }
    .ribbon-box .ribbon-light:before {
        border-color: #dae0e5 transparent transparent;
   }
    .ribbon-box .ribbon-dark {
        background: #323a46;
   }
    .ribbon-box .ribbon-dark:before {
        border-color: #1d2128 transparent transparent;
   }
    .ribbon-box .ribbon-pink {
        background: #ff8acc;
   }
    .ribbon-box .ribbon-pink:before {
        border-color: #ff57b6 transparent transparent;
   }
    .ribbon-box .ribbon-purple {
        background: #5b69bc;
   }
    .ribbon-box .ribbon-purple:before {
        border-color: #4250a2 transparent transparent;
   }
    .ribbon-box .ribbon-blue {
        background: #71b6f9;
   }
    .ribbon-box .ribbon-blue:before {
        border-color: #409df7 transparent transparent;
   }
    .ribbon-box .ribbon-two {
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;
   }
    .ribbon-box .ribbon-two span {
        font-size: 13px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        display: block;
        -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        position: absolute;
        top: 19px;
        left: -21px;
        font-weight: 600;
   }
    .ribbon-box .ribbon-two span:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
   }
    .ribbon-box .ribbon-two span:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
   }
    .ribbon-box .ribbon-two-primary span {
        background: #71b6f9;
   }
    .ribbon-box .ribbon-two-primary span:before {
        border-left: 3px solid #2890f6;
        border-top: 3px solid #2890f6;
   }
    .ribbon-box .ribbon-two-primary span:after {
        border-right: 3px solid #2890f6;
        border-top: 3px solid #2890f6;
   }
    .ribbon-box .ribbon-two-secondary span {
        background: #6c757d;
   }
    .ribbon-box .ribbon-two-secondary span:before {
        border-left: 3px solid #494f54;
        border-top: 3px solid #494f54;
   }
    .ribbon-box .ribbon-two-secondary span:after {
        border-right: 3px solid #494f54;
        border-top: 3px solid #494f54;
   }
    .ribbon-box .ribbon-two-success span {
        background: #10c469;
   }
    .ribbon-box .ribbon-two-success span:before {
        border-left: 3px solid #0a7d43;
        border-top: 3px solid #0a7d43;
   }
    .ribbon-box .ribbon-two-success span:after {
        border-right: 3px solid #0a7d43;
        border-top: 3px solid #0a7d43;
   }
    .ribbon-box .ribbon-two-info span {
        background: #35b8e0;
   }
    .ribbon-box .ribbon-two-info span:before {
        border-left: 3px solid #1b8bae;
        border-top: 3px solid #1b8bae;
   }
    .ribbon-box .ribbon-two-info span:after {
        border-right: 3px solid #1b8bae;
        border-top: 3px solid #1b8bae;
   }
    .ribbon-box .ribbon-two-warning span {
        background: #f9c851;
   }
    .ribbon-box .ribbon-two-warning span:before {
        border-left: 3px solid #f5b008;
        border-top: 3px solid #f5b008;
   }
    .ribbon-box .ribbon-two-warning span:after {
        border-right: 3px solid #f5b008;
        border-top: 3px solid #f5b008;
   }
    .ribbon-box .ribbon-two-danger span {
        background: #ff5b5b;
   }
    .ribbon-box .ribbon-two-danger span:before {
        border-left: 3px solid #ff0f0f;
        border-top: 3px solid #ff0f0f;
   }
    .ribbon-box .ribbon-two-danger span:after {
        border-right: 3px solid #ff0f0f;
        border-top: 3px solid #ff0f0f;
   }
    .ribbon-box .ribbon-two-light span {
        background: #f8f9fa;
   }
    .ribbon-box .ribbon-two-light span:before {
        border-left: 3px solid #cbd3da;
        border-top: 3px solid #cbd3da;
   }
    .ribbon-box .ribbon-two-light span:after {
        border-right: 3px solid #cbd3da;
        border-top: 3px solid #cbd3da;
   }
    .ribbon-box .ribbon-two-dark span {
        background: #323a46;
   }
    .ribbon-box .ribbon-two-dark span:before {
        border-left: 3px solid #121519;
        border-top: 3px solid #121519;
   }
    .ribbon-box .ribbon-two-dark span:after {
        border-right: 3px solid #121519;
        border-top: 3px solid #121519;
   }
    .ribbon-box .ribbon-two-pink span {
        background: #ff8acc;
   }
    .ribbon-box .ribbon-two-pink span:before {
        border-left: 3px solid #ff3eab;
        border-top: 3px solid #ff3eab;
   }
    .ribbon-box .ribbon-two-pink span:after {
        border-right: 3px solid #ff3eab;
        border-top: 3px solid #ff3eab;
   }
    .ribbon-box .ribbon-two-purple span {
        background: #5b69bc;
   }
    .ribbon-box .ribbon-two-purple span:before {
        border-left: 3px solid #3b4790;
        border-top: 3px solid #3b4790;
   }
    .ribbon-box .ribbon-two-purple span:after {
        border-right: 3px solid #3b4790;
        border-top: 3px solid #3b4790;
   }
    .ribbon-box .ribbon-two-blue span {
        background: #71b6f9;
   }
    .ribbon-box .ribbon-two-blue span:before {
        border-left: 3px solid #2890f6;
        border-top: 3px solid #2890f6;
   }
    .ribbon-box .ribbon-two-blue span:after {
        border-right: 3px solid #2890f6;
        border-top: 3px solid #2890f6;
   }
    @media print {
        .footer, .left-side-menu, .navbar-custom, .page-title-box, .right-bar {
            display: none;
       }
        .card-body, .content, .content-page, .right-bar, body {
            padding: 0;
            margin: 0;
       }
   }
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 9999;
   }
    #status {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
   }
    .spinner {
        margin: 0 auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 5px solid #dee2e6;
        border-right: 5px solid #dee2e6;
        border-bottom: 5px solid #dee2e6;
        border-left: 5px solid #71b6f9;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: SpinnerAnimation 1.1s infinite linear;
        animation: SpinnerAnimation 1.1s infinite linear;
   }
    .spinner, .spinner:after {
        border-radius: 50%;
        width: 40px;
        height: 40px;
   }
    @-webkit-keyframes SpinnerAnimation {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
       }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
       }
   }
    @keyframes SpinnerAnimation {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
       }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
       }
   }
    .custom-accordion .card {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #dee2e6 !important;
   }
    .button-list .btn {
        margin-top: 7px;
        margin-right: 5px;
   }
    .list-group-item {
        border: 1px solid #fcfcfc;
        border-left: none;
        border-right: none;
   }
    .list-group-item:hover {
        background: #fcfcfc;
   }
    .list-group-item:first-child {
        border-top: none;
   }
    .list-group-item:last-child {
        border-bottom: none;
   }
    .icons-list-demo div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
   }
    .icons-list-demo div p {
        margin-bottom: 0;
        line-height: inherit;
   }
    .icons-list-demo i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        color: rgba(50, 58, 70, .7);
        border-radius: 3px;
        display: inline-block;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
   }
    .icons-list-demo .col-lg-4 {
        border-radius: 3px;
        -moz-border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;
   }
    .icons-list-demo .col-lg-4:hover, .icons-list-demo .col-lg-4:hover i {
        color: #71b6f9;
   }
    .demos-show-btn {
        position: fixed;
        top: 50%;
        right: 0;
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        font-weight: 500;
        background-color: #ff5b5b;
        color: #fff !important;
        line-height: 36px;
        padding: 15px 3px;
        border-radius: 6px 0 0 6px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-transform: uppercase;
        z-index: 9;
   }
    @media (max-width: 600px) {
        .demos-show-btn {
            display: none;
       }
   }
    .scrollspy-example {
        position: relative;
        height: 200px;
        margin-top: 0.5rem;
        overflow: auto;
   }
    .chat-list li a {
        display: block;
        color: #98a6ad;
        position: relative;
        padding: 12px 14px;
        font-size: 13px;
   }
    .chat-list li a .chat-user-img .avatar-title, .chat-list li a .chat-user-img img {
        padding: 2px;
        border: 2px solid #f8f9fa;
   }
    .chat-list li a .chat-user-img.active .avatar-title, .chat-list li a .chat-user-img.active img {
        border-color: #10c469;
   }
    .chat-list li.active a {
        background-color: #f8f9fa;
   }
    .chat-list li.unread a::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #71b6f9;
        right: 24px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
   }
    .conversation-list {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
   }
    .conversation-list li {
        margin-bottom: 24px;
   }
    .conversation-list li .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
   }
    .conversation-list li .chat-day-title .title {
        background-color: #fff;
        position: relative;
        z-index: 1;
        padding: 6px 24px;
   }
    .conversation-list li .chat-day-title:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: #f8f9fa;
        top: 10px;
   }
    .conversation-list li .message-list::after {
        display: block;
        clear: both;
        content: "";
   }
    .conversation-list .chat-avatar {
        float: left;
        text-align: center;
        width: 36px;
   }
    .conversation-list .chat-avatar img {
        border-radius: 50%;
        width: 100%;
   }
    .conversation-list .ctext-wrap {
        background: #71b6f9;
        border-radius: 0 6px 6px 6px;
        display: inline-block;
        padding: 12px;
        position: relative;
        color: #fff;
   }
    .conversation-list .ctext-wrap:after {
        content: " ";
        position: absolute;
        right: 100%;
        top: 0;
        border: solid transparent;
        border-right-color: #71b6f9;
        border-top-color: #71b6f9;
        border-width: 5px;
   }
    .conversation-list .ctext-wrap .user-name {
        display: block;
        font-size: 12px;
        font-weight: 600;
        position: relative;
   }
    .conversation-list .ctext-wrap p {
        margin: 0;
        padding-top: 4px;
   }
    .conversation-list .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
   }
    .conversation-list .conversation-text .time {
        display: block;
        font-size: 12px;
        margin-top: 6px;
   }
    .conversation-list .odd .chat-avatar {
        float: right;
   }
    .conversation-list .odd .conversation-text {
        float: right;
        margin-left: 0;
        margin-right: 12px;
        text-align: right;
   }
    .conversation-list .odd .ctext-wrap {
        background-color: #f8f9fa;
        color: #6c757d;
        border-radius: 6px 0 6px 6px;
   }
    .conversation-list .odd .ctext-wrap:after {
        left: 100%;
        right: auto;
        border-right-color: transparent;
        border-left-color: #f8f9fa;
        border-top-color: #f8f9fa;
   }
    @media (max-width: 575.98px) {
        .chat-send {
            min-width: auto;
       }
   }
    .inbox-app-main {
        margin-left: -20px;
   }
    .circle-icon {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 26px;
        border: 2px solid #98a6ad;
        border-radius: 100px;
        font-size: 14px;
        color: #98a6ad;
        cursor: pointer;
        display: block;
        float: left;
   }
    .circle-icon:hover {
        color: #6e818a;
        border-color: #6e818a;
   }
    .circle-icon.small {
        height: 25px;
        width: 25px;
        line-height: 21px;
        font-size: 11px;
   }
    .circle-icon.red {
        color: #ff5b5b;
        border-color: #ff5b5b;
   }
    .circle-icon.red:hover {
        color: #c10000;
        border-color: #c10000;
   }
    .checkbox-wrapper-mail {
        cursor: pointer;
        height: 20px;
        width: 20px;
        position: relative;
        display: inline-block;
        -webkit-box-shadow: inset 0 0 0 1px #98a6ad;
        box-shadow: inset 0 0 0 1px #98a6ad;
        border-radius: 1px;
   }
    .checkbox-wrapper-mail input {
        opacity: 0;
        cursor: pointer;
        display: none;
   }
    .checkbox-wrapper-mail input:checked ~ label {
        opacity: 1;
   }
    .checkbox-wrapper-mail label {
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        cursor: pointer;
        background: #98a6ad;
        opacity: 0;
        margin-bottom: 0 !important;
        -webkit-transition-duration: 0.05s;
        transition-duration: 0.05s;
   }
    .checkbox-wrapper-mail label:active {
        background: #6c757d;
   }
    #sidebar {
        position: fixed;
        height: 100%;
        width: 240px;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        z-index: 5;
        color: #323a46;
   }
    #sidebar li a {
        color: #323a46;
   }
    #sidebar .menu-segment {
        padding: 0 50px;
   }
    #sidebar .menu-segment li, #sidebar .menu-segment ul {
        margin: 0;
        padding: 0;
   }
    #sidebar .menu-segment .ball.pink {
        border-color: #ff8acc;
   }
    #sidebar .menu-segment .ball.green {
        border-color: #10c469;
   }
    #sidebar .menu-segment .ball.blue {
        border-color: #71b6f9;
   }
    #sidebar .menu-segment li.title {
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 0;
        color: #6c757d;
   }
    #sidebar .menu-segment li.title .icon {
        float: right;
   }
    #sidebar .menu-segment li.active a, #sidebar .menu-segment li.active a:hover {
        color: #ff5b5b;
        font-weight: 600;
   }
    #sidebar .menu-segment li a {
        display: block;
        margin: 0 -50px;
        padding: 10px 50px;
   }
    #sidebar .menu-segment li a:hover {
        background: rgba(206, 212, 218, .3);
   }
    #sidebar .menu-segment li .italic-link {
        font-style: italic;
        font-weight: 600;
   }
    #sidebar .menu-segment .chat li a span, #sidebar .menu-segment .labels li a span {
        display: block;
        border: 5px solid #ff5b5b;
        border-radius: 100px;
        margin-top: 6px;
   }
    #sidebar .menu-segment .chat.labels li a span, #sidebar .menu-segment .labels.labels li a span {
        float: right;
   }
    #sidebar .menu-segment .chat.chat li a span, #sidebar .menu-segment .labels.chat li a span {
        float: left;
        margin-right: 10px;
   }
    #sidebar .menu-segment .chat li a {
        padding-left: 50px;
   }
    #sidebar .separator {
        margin: 30px 35px;
        height: 1px;
        background: rgba(152, 166, 173, .5);
   }
    #sidebar .bottom-padding {
        height: 100px;
   }
    #sidebar > .nano-pane {
        background: 0 0;
        width: 5px;
   }
    #sidebar > .nano-pane .nano-slider {
        background: rgba(152, 166, 173, .3);
   }
    #main {
        position: fixed;
        top: 72px;
        left: 490px;
        bottom: 0;
        right: 0;
        z-index: 4;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        background-color: #fff;
   }
    #main .overlay {
        position: absolute;
        top: 0;
        left: -5px;
        right: 0;
        bottom: 100%;
        background: rgba(50, 58, 70, .6);
        z-index: 5;
        opacity: 0;
        -webkit-transition-duration: 0s;
        transition-duration: 0s;
        -webkit-transition-property: opacity;
        transition-property: opacity;
   }
    #main .header {
        padding: 12px 25px;
        border-bottom: 1px solid rgba(152, 166, 173, .3);
        overflow: hidden;
   }
    #main .header .page-title {
        display: block;
   }
    #main .header .page-title .sidebar-toggle-btn {
        width: 0;
        margin-top: 1px;
        padding: 11px 0 0 0;
        float: left;
        position: relative;
        display: block;
        cursor: pointer;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-delay: 0.5s;
        transition-delay: 0.5s;
        opacity: 0;
        margin-right: 0;
   }
    #main .header .page-title .sidebar-toggle-btn .line {
        height: 3px;
        display: block;
        background: #ced4da;
        margin-bottom: 4px;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-delay: 0.5s;
        transition-delay: 0.5s;
   }
    #main .header .page-title .sidebar-toggle-btn .line-angle1 {
        width: 8px;
        margin: 0;
        position: absolute;
        top: 15px;
        left: -11px;
        -webkit-transform: rotate(-60deg);
        transform: rotate(-60deg);
   }
    #main .header .page-title .sidebar-toggle-btn .line-angle2 {
        width: 8px;
        margin: 0;
        position: absolute;
        top: 21px;
        left: -11px;
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
   }
    #main .header .page-title .icon {
        font-size: 15px;
        margin-left: 20px;
        position: relative;
        top: -5px;
        cursor: pointer;
   }
    #main .header .search-box {
        width: 180px;
        height: 40px;
        margin-left: 30px;
        position: relative;
   }
    #main .header .search-box input {
        position: absolute;
        top: 0;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 0 36px 0 0;
        margin: 0;
        text-indent: 15px;
        height: 38px;
        z-index: 2;
        outline: 0;
        color: #adb5bd;
        background: 0 0;
        border: 2px solid #dee2e6;
        border-radius: 5px;
        -webkit-transition-timing-function: cubic-bezier(0.3, 1.5, 0.6, 1);
        transition-timing-function: cubic-bezier(0.3, 1.5, 0.6, 1);
   }
    #main .header .search-box input:focus {
        color: #323a46;
        border-color: #dee2e6;
   }
    #main .header .search-box input:focus ~ .icon {
        opacity: 1;
        z-index: 3;
        color: #10c469;
   }
    #main .header .search-box input::-webkit-input-placeholder {
        color: #adb5bd;
   }
    #main .header .search-box input::-moz-placeholder {
        color: #adb5bd;
   }
    #main .header .search-box input:-ms-input-placeholder {
        color: #adb5bd;
   }
    #main .header .search-box input::-ms-input-placeholder {
        color: #adb5bd;
   }
    #main .header .search-box input::placeholder {
        color: #adb5bd;
   }
    #main .header .search-box .icon {
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        text-align: center;
        line-height: 38px;
        z-index: 1;
        cursor: pointer;
        opacity: 0.5;
   }
    #main .action-bar {
        padding: 5px;
        overflow: hidden;
   }
    #main .message-list {
        display: block;
        padding-left: 0;
   }
    #main .message-list li {
        position: relative;
        display: block;
        height: 50px;
        line-height: 50px;
        cursor: default;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        color: #6c757d;
   }
    #main .message-list li:hover {
        background: #f8f9fa;
        -webkit-transition-duration: 0.05s;
        transition-duration: 0.05s;
   }
    #main .message-list li .mail-col {
        float: left;
        position: relative;
   }
    #main .message-list li .mail-col-1 {
        width: 400px;
   }
    #main .message-list li .mail-col-1 .checkbox-wrapper-mail, #main .message-list li .mail-col-1 .dot, #main .message-list li .mail-col-1 .star-toggle {
        display: block;
        float: left;
   }
    #main .message-list li .mail-col-1 .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0;
   }
    #main .message-list li .mail-col-1 .checkbox-wrapper-mail {
        margin-top: 15px;
        margin-right: 10px;
   }
    #main .message-list li .mail-col-1 .star-toggle {
        margin-top: 18px;
        font-size: 14px;
        margin-left: 5px;
   }
    #main .message-list li .mail-col-1 .title {
        position: absolute;
        top: 0;
        left: 140px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
   }
    #main .message-list li .mail-col-2 {
        position: absolute;
        top: 0;
        left: 400px;
        right: 0;
        bottom: 0;
   }
    #main .message-list li .mail-col-2 .date, #main .message-list li .mail-col-2 .subject {
        position: absolute;
        top: 0;
   }
    #main .message-list li .mail-col-2 .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
   }
    #main .message-list li .mail-col-2 .date {
        right: 0;
        width: 200px;
        padding-left: 80px;
   }
    #main .message-list li.active, #main .message-list li.selected {
        background: #f8f9fa;
        -webkit-transition-duration: 0.05s;
        transition-duration: 0.05s;
   }
    #main .message-list li.active, #main .message-list li.active:hover {
        -webkit-box-shadow: inset 3px 0 0 #71b6f9;
        box-shadow: inset 3px 0 0 #71b6f9;
   }
    #main .message-list li.unread {
        font-weight: 500;
        color: #343a40;
   }
    #main .message-list li.blue-dot .mail-col-1 .dot {
        border-color: #71b6f9;
   }
    #main .message-list li.orange-dot .mail-col-1 .dot {
        border-color: #f9c851;
   }
    #main .message-list li.green-dot .mail-col-1 .dot {
        border-color: #10c469;
   }
    #main .load-more-link {
        display: block;
        text-align: center;
        margin: 30px 0 50px 0;
   }
    body[data-layout=horizontal] #main {
        top: 132px;
        left: 350px;
   }
    @media (max-width: 1024px) {
        body[data-layout=horizontal] #main {
            left: 270px;
       }
   }
    @media (max-width: 768px) {
        body[data-layout=horizontal] #main {
            left: 0;
            top: 70px;
       }
   }
    body[data-layout=horizontal] #message {
        top: 132px;
   }
    @media (max-width: 768px) {
        body[data-layout=horizontal] #message {
            top: 70px;
       }
   }
    .show-main-overlay #main .overlay {
        opacity: 1;
        bottom: 0;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
   }
    .show-sidebar #main .header .page-title .sidebar-toggle-btn {
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
   }
    .show-sidebar #main .header .page-title .sidebar-toggle-btn .line-angle1 {
        -webkit-transform: rotate(-120deg);
        transform: rotate(-120deg);
   }
    .show-sidebar #main .header .page-title .sidebar-toggle-btn .line-angle2 {
        -webkit-transform: rotate(120deg);
        transform: rotate(120deg);
   }
    #main #main-nano-wrapper {
        position: absolute;
        top: 100px;
        bottom: 0;
        height: auto;
   }
    #message {
        position: fixed;
        top: 72px;
        left: 60%;
        bottom: 0;
        width: 40%;
        z-index: 5;
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        padding: 20px 30px;
        background: #fff;
        -webkit-box-shadow: 0 0 77px 0 rgba(0, 0, 0, .08), 0 1px 0 0 rgba(0, 0, 0, .02);
        box-shadow: 0 0 77px 0 rgba(0, 0, 0, .08), 0 1px 0 0 rgba(0, 0, 0, .02);
   }
    #message .header {
        padding-bottom: 20px;
        border-bottom: 2px solid rgba(152, 166, 173, .2);
   }
    #message .header .page-title {
        display: block;
        float: none;
        margin-bottom: 15px;
        line-height: 40px;
   }
    #message .header .page-title .icon {
        margin-top: 4px;
        margin-right: 10px;
   }
    #message .header .grey {
        margin-left: 10px;
        color: #adb5bd;
   }
    #message .message-container {
        padding: 0 30px;
   }
    #message .message-container li {
        padding: 25px;
        border: 1px solid #dee2e6;
        background: #fff;
        margin: 0 0 30px 0;
        position: relative;
   }
    #message .message-container li .details {
        padding-bottom: 20px;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 30px;
        overflow: hidden;
   }
    #message .message-container li .details .left {
        float: left;
        font-weight: 600;
        color: #adb5bd;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
   }
    #message .message-container li .details .left .arrow {
        display: inline-block;
        position: relative;
        height: 2px;
        width: 20px;
        background: rgba(50, 58, 70, .15);
        vertical-align: top;
        margin: 10px 20px 0 15px;
        border: 0 solid rgba(50, 58, 70, .15);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
   }
    #message .message-container li .details .left .arrow:after {
        position: absolute;
        top: -4px;
        left: 100%;
        height: 0;
        width: 0;
        border: inherit;
        border-width: 7px;
        border-style: solid;
        content: '';
        border-right: 0;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-top-width: 5px;
        border-bottom-width: 5px;
   }
    #message .message-container li .details .right {
        float: right;
        color: #adb5bd;
   }
    #message .message-container li .message {
        margin-bottom: 40px;
   }
    #message .message-container li .message p:last-child {
        margin-bottom: 0;
   }
    #message .message-container li:hover .tool-box .red-hover {
        color: #ff5b5b;
        border-color: #ff5b5b;
   }
    #message .message-container li:hover .tool-box .red-hover:hover {
        color: #f40000;
        border-color: #f40000;
   }
    #message .message-container li .tool-box {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0 solid #dee2e6;
        border-top-width: 1px;
        border-left-width: 1px;
        padding: 8px 10px;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
   }
    #message .message-container li .tool-box a {
        margin-right: 10px;
   }
    #message .message-container li .tool-box a:last-child {
        margin-right: 0;
   }
    #message .message-container li:hover .details .left .arrow {
        background: #10c469;
        border: 0 solid #10c469;
   }
    #message .message-container li:hover .details .left .arrow.orange {
        background: #f9c851;
        border: 0 solid #f9c851;
   }
    #message #message-nano-wrapper {
        position: absolute;
        top: 130px;
        bottom: 0;
        height: auto;
        left: 0;
        right: 0;
        width: auto;
   }
    .show-message #message {
        -webkit-transform: none;
        transform: none;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
   }
    .show-message #main {
        margin-right: 40%;
   }
    @media only screen and (min-width: 1499px) {
        #main .overlay {
            display: none;
       }
   }
    @media only screen and (max-width: 1500px) {
        .show-message #main {
            margin-right: 0;
       }
        .show-message #message {
            left: 50%;
            width: 50%;
       }
   }
    @media only screen and (max-width: 1024px) {
        #main {
            left: 340px;
       }
        .show-sidebar #sidebar {
            -webkit-transform: none;
            transform: none;
       }
        .show-sidebar #main {
            -webkit-transform: translateX(250px);
            transform: translateX(250px);
       }
        .show-message #main {
            margin-right: 0;
       }
   }
    @media only screen and (max-width: 768px) {
        #sidebar {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
       }
        #main {
            left: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
       }
        #main .header .page-title .sidebar-toggle-btn {
            margin-right: 20px;
            opacity: 1;
            width: 20px;
            margin-left: 10px;
       }
        #main .header .page-title .sidebar-toggle-btn .line {
            height: 2px;
       }
        .show-sidebar #sidebar {
            -webkit-transform: none;
            transform: none;
       }
        .show-sidebar #main {
            -webkit-transform: translateX(250px);
            transform: translateX(250px);
       }
        .show-message #main {
            margin-right: 0;
       }
        .show-message #message {
            left: 20%;
            width: 80%;
       }
   }
    @media only screen and (max-width: 600px) {
        #main .header .search-box {
            float: none;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: 0;
       }
        #main .header .search-box input, #main .header .search-box input:focus {
            width: 100%;
       }
        #main .action-bar {
            float: none !important;
       }
        #main .action-bar ul {
            margin-bottom: 0;
            text-align: center;
       }
        #main #main-nano-wrapper {
            position: absolute;
            top: 150px;
            bottom: 0;
            height: auto;
       }
        #main .message-list li .mail-col-1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 120px;
            width: auto;
       }
        #main .message-list li .mail-col-2 {
            right: 0;
            left: auto;
            width: 120px;
       }
        #main .message-list li .mail-col-2 .date {
            padding-left: 0;
            position: static;
       }
        #main .message-list li .mail-col-2 .subject {
            display: none;
       }
   }
    .nano {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
   }
    .nano > .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
   }
    .nano > .nano-content:focus {
        outline: 0;
   }
    .nano > .nano-content:-webkit-scrollbar {
        visibility: hidden;
   }
    .nano > .nano-pane {
        background: rgba(152, 166, 173, .1);
        position: absolute;
        width: 7px;
        right: 8px;
        top: 8px;
        bottom: 8px;
        opacity: 0.01;
        border-radius: 5px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
   }
    .nano > .nano-pane > .nano-slider {
        background: rgba(152, 166, 173, .35);
        position: relative;
        border-radius: 3px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        -webkit-transition-property: background;
        transition-property: background;
   }
    .nano > .nano-pane.active .nano-slider, .nano > .nano-pane:hover .nano-slider {
        background: #dee2e6;
   }
    .has-scrollbar > .nano-content:-webkit-scrollbar {
        visibility: visible;
   }
    .nano-pane.active, .nano-pane.flashed, .nano:hover > .nano-pane {
        opacity: 0.99;
   }
    .authentication-bg.enlarged {
        min-height: 100px;
   }
    body.authentication-bg {
        background-image: url(../../assets/images/bg-light-img.jpg);
        background-size: cover;
        background-position: center;
   }
    .logout-icon {
        width: 140px;
   }
    .text-error {
        color: #71b6f9;
        text-shadow: rgba(113, 182, 249, .3) 5px 1px, rgba(113, 182, 249, .2) 10px 3px;
        font-size: 84px;
        line-height: 90px;
        font-family: Karla, sans-serif;
   }
    .taskList {
        min-height: 40px;
        margin-bottom: 0;
   }
    .taskList > li {
        background-color: #fff;
        border: 1px solid #dee2e6;
        padding: 20px;
        margin-bottom: 15px;
        border-radius: 3px;
   }
    .taskList .kanban-detail {
        margin-left: 35px;
   }
    .taskList .kanban-detail ul li a {
        display: inline-block;
   }
    .taskList .kanban-detail ul li a i {
        font-size: 16px;
        color: #98a6ad;
        padding: 0 5px;
        line-height: 32px;
   }
    .task-placeholder {
        border: 1px dashed #dee2e6 !important;
        background-color: #f7f7f7 !important;
        padding: 20px;
   }
    .gu-mirror {
        list-style: none;
   }
    .task-tags .bootstrap-tagsinput {
        padding: 0;
        border: none;
        background-color: transparent;
   }
    .attached-files .files-list .file-box {
        vertical-align: middle;
   }
    .assign-team a {
        margin-right: 7px;
   }
    .fileupload {
        overflow: hidden;
        position: relative;
   }
    .fileupload input.upload {
        cursor: pointer;
        font-size: 20px;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
   }
    .add-new-plus {
        height: 32px;
        text-align: center;
        width: 32px;
        display: inline-block;
        line-height: 32px;
        color: #98a6ad;
        font-weight: 700;
        background-color: #dee2e6;
        border-radius: 50%;
   }
    .profile-pills li a {
        color: #adb5bd !important;
        padding: 0 10px;
        display: block;
        line-height: 30px !important;
   }
    .profile-pills li a i {
        font-size: 14px;
   }
    .comment-footer {
        display: block;
        font-size: 12px;
   }
    .comment-footer i {
        font-size: 13px;
        margin-right: 7px;
   }
    .comment-footer, .comment-footer a {
        color: rgba(50, 58, 70, .5);
   }
    .comment-footer a:hover, .comment-footer:hover {
        color: #71b6f9;
   }
    .pricing-column {
        position: relative;
        margin-bottom: 40px;
   }
    .pricing-column .inner-box {
        position: relative;
        padding: 0 0 40px;
   }
    .pricing-column .plan-title {
        font-size: 16px;
        color: #71b6f9;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
   }
    .pricing-column .plan-price {
        font-size: 40px;
        margin-bottom: 10px;
        color: #323a46;
   }
    .pricing-column .plan-duration {
        font-size: 13px;
        color: #98a6ad;
   }
    .pricing-column .plan-stats li {
        margin-bottom: 15px;
        line-height: 24px;
   }
    .ribbon {
        position: absolute;
        left: 5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;
   }
    .ribbon span {
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 100px;
        display: block;
        -webkit-box-shadow: 0 0 8px 0 rgba(50, 58, 70, .06), 0 1px 0 0 rgba(50, 58, 70, .02);
        box-shadow: 0 0 8px 0 rgba(50, 58, 70, .06), 0 1px 0 0 rgba(50, 58, 70, .02);
        background: #71b6f9;
        position: absolute;
        top: 19px;
        left: -21px;
   }
    .ribbon span:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #71b6f9;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #71b6f9;
   }
    .ribbon span:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #71b6f9;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #71b6f9;
   }
    .timeline {
        border-collapse: collapse;
        border-spacing: 0;
        display: table;
        margin-bottom: 50px;
        position: relative;
        table-layout: fixed;
        width: 100%;
   }
    .timeline .time-show {
        margin-bottom: 30px;
        margin-right: -75px;
        margin-top: 30px;
        position: relative;
        text-align: right;
   }
    .timeline .time-show a {
        color: #fff;
   }
    .timeline:before {
        background-color: rgba(173, 181, 189, .3);
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 30px;
        width: 2px;
        z-index: 0;
   }
    .timeline .timeline-icon {
        -webkit-border-radius: 50%;
        background: #98a6ad;
        border-radius: 50%;
        color: #fff;
        display: block;
        height: 21px;
        left: -54px;
        margin-top: -11px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 21px;
   }
    .timeline .timeline-icon i {
        color: #f7f7f7;
   }
    .timeline .time-icon:before {
        font-size: 16px;
        margin-top: 5px;
   }
    h3.timeline-title {
        color: #98a6ad;
        font-size: 20px;
        font-weight: 400;
        margin: 0 0 5px;
        text-transform: uppercase;
   }
    .timeline-item {
        display: table-row;
   }
    .timeline-item:before {
        content: "";
        display: block;
        width: 50%;
   }
    .timeline-item .timeline-desk .arrow {
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fff !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        left: -7px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
        width: 0;
   }
    .timeline-item.alt:after {
        content: "";
        display: block;
        width: 50%;
   }
    .timeline-item.alt .timeline-desk .arrow-alt {
        border-bottom: 8px solid transparent;
        border-left: 8px solid #fff !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        left: auto;
        margin-top: -10px;
        position: absolute;
        right: -7px;
        top: 50%;
        width: 0;
   }
    .timeline-item.alt .timeline-desk .album {
        float: right;
        margin-top: 20px;
   }
    .timeline-item.alt .timeline-desk .album a {
        float: right;
        margin-left: 5px;
   }
    .timeline-item.alt .timeline-icon {
        left: auto;
        right: -58px;
   }
    .timeline-item.alt:before {
        display: none;
   }
    .timeline-item.alt .panel {
        margin-left: 0;
        margin-right: 45px;
   }
    .timeline-item.alt .panel .panel-body p + p {
        margin-top: 10px !important;
   }
    .timeline-item.alt h4 {
        text-align: right;
   }
    .timeline-item.alt p {
        text-align: right;
   }
    .timeline-item.alt .timeline-date {
        text-align: right;
   }
    .timeline-desk {
        display: table-cell;
        vertical-align: top;
        width: 50%;
   }
    .timeline-desk h4 {
        font-size: 16px;
        font-weight: 300;
        margin: 0;
   }
    .timeline-desk .panel {
        background: #fff;
        display: block;
        margin-bottom: 5px;
        margin-left: 45px;
        position: relative;
        text-align: left;
        padding: 20px;
        border-radius: 7px;
   }
    .timeline-desk h5 span {
        color: #f7f7f7;
        display: block;
        font-size: 12px;
        margin-bottom: 4px;
   }
    .timeline-desk p {
        color: #98a6ad;
        font-size: 14px;
        margin-bottom: 0;
   }
    .timeline-desk .album {
        margin-top: 12px;
   }
    .timeline-desk .album a {
        float: left;
        margin-right: 5px;
   }
    .timeline-desk .album img {
        height: 36px;
        width: auto;
        border-radius: 3px;
   }
    .timeline-desk .notification {
        background: none repeat scroll 0 0 #fff;
        margin-top: 20px;
        padding: 8px;
   }
    .portfolioFilter a {
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        color: #323a46;
        border-radius: 3px;
        padding: 5px 10px;
        display: inline-block;
        font-weight: 500;
   }
    .portfolioFilter a:hover {
        color: #71b6f9;
   }
    .portfolioFilter a.current {
        color: #71b6f9;
   }
    .thumb {
        background-color: #fff;
        border-radius: 3px;
        -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
        box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
        margin-top: 24px;
        padding: 10px;
   }
    .thumb-img {
        border-radius: 2px;
        overflow: hidden;
   }
    .gal-detail h4 {
        margin-top: 16px;
        font-size: 16px;
   }
    .counter-number {
        font-size: 32px;
        font-weight: 700;
        color: #343a40;
   }
    .counter-number span {
        font-size: 15px;
        font-weight: 400;
        display: block;
        color: #98a6ad;
   }
    .coming-box {
        float: left;
        width: 25%;
   }
    </style><div class="row">
    <div class="col-12">
        <div class="card-box">
            <div class="row">
                <div class="col-12">
                    <div class="p-2">
                        <form class="form-horizontal" role="form">
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-fileinput">Profile Picture</label>
                                <div class="col-md-10">
                                    <div >
                                        <img src="../../assets/images/user-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle img-thumbnail avatar-md">&nbsp;
                                        <input type="file" />
                                      </div>

                                    <!-- <input type="file" class="form-control" id="example-fileinput"> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="simpleinput">User ID<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="text" id="simpleinput" class="form-control" value="Some text value...">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-placeholder">Username<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label">Nationality<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <select class="form-control">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-date">Date of Birth<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input class="form-control" id="example-date" type="date" name="date">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-placeholder">Contact Number<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-placeholder">Position<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-placeholder">Role<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="text" id="example-placeholder" class="form-control" placeholder="placeholder">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-email">Email<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="email" id="example-email" name="example-email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-2 col-form-label" for="example-password">Password<span style="color:#ff0000">*</span></label>
                                <div class="col-md-10">
                                    <input type="password" id="example-password" class="form-control" value="password">
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
            <!-- end row -->

        </div> <!-- end card-box -->
    </div><!-- end col -->
</div>
<!-- end row -->

