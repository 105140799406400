<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;">
        <h2 class="title" style="margin-top: 10px;">Bottled Honey
            Quality Inspection</h2>

        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span><br>

        <div style="padding: 0px 5vh;text-align: center;">
            <span *ngIf="current_ticket.page_10_section_1" class="batch_title">
                    {{current_ticket.page_10_section_1}}
                    <br><br>
                    {{current_ticket.page_10_section_2}}
            </span>
            <span *ngIf="!current_ticket.page_10_section_1" class="batch_title">

                Upon receiving the honey, we perform quality checks to ensure that this honey meeting the international
                standards of pure honey.
                <br><br>
                Shown below the analysis of all quality parameters upon receiving the honey and after bottling:</span>
        </div>

        <div class="container" style="height: 999px;padding: 20px;cursor: pointer;" (click)="openModal()">
            <div class="row">
                <div class="col-12">
                    <table aria-label="TABLE" class="table table-bordered">
                        <thead role="rowgroup">
                            <tr role="row">
                                <th role="columnheader" scope="col" tabindex="0"> Parameter </th>
                                <th role="columnheader" scope="col" tabindex="0"> received Value
                                </th>
                                <th role="columnheader" scope="col" tabindex="0">bottled value
                                </th>
                                <th role="columnheader" scope="col" tabindex="0">Standard </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup" *ngIf="current_ticket">
                            <tr role="row">
                                <td>HMF</td>
                                <td>{{current_ticket.hmf}}</td>
                                <td>33</td>
                                <td>&lt; 80</td>
                            </tr>
                            <tr role="row">
                                <td>Moisture</td>
                                <td>{{current_ticket.moister}}%</td>
                                <td>18.8%</td>
                                <td>18.5% - 19.5%</td>
                            </tr>
                            <tr role="row">
                                <td colspan="4">View more...</td> 
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>