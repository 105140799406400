import { appBaseUrl } from "./api-contant";

export class ApiUrl {
    static login(): string {
        return `${appBaseUrl}/auth/login`;
    }
    static initiate_collectables(id): string {
        return `${appBaseUrl}/tickets/init/collectables/${id}`;
    }
    static initiate_ticket(id): string {
        return `${appBaseUrl}/tickets/init/collector/${id}`;
    }
    static set_cert(): string {
        return `${appBaseUrl}/tickets/update/set_cert`;
    }
    static rem_cert(): string {
        return `${appBaseUrl}/tickets/update/rem_cert`;
    }
    static upload_media(ticket_id): string {
        return `${appBaseUrl}/tickets/update/media/${ticket_id}`;
    }
    static upload_media_shipper(ticket_id): string {
        return `${appBaseUrl}/tickets/update/media/shipper/${ticket_id}`;
    }
    static upload_media_inspector(ticket_id): string {
        return `${appBaseUrl}/tickets/update/media/inspector/${ticket_id}`;
    }
    static upload_media_botteling(ticket_id): string {
        return `${appBaseUrl}/tickets/update/media/botteling/${ticket_id}`;
    }
    static upload_profile(user_id): string {
        return `${appBaseUrl}/upload/profile/${user_id}`;
    }  
    static update_profile(user_id): string {
        return `${appBaseUrl}/upload/update/${user_id}`;
    }
    static upload_cert_shipper(ticket_id): string {
        return `${appBaseUrl}/upload/cert/shipper/${ticket_id}`;
    }
    static upload_cert_inspector(ticket_id): string {
        return `${appBaseUrl}/upload/cert/inspector/${ticket_id}`;
    }
    static upload_cert_botteling(ticket_id): string {
        return `${appBaseUrl}/upload/cert/botteling/${ticket_id}`;
    }
    static get_media(ticket_id): string {
        return `${appBaseUrl}/collector/getcerts/${ticket_id}`;
    }
    static get_media_shipper(ticket_id): string {
        return `${appBaseUrl}/shipper/getcerts/${ticket_id}`;
    }
    static get_media_inspector(ticket_id): string {
        return `${appBaseUrl}/inspector/getcerts/${ticket_id}`;
    }
    static get_media_botteling(ticket_id): string {
        return `${appBaseUrl}/botteling/getcerts/${ticket_id}`;
    }
    static del_media(): string {
        return `${appBaseUrl}/collector/delcerts/`;
    }
    static del_media_all(): string {
        return `${appBaseUrl}/all/delcerts/`;
    }
    static initiate_shipment(id, ticket_id): string {
        return `${appBaseUrl}/tickets/init/shipment/${id}/${ticket_id}`;
    }
    static update_collectable() {
        return `${appBaseUrl}/tickets/update/collectables`;
    }
    static update_ticket() {
        return `${appBaseUrl}/tickets/update/ticket`;
    }
    static update_ticket_shipper() {
        return `${appBaseUrl}/tickets/update/ticket/shipper`;
    }
    static update_ticket_inspector() {
        return `${appBaseUrl}/tickets/update/ticket/inspector`;
    }
    static update_ticket_botteling() {
        return `${appBaseUrl}/tickets/update/ticket/botteling`;
    }
    static update_ticket_blockchain(id) {
        return `${appBaseUrl}/tickets/update/init_blockchain/${id}`;
    }
    static update_ticket_blockchain_botteling(id) {
        return `${appBaseUrl}/tickets/update/init_blockchain/botteling/${id}`;
    }
    static update_ticket_real(id) {
        return `${appBaseUrl}/tickets/update/init_blockchain/real/${id}`;
    }
    static update_ticket_blockchain_shipping(id) {
        return `${appBaseUrl}/tickets/update/init_blockchain/shipping/${id}`;
    }
    static update_ticket_blockchain_inspector(id) {
        return `${appBaseUrl}/tickets/update/init_blockchain/inspector/${id}`;
    }
    static suspend(id): string {
        return `${appBaseUrl}/auth/suspend/${id}`;
    }
    static delete(id): string {
        return `${appBaseUrl}/auth/delete/${id}`;
    }
    static delete_collectable(id): string {
        return `${appBaseUrl}/tickets/delete/collectables/${id}`;
    }
    static delete_ticket(id): string {
        return `${appBaseUrl}/tickets/delete/collectors/ticket/${id}`;
    }
    static cancel_ticket(): string {
        return `${appBaseUrl}/tickets/cancel/ticket`;
    }
    static logout(): string {
        return `${appBaseUrl}/auth/logout`;
    }
    static refresh(): string {
        return `${appBaseUrl}/auth/refresh`;
    }
    static mailpasswordlink(): string {
        return `${appBaseUrl}/auth/forgot_password`
    }
    static reset_password(): string {
        return `${appBaseUrl}/auth/forgot_password/reset`
    }
    static register_user(): string {
        return `${appBaseUrl}/register`
    }
    static list_collectables(): string {
        return `${appBaseUrl}/tickets/list/collectables`
    }
    static collect_shipments(): string {
        return `${appBaseUrl}/tickets/collect/collector`
    }
    static collect_inspector(): string {
        return `${appBaseUrl}/tickets/collect/shipper`
    }
    static collect_botteling(): string {
        return `${appBaseUrl}/tickets/collect/botteling`
    }
    static collect_colelctables(): string {
        return `${appBaseUrl}/tickets/collect/collectables`
    }
    static list_collectables_by_id(id): string {
        return `${appBaseUrl}/tickets/list/collectables/ticket/${id}`
    }
    static get_ticket(ticket_id): string {
        return `${appBaseUrl}/tickets/list/ticket/${ticket_id}`
    }
    static list_tickets(): string {
        return `${appBaseUrl}/tickets/list/tickets/collector`
    }
    static list_tickets_no_jwt(): string {
        return `${appBaseUrl}/list/ticket`
    }
    static list_tickets_all(): string {
        return `${appBaseUrl}/tickets/list/tickets`
    }
    static list_tickets_shipper(): string {
        return `${appBaseUrl}/tickets/list/tickets/shipper`
    } static list_tickets_inspector(): string {
        return `${appBaseUrl}/tickets/list/tickets/inspector`
    }
    static list_tickets_inspector_temp(): string {
        return `${appBaseUrl}/tickets/list/tickets/temp/inspector`
    } 
    static list_tickets_inspector_temp_ticket(): string {
        return `${appBaseUrl}/tickets/list/tickets/temp/inspector/ticket`
    }
    static list_tickets_botteling_temp_ticket(): string {
        return `${appBaseUrl}/tickets/list/tickets/temp/botteling/ticket`
    }
    static list_tickets_botteling_temp(): string {
        return `${appBaseUrl}/tickets/list/tickets/temp/botteling`
    }
    static list_tickets_botteling(): string {
        return `${appBaseUrl}/tickets/list/tickets/botteling`
    }
    static list_ticket_final():string{
        return `${appBaseUrl}/tickets/list/ticket/final`
    }
    static list_farmers(): string {
        return `${appBaseUrl}/list/users/farmers`
    }
    static get_profile(): string {
        return `${appBaseUrl}/list/users/get_profile`
    }
    static list_shippers(): string {
        return `${appBaseUrl}/list/users/shippers`
    }
    static list_collectors(): string {
        return `${appBaseUrl}/list/users/collectors`
    }
    static list_User(): string {
        return `${appBaseUrl}/list/users/user`
    }
    static list_inspectors(): string {
        return `${appBaseUrl}/list/users/quality_inspectors`
    }
    static list_botteling(): string {
        return `${appBaseUrl}/list/users/botteling`
    }

    static list_admins(): string {
        return `${appBaseUrl}/list/users/admins`
    }
}  