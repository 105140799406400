import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ErrorHandler, Injectable} from "@angular/core";

@Injectable()
// export class ErrorDataHandler extends ErrorHandler {
export class ErrorDataHandler {
  err: HttpErrorResponse;

  constructor(
    err: HttpErrorResponse
  ) {
    // super();
    this.err = err;
  }
  handleError(errorObjType: any): Observable<never> {
    console.log('enter error handling');
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    let errorObj = {};
    if (this.err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${this.err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('error object is: ', this.err);
      console.log('error object is: ', this.err.error);
      errorMessage = `Server returned code: ${this.err.status}, error message is: ${this.err.message}`;
      console.log('error message is: ', errorMessage);
      if (this.err.status === 500) {
        // this.toastr.error('Contact at office', 'Error 500');
        // errorObj[serverErr] = 'Contact at office';
      } else {
        errorObj = this.errorMapper(this.err.error, errorObjType);
        console.log('error object returned is: ', errorObj);
      }
    }
    console.error('errorMessage returned ', errorMessage);
    return throwError(() => errorObj);
  }
  innerErrorHandler(errors: any): Observable<any> {
    const innerErrorCollector: any = {};
    // errors.forEach(err => {
    //   innerErrorCollector[err] = err[0];
    // });
    for (const value in errors) {
      if (value) {
        innerErrorCollector[value] = errors[value[0]];
      }
    }
    return innerErrorCollector;
  }
  errorMapper(responseError: any, error: any): Observable<any> {
    const errorList = responseError.errors;
    for (const index in errorList) {
      if (errorList[index].message.constructor === Array) {
        error[errorList[index].field] = errorList[index].message[0];
        console.log('error message123');
        console.log(error[errorList[index].field] = errorList[index].message[0]);
        console.log('error message123');

      } else {
        if (errorList[index].message.constructor === Object) {
          console.log('error message 4589');
          error[errorList[index].field] = this.innerErrorHandler(errorList[index].message);
        }
      }
    }
    console.log('error Object');
    console.log(error);
    console.log('error Object');
    return error;
  }
}