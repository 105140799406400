import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass']
})
export class HistoryComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  get_title(): string[] {
    switch (this.route.url) {
      case "/tickets/history":
        return ["Track Tickets", "View all of tickets related data here"]
      case "/tickets/confirmed":
        return ["Confirmed Tickets", "View all of cofirmed tickets data here"]
      case "/tickets/blockchain":
        return ["Blockchain verified Tickets", "View all of verified tickets from blockchain data here"]
      case "/farmer/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }
  }

}
