import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getrole } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-packaging',
  templateUrl: './packaging.component.html',
  styleUrls: ['./packaging.component.sass']
})
export class PackagingComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
    console.log(this.get_title())
  }
  get_title(): string[] {

    if(getrole() === 'admin'){ 
      switch (this.route.url) {
        case "/tickets/inspector":
          return ["Quality Inspection Tickets", "Manage all your Inspecting tickets here"]
        case "/inspector/confirm":
          return ["confirm inspection", "Please clearfully read before collecting as it is irreversible task"]
        case "/inspector/update":
          return ["Add inspection and packaging Information", "Please clearfully read before adding data"]
        case "/inspector/privacy":
          return ["Privacy & policy", "By using the following services you agree to these terms!"]
        default:
          return ["Unknown route", "Wrong url entered!"]
      }
    }
    switch (this.route.url) {
      case "/inspector":
        return ["Tickets collected from shipper", "Manage all your batches here"]
      case "/inspector/confirm":
        return ["confirm inspection", "Please clearfully read before collecting as it is irreversible task"]
      case "/inspector/update":
        return ["Add inspection and packaging Information", "Please clearfully read before adding data"]
      case "/inspector/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }

  }
}