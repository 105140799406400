import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-page-five',
  templateUrl: './page-five.component.html',
  styleUrls: ['./page-five.component.css']
})
export class PageFiveComponent implements OnInit {
  constructor(public appService: AppCoreService) { }

  @Output() modal = new EventEmitter<any[]>();
  @Output() farmers = new EventEmitter<any>();

  collectableData: any[] = []; // Initialize as an empty array
  @Input() current_ticket: any;

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current_ticket']?.currentValue) {
      const collectables = this.current_ticket.collectables_added;

      for (const collectable of collectables) {
        const collectableId = collectable.collectable;
        try {
          this.appService.list_collectables_by_id(collectableId).subscribe(
            (data) => {
              this.collectableData.push(data.data[0]);
            },
            (error) => {
              console.error(`Error fetching data for collectable ${collectableId}: ${error}`);
            }
          );
        } catch (error) {
          console.error(`Error fetching data for collectable ${collectableId}: ${error}`);
        }
      }
      if (this.collectableData) {
        this.farmers.emit(this.collectableData)
      }
    }
  }

  openModal() {
    this.modal.emit([true, 'farmers']);
  }
}