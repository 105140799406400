import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-six',
  templateUrl: './page-six.component.html',
  styleUrls: ['./page-six.component.css']
})
export class PageSixComponent implements OnInit {

  ticket_id: number

  @Input() current_ticket: any;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  @Output() modal = new EventEmitter<any[]>();
  // @Output() modalvalue = new EventEmitter<any>
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['current_ticket'].currentValue) {
      console.log(this.current_ticket, "ticket")
    }
  }
  openModal() {
    this.modal.emit([true, 'analysis'])
  }
}
