import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { getUserId, get_ticket, remove_ticket, set_ticket } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-final',
  templateUrl: './update-final.component.html',
  styleUrls: ['./update-final.component.css']
})
export class UpdateFinalComponent implements OnInit {
  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private router: Router, private appCoreService: AppCoreService, private Toastr: ToastrService, private formBuilder: FormBuilder) { }

  @ViewChild('search') searchElementRef: ElementRef;

  media_to_set: any = [];
  current_vals: any;
  dataform: FormGroup = new FormGroup({});
  // google maps zoom level
  zoom = 8;
  medias: string[]
  //set latitude, longitude and zoom 
  id = getUserId()
  // initial center position for the map 
  disable: boolean = true
  markers: Marker[] = [];
  color = [
    { name: "Water White", value: "Water White" },
    { name: "Extra White", value: "Extra White" },
    { name: "White", value: "White" },
    { name: "Extra Light Amber", value: "Extra Light Amber" },
    { name: "Light Amber", value: "Light Amber" },
    { name: "Amber", value: "Amber" },
    { name: "Dark Amber", value: "Dark Amber" }
  ];

  selectedcolor: any;

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    remove_ticket()
  }

  ngOnInit(): void {
    if (!get_ticket()) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'An error Occurred! please try again later',
        showConfirmButton: false,
        timer: 1000
      }).then(result => {
        this.router.navigateByUrl('/')
      })
    } else {
      this.current_vals = JSON.parse(get_ticket())
      this.selectedcolor = this.current_vals.color
      console.log(this.current_vals)
      this.appCoreService.get_media(this.current_vals.ticket_id).subscribe(data => {
        this.medias = data
      })
      this.initForm()

    }
    setInterval(() => {
      console.log(this.media_to_set)
    }, 1000)
  }

  initForm() {
    if (this.current_vals.appearence === '' || this.current_vals.appearence === null) {
      this.current_vals.appearence = ''
    }
    // hmf | moister | acidity | ph | color | electrical_cunductivity | diastate
    this.dataform = this.formBuilder.group({
      hmf: new FormControl(this.current_vals.hmf, [Validators.required]),
      moister: new FormControl(this.current_vals.moister, [Validators.required]),
      ph: new FormControl(this.current_vals.ph, [Validators.required]),
      diastate: new FormControl(this.current_vals.diastate, [Validators.required]),
      fg_ratio: new FormControl(this.current_vals.fg_ratio, [Validators.required]),
      invert_sugar: new FormControl(this.current_vals.invert_sugar, [Validators.required]),
      suctose: new FormControl(this.current_vals.suctose, [Validators.required]),
      ash: new FormControl(this.current_vals.ash, [Validators.required]),
      appearence: new FormControl(this.current_vals.appearence, [Validators.required]),  // Replace with appropriate value or variable
      cleanliness: new FormControl(this.current_vals.cleanliness, [Validators.required]),  // Replace with appropriate value or variable
      odder: new FormControl(this.current_vals.odder, [Validators.required]),  // Replace with appropriate value or variable
      taste: new FormControl(this.current_vals.taste, [Validators.required]),  // Replace with appropriate value or variable
      fermentation: new FormControl(this.current_vals.fermentation, [Validators.required]), // Replace with appropriate value or variable
      lot_no: new FormControl(this.current_vals.lot_no, [Validators.required]),  // Replace with appropriate value or variable
      batch_no: new FormControl(this.current_vals.batch_no, [Validators.required]),  // Replace with appropriate value or variable
      batch_bottles: new FormControl(this.current_vals.batch_bottles, [Validators.required]),  // Replace with appropriate value or variable
      page_1_section_1: new FormControl(this.current_vals.page_1_section_1 ? this.current_vals.page_1_section_1 : 'Encourage all generations to enjoy the benefits of honey while taking care of the well-being of the planet starting with the bee’s ecosystem', [Validators.required]),  // Replace with appropriate value or variable
      page_3_section_1: new FormControl(this.current_vals.page_3_section_1 ? this.current_vals.page_3_section_1 : 'Al-Shifa is bringing you this honey from the tropical west coast area of Romania, from the state of Oaxaca, where honeybees collected the nectar from flowers growing on mountains rises up to 3000 meters altitude  above the see level. The bees continue to collect the sweet nectar from the flowers all the way to the top and produce this unique type of honey.', [Validators.required]), // Replace with appropriate value or variable
      page_5_section_1: new FormControl(this.current_vals.page_5_section_1 ? this.current_vals.page_5_section_1 : 'This bottle of honey comes from several beekeepers and the honey collected was homogenized in one lot  to ensure every bottle has honey with uniform content', [Validators.required]),  // Replace with appropriate value or variable
      page_6_section_1: new FormControl(this.current_vals.page_6_section_1 ? this.current_vals.page_6_section_1 : 'Previous lots have been collected, tested for authenticity then released for homogenization.', [Validators.required]), // Replace with appropriate value or variable
      page_9_section_1: new FormControl(this.current_vals.page_9_section_1 ? this.current_vals.page_9_section_1 : 'After receiving the collected honey at our plant, we fill it and label it with ALSHIFA labels', [Validators.required]),  // Replace with appropriate value or variable
      page_9_section_2: new FormControl(this.current_vals.page_9_section_2 ? this.current_vals.page_9_section_2 : 'Our Plant has been awarded the below certificates for the high-quality standards we apply', [Validators.required]),  // Replace with appropriate value or variable
      page_10_section_1: new FormControl(this.current_vals.page_10_section_1 ? this.current_vals.page_10_section_1 : 'Upon receiving the honey, we perform quality checks to ensure that this honey meeting the international standards of pure honey.', [Validators.required]),  // Replace with appropriate value or variable
      page_10_section_2: new FormControl(this.current_vals.page_10_section_2 ? this.current_vals.page_10_section_2 : 'Shown below the analysis of all quality parameters upon receiving the honey and after bottling:', [Validators.required]),  // Replace with appropriate value or variable
    });
  }
  set_media(m) {
    if (this.media_to_set.length >= 4 && !this.media_to_set.includes(m)) {
      this.Toastr.error('Only upto 4 images can be selected')
    }
    else if (this.media_to_set.includes(m)) {
      let index = this.media_to_set.indexOf(m)
      this.media_to_set.splice(index, 1);
    } else {
      this.media_to_set.push(m)
    }
  }
  update() {
    Swal.showLoading()
    console.log(this.dataform, "Validation")
    console.log({ ticket_id: this.current_vals.ticket_id, "updates": this.dataform.value })
    if (this.dataform.valid && this.selectedcolor) {
      if (this.media_to_set.length < 4) {
        this.Toastr.error('please select atleast 4 images as media')
        Swal.close()
      } else {
        this.appCoreService.update_ticket({ ticket_id: this.current_vals.ticket_id, "updates": { ...this.dataform.value, ...{ color: this.selectedcolor.name, media_used: String(this.media_to_set) } } }).subscribe(data => {
          Swal.close()
          if (data.status === 200) {
            Swal.fire({
              title: 'Ticket Updated successfully!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok!'
            }).then((result) => {
              if (result.value) {
                this.router.navigateByUrl('/tickets/history')
              } else {
                setTimeout(() => {
                  this.router.navigateByUrl('/tickets/history')
                }, 500);
              }
            })
          } else {
            Swal.close()
            this.Toastr.error(data.message)
          }
        })
      }
    }
    else {

      console.log(this.dataform.value);
      if (!this.selectedcolor) {
        const errorMessage = `Please complete the following fields: Color`;
        Swal.close()
        this.Toastr.error(errorMessage);

      }
      // Find the invalid fields
      const invalidFields = [];
      for (const field in this.dataform.controls) {
        if (this.dataform.controls[field].invalid) {
          invalidFields.push(field);
        }
      }

      const errorMessage = `Please complete the following fields: ${invalidFields.join(", ")}`;
      Swal.close()

      this.Toastr.error(errorMessage);

    }
  }

}

// just an interface for type safety.
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
