import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AppCoreService } from 'src/app/services/app-core.service';
import { faEdit, faImages, faLock, faPlus, faTrash, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { getUserId, get_ticket_id, getrole, set_ticket, set_ticket_id } from 'src/app/utils/preserved-storage';
import { DatePipe } from '@angular/common';
import { faHive } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-list-botteling',
  templateUrl: './list-botteling.component.html',
  styleUrls: ['./list-botteling.component.css']
})
export class ListBottelingComponent implements OnInit, AfterViewInit {

  edit = faEdit
  blockchain = faLock
  add = faPlus
  temp_id: string;
  data: any[];
  displayedColumns = ['ticket_id', 'farmer_id', 'collector_id', 'honey_origin', 'honey_weight', 'honey_kind', "updated", 'status', "edit"];
  dataSource2: MatTableDataSource<any>;

  closed: boolean = false;
  current_ticket: any
  medias: any[]
  img_ticket: any
  add_media = faImages

  dialog: boolean = false
  dialog_2: boolean = false
  files: { name: string; preview: string }[] = [];

  current_pdf: string = ''

  img_id: string

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private datePipe: DatePipe, private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService) { }

  table_status: string = 'Loading...';
  role: string = getrole();
  id: number = getUserId()
  delete = faTrash
  media = faImages
  ngOnInit() {
    this.get_tickets();
  }

  showReason(reason) {
    Swal.fire({
      text: reason,
    })
  }
  get_tickets() {
    if (this.role.includes('admin')) {
      this.appCoreService.list_tickets_botteling().subscribe(result => {
        if (result.data?.length) {
          this.table_status = ''
          this.data = result.data
          this.data = this.data.sort((a, b) => a.status.localeCompare(b.status));
          this.dataSource2 = new MatTableDataSource(this.data)
          this.dataSource2.paginator = this.paginator;
        }
        else {
          this.table_status = 'No tickets initiated yet!'
          console.log(this.table_status)
        }
      })
    }
    else {
      this.appCoreService.list_tickets_botteling_temp(this.id.toString()).subscribe(result => {
        if (result.data?.length) {
          console.log(this.data)
          this.table_status = ''
          this.data = result.data
          this.data.sort((a: any, b: any) => {
            const dateA = new Date(a.updated);
            const dateB = new Date(b.updated);
            return dateB.getTime() - dateA.getTime();
          });

          this.dataSource2 = new MatTableDataSource(this.data)
          this.dataSource2.paginator = this.paginator;
        }
        else {
          this.table_status = 'No tickets initiated yet!'
          console.log(this.table_status)
        }

      })
    }

  }
  formattedTimestamp(stamp): string {
    const formattedDate = this.datePipe.transform(stamp, 'medium');
    return formattedDate || '';
  }

  validateCode(code) {
    const regex = /^[A-Z0-9]{7}$/;
    return regex.test(code);
  };

  initiate_ticket() {
    Swal.fire({
      title: 'Enter ticket number',
      input: 'text',
      inputAttributes: {
      },
      inputLabel: "Inspector ticket number",
      showCancelButton: false,
      confirmButtonText: "Look up",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      inputValidator: (value) => {
        if (!this.validateCode(value)) {
          set_ticket_id(value)
          return 'Invalid Ticket Id!'
        }
      },
      preConfirm: (login) => {
        this.closed = true
        this.appCoreService.get_ticket(login).subscribe(data => {
          this.current_ticket = data
          set_ticket(JSON.stringify(data))
        })
      },
      didClose: () => {
        if (!this.closed) {
          console.log("hi")
          this.router.navigateByUrl('/botteling')
        } else {
          Swal.showLoading()
          this.closed = false
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          Swal.close()
          if (this.current_ticket.ticket_id) {
            if (!this.current_ticket.hmf) {
              Swal.fire({
                icon: 'error',
                title: `not a valid ticket!`
              })
              this.router.navigateByUrl('botteling/')
            } else {
              Swal.fire({
                icon: 'success',
                title: `Retrieved ticket successfully`
              })
              this.router.navigateByUrl('botteling/confirm')
            }
          } else if (this.current_ticket.status === 404) {
            Swal.fire({
              icon: 'error',
              title: `Ticket does not exist!`
            }).then(() => this.get_tickets())
          } else {
            Swal.fire({
              icon: 'error',
              title: `Undefined error occured! please try later`
            }).then(() => this.get_tickets())
          }
        }, 2000);
      } else if (result.isDenied) {
        Swal.fire({
          title: "Please Enter valid ticket!",
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "retry",
          cancelButtonColor: '#880000',
          cancelButtonText: "go back"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.close()
            this.get_tickets()
          } else {
            Swal.close()
            this.router.navigateByUrl('/botteling')
          }
        })
      }
    })

  }
  copy(value) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
    this.toastr.success('ticket Id copied!')
  }

  applyFilter(Value: any) {
    let filterValue = Value.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }
  update_current_ticket(el) {
    if (el.cancelled) {
      this.showReason(el.cancelled_reason)
    } else {
      set_ticket(JSON.stringify(el))
      this.router.navigateByUrl('/botteling/update')
    }
  }
  ngAfterViewInit(): void {

  }
  go_blockchain_botteling(ticket_id) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You won\'t be able to alter the batch information anymore',
      showCancelButton: true,
      cancelButtonColor: "#880000"
    }).then(data => {
      if (data.isConfirmed) {
        this.appCoreService.update_blockchain_botteling(ticket_id).subscribe(data => {
          if (data.status === 200) {
            Swal.fire({
              title: data.message,
              icon: 'success'
            })
            this.get_tickets()
          } else {
            Swal.fire({
              title: data.message,
              icon: 'error'
            })
          }

        })
      } else {
        this.toastr.info("canceled blockchain initiation")
      }
    })

  }
  go_blockchain_real(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You won\'t be able to alter the batch information anymore',
      showCancelButton: true,
      cancelButtonColor: "#880000"
    }).then(data => {
      if (data.isConfirmed) {
        this.appCoreService.update_blockchain_real(id).subscribe(data => {
          if (data.status === 200) {
            Swal.fire({
              title: data.message,
              icon: 'success'
            })
            this.get_tickets()
          } else {
            Swal.fire({
              title: data.message,
              icon: 'error'
            })
          }

        })
      } else {
        this.toastr.info("canceled blockchain initiation")
      }
    })
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    Swal.showLoading()

    if (file) {
      // Check if the selected file is of an allowed file type
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (allowedTypes.includes(file.type)) {
        // Create FormData object
        const formData = new FormData();
        formData.append('file', file);

        // Make the POST request to the API
        this.appCoreService.upload_cert_botteling(this.temp_id, formData).subscribe(
          (response) => {
            console.log('File uploaded successfully.');
            if (response.status === 200)
              Swal.fire({
                icon: "success",
                title: 'Certificate Uploaded successfully!'
              }).then(result => {
                if (result) {
                  window.location.reload()
                }
              })
            console.log('Response:', response);
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: 'Certificate Uploading failed. please try again later!'
            })
            console.error('File upload failed.');
            console.error('Error:', error);
          }
        );
      } else {
        console.error('Invalid file type. Please select a PNG, JPEG, or JPG file.');
      }
    }
  }

  // Function to generate a random alphanumeric string
  generateUniqueId(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uniqueId = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }
    return uniqueId;
  }

  onFileChange(pFileList: File[]) {
    const maxFiles = 15;

    if (this.files.length + pFileList.length > maxFiles) {
      this.toastr.error(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }

    for (const file of pFileList) {
      // Generate a unique random digit
      const uniqueDigit = this.generateUniqueId(10);
      // Check file size (in bytes) and allowed file types
      if (file.size <= 5 * 1024 * 1024 && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'application/pdf')) {
        // Read the file as a data URL to display as an image preview
        const reader = new FileReader();
        reader.onload = (event) => {
          const filePreview = event.target.result as string;
          this.files.push({ name: `${uniqueDigit}`, preview: filePreview });
          this.toastr.success('Successfully uploaded: ' + file.name);
        };
        reader.readAsDataURL(file);
      } else {
        this.toastr.error(`Invalid file or file size too large to upload: ${file.name}`);
      }
    }
  }

  select_certificate(i) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      confirmButtonText: "Use this file as certificate?",

      cancelButtonText: "cancel",
      showCancelButton: true,
      cancelButtonColor: '#990000'
    }).then(result => {
      if (result.isConfirmed) {
        const data = { cert: 'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/' + i, ticket_id: this.img_id, role: this.role }
        this.appCoreService.setcert(data).subscribe(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: res.message
            })
            this.enable_dialog('', '')
            this.get_tickets()
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.message
            })
          }
        })
      } else {
        this.toastr.error('cancelled')
      }
    })
  }

  remove_certificate(i) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      confirmButtonText: "Remove this file as certificate?",
      cancelButtonText: "cancel",
      showCancelButton: true,
      cancelButtonColor: '#990000'
    }).then(result => {
      if (result.isConfirmed) {
        const data = { cert: 'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/' + i, ticket_id: this.img_id, role: this.role }
        this.appCoreService.remcert(data).subscribe(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: res.message
            })
            this.enable_dialog('', '')
            this.get_tickets()
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.message
            })
          }
        })
      } else {
        this.toastr.error('cancelled')
      }
    })
  }

  showimg(url) {
    Swal.fire({
      imageUrl: url,
      imageAlt: 'Collecter ticket media'
    }
    )
  }

  del_certificate(i) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      confirmButtonText: "Yes! Delete this media file?",
      cancelButtonText: "cancel",
      showCancelButton: true,
      cancelButtonColor: '#990000'
    }).then(result => {
      if (result.isConfirmed) {
        this.appCoreService.del_media(this.img_id, i,this.role).subscribe(res => {
          if (res === 'done') {
            Swal.fire({
              icon: "success",
              title: res.message
            })
            this.enable_dialog('', '')
            this.get_tickets()
          }
          else {
            Swal.fire({
              icon: "error",
              title: res.message
            })
          }
        })
      } else {
        this.toastr.error('cancelled')
      }
    })
  }

  enable_dialog(id, ticket) {
    this.img_ticket = ticket
    console.log(ticket)
    this.img_id = id
    this.dialog = !this.dialog
  }

  enable_dialog_2(id, ticket, media) {
    this.dialog_2 = !this.dialog_2
    this.current_pdf = media
  }

  add_media_fun() {
    const formData = new FormData();

    Swal.showLoading()
    for (const file of this.files) {
      console.log(file.name)
      // Create a Blob from the file's data URL
      const blob = this.dataURLtoBlob(file.preview); 
      console.log("blob::: ", blob + blob.type);

      if (blob.type.includes('pdf')) {
        formData.append(`files`, blob, `${file.name}.pdf`);
      }else if(blob.type.includes('jpg')){
        formData.append(`files`, blob, `${file.name}.jpg`);
      }
      else {
        formData.append(`files`, blob, `${file.name}.png`);
      }
    }

    // Include the ticket_id in the function call
    this.appCoreService.upload_media_botteling(this.img_id, formData).subscribe(
      (response) => {
        // Handle the success response from the server.
        this.dialog = false

        Swal.close()
        Swal.fire({
          text: response.message
        })
        // Optionally, clear the uploaded files list or perform other actions.
        this.files = [];
      },
      (error) => {
        // Handle the error response from the server.
        console.error(error);
        this.files = []
        this.dialog = false
      }
    );
  }
  // Function to convert a data URL to Blob
  dataURLtoBlob(dataURL: string): Blob {
    const parts = dataURL.split(',');
    const contentType = parts[0].match(/:(.*?);/)[1];
    const byteCharacters = atob(parts[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  deleteFile(index) {
    this.files.splice(index, 1);
    this.toastr.success('Successfully deleted!');
  }

  addrow(id) {
    this.medias = []
    this.appCoreService.get_media_botteling(id).subscribe(data => {
      this.medias = data
    })
  }
}
