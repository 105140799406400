import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[disableMousewheel]'
})
export class DisableMousewheelDirective {
  @HostListener('mousewheel', ['$event'])
  onMousewheel(event: Event): void {
    event.preventDefault();
  }
}