import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AppCoreService } from 'src/app/services/app-core.service';
import { faEdit, faTrash, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { getUserId, getrole, setcollectable } from 'src/app/utils/preserved-storage';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-tickets',
  templateUrl: './list-tickets.component.html',
  styleUrls: ['./list-tickets.component.css']
})
export class ListTicketsComponent implements OnInit, AfterViewInit {

  edit = faEdit
  data: any[];
  displayedColumns = ['ticket_id', 'farmer_id', 'honey_origin', 'honey_weight', 'honey_kind', 'timestamp', 'status', "edit"];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService, private datePipe: DatePipe) { }

  table_status: string = 'Loading...';
  role: string = getrole();
  id: number = getUserId()
  delete = faTrash
  ngOnInit() {
    this.get_collectables();
  }

  formattedTimestamp(stamp): string {
    const formattedDate = this.datePipe.transform(stamp, 'medium');
    return formattedDate || '';
  }
  get_collectables() {
    if (this.role.includes('admin')) {
      this.appCoreService.list_collectables().subscribe(result => {
        if (result.data?.length) {
          this.table_status = ''
          this.data = result.data
          this.data.sort((a: any, b: any) => {
            const dateA = new Date(a.updated);
            const dateB = new Date(b.updated);
            return dateB.getTime() - dateA.getTime();
          });
          this.dataSource2 = new MatTableDataSource(this.data)
          this.dataSource2.paginator = this.paginator;
        }
        else {
          this.table_status = 'No Tickets Initiated yet!'
          console.log(this.table_status)
        }

      })
    }
    else {
      this.appCoreService.list_collectables(this.id.toString()).subscribe(result => {
        if (result.data?.length) {
          this.table_status = ''
          this.data = result.data
          this.data.sort((a: any, b: any) => {
            const dateA = new Date(a.updated);
            const dateB = new Date(b.updated);
            return dateB.getTime() - dateA.getTime();
          });
          this.dataSource2 = new MatTableDataSource(this.data)
          this.dataSource2.paginator = this.paginator;
        }
        else {
          this.table_status = 'No Tickets Initiated yet!'
          console.log(this.table_status)
        }

      })
    }

  }
  delete_collectables(id) {
    Swal.fire(
      {
        title: 'Are you sure?',
        text: 'You wont be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete ticket!'
      }).then((result) => {
        if (result.value) {
          this.appCoreService.delete_collectable(id).subscribe(data => {
            if (data.status === 200) {
              this.toastr.success("ticket deleted changed Successfully")
              this.get_collectables()
            } else if (data.status === 401) {
              console.log('this action is not allowed by the user role farmer')
              this.toastr.success("user suspended Successfully")
            } else {
              this.toastr.error(data.message)
            }
          })
        }
      }
      )
  }
  copy(value) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(value);
    this.toastr.success('ticket Id copied!')
  }

  applyFilter(Value: any) {
    let filterValue = Value.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }
  update_collectable(el) {
    if (!el.farmer_id) {
      Swal.fire({
        title: "Error! Invalid ticket",
        text: 'Please contact the admins or try again later!',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
    else {
      setcollectable(JSON.stringify(el))
      if(this.role === 'admin'){
        this.router.navigateByUrl('tickets/farmer/update-collectable')
      }else{
        this.router.navigateByUrl('/farmer/update-collectable')
      }
    }
  }
  ngAfterViewInit(): void {

  }
}

