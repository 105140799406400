import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { faShoppingCart, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.css']
})
export class PageOneComponent implements OnInit {

  @Output() curr_slider = new EventEmitter<number>();
  marker = faMapMarkedAlt
  cart = faShoppingCart
  ticket_id: number

  @Input() batch: string;
  @Input() ticket: any;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      const id = params['ticket_id']; // Replace 'param1' with the name of your parameter 
      this.ticket_id = id
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticket'].currentValue) {
      console.log(this.ticket.page_1_section_1, "ticket one")
    }
  }

  slide() {
    this.curr_slider.emit(1);
  }
  ngOnInit(): void {
      console.log(typeof(this.ticket.page_1_section_1),'section 1')
  }

}
