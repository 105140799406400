import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppCoreService } from 'src/app/services/app-core.service';
import { getUserId, get_ticket } from 'src/app/utils/preserved-storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-packaging',
  templateUrl: './confirm-packaging.component.html',
  styleUrls: ['./confirm-packaging.component.css']
})
export class ConfirmPackagingComponent implements OnInit {

  constructor(private appCoreService: AppCoreService, private router: Router, private toastr: ToastrService, private Activated_route: ActivatedRoute) { }

  closed: boolean = false;
  ticket: any

  ngOnInit(): void {
    this.ticket = get_ticket()
    this.ticket = JSON.parse(this.ticket)
  }

  validateCode(code) {
    const regex = /^[A-Z0-9]{7}$/;
    return regex.test(code);
  };

  collect() {
    this.appCoreService.collect_inspector({ inspector_id: getUserId(), ticket_id: this.ticket.ticket_id }).subscribe((data) => {
      if (data.status === 200) {
        Swal.fire({
          title: data.message,
          icon: 'success'
        }).then(() =>
          this.router.navigateByUrl('/inspector')
        )
      } else {
        Swal.fire({
          title: data.message,
          icon: 'error'
        })
      }
    })
  }
}
