<style>
    .form-group {
        margin-top: 10px;
    }
</style>

<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container bootstrap snippets bootdeys">
    <div class="row"
        style="display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 5vh;">
        <div class="col-xs-12 col-sm-9">
            <form class="form-horizontal" [formGroup]="loginform" (ngSubmit)="onsubmit()">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">User info</h4>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <label class="col-sm-2 control-label">User name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" formControlName="username"
                                    [placeholder]="profile.username?profile.username:'Enter your username'" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">Full name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" formControlName="fullname"
                                    [placeholder]="profile.fullname?profile.fullname:'Enter your fullname'">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default" style="margin-top: 10px;">
                    <div class="panel-heading">
                        <h4 class="panel-title">Contact info</h4>
                    </div>
                    <div class="panel-body">
                        <div class="form-group">
                            <label class="col-sm-2 control-label">Company Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" formControlName="company"
                                    [placeholder]="profile.company?profile.company:'Enter your profile'">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">Mobile number</label>
                            <div class="col-sm-10">
                                <input type="tel" class="form-control" formControlName="phone"
                                    [placeholder]="profile.phone?profile.phone:'Enter your phone'">
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">E-mail address</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control" formControlName="email"
                                    [placeholder]="profile.email?profile.email:'Enter your E-mail'" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-2 control-label">Description</label>
                            <div class="col-sm-10">
                                <textarea rows="3" class="form-control" formControlName="description"
                                    [placeholder]="profile.description?profile.description:'Enter description'"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" style="margin-top: 10px;">
                    <div class="col-sm-10 col-sm-offset-2">
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <button type="reset" class="btn btn-default" (click)="goback()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>