import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-three',
  templateUrl: './page-three.component.html',
  styleUrls: ['./page-three.component.css']
})
export class PageThreeComponent implements OnInit {

  image: boolean = false
  @Input() ticket: any
  constructor(private route: ActivatedRoute) {
  }

  ngAfterViewInit() {
    if (this.ticket.honey_kind === 'Acacia' ||
     this.ticket.honey_kind === 'Manuka' ||
     this.ticket.honey_kind === 'Black Forest' || 
     this.ticket.honey_kind === 'Pine' || 
     this.ticket.honey_kind === 'Sidr') {
        this.image = true
    }
  }
  ngOnInit(): void {
  }

}
