<div class="fluid_container">
    <div class="logo">
        <img class="logo_img" src="../../../../assets/images/Alshifa_Logo.png" alt="logo">
        <img class="devider" src="../../../../assets/images/Devider.png" alt="devider">
    </div>
    <div style="text-align: center;" *ngIf="current_ticket">
        <h2 class="title">{{current_ticket.honey_origin}}</h2>
        <span class="batch_title">Batch Number</span><br>
        <span class="batch">{{current_ticket.batch_no}}</span>
    </div>
    <div style="text-align: center;" *ngIf="!current_ticket">
        <h2 class="title">NA</h2>
        <span class="batch_title">Batch Number</span><br>
        <span class="batch">NA</span>
    </div>

    <div *ngIf="current_ticket">
        <agm-map style="height: 50vh;" fullscreenControl="true" [latitude]="lat" [longitude]="lng" [zoom]="zoom"
            [disableDefaultUI]="false" #map [zoomControl]="true" streetViewControlOptions="false"
            (mapReady)="mapReady($event)" class="diable-x">

            <agm-marker (markerClick)="slide(10)" [latitude]="lat" [longitude]="lng" [markerDraggable]="false"
                iconUrl="../../../../assets/images/leaflet/icon.png">

                <agm-info-window isOpen="true">
                    <strong>Quality Inspection</strong>
                </agm-info-window>

            </agm-marker>
            <agm-marker (markerClick)="slide(2)" [latitude]="latA" [longitude]="lngA" [markerDraggable]="false"
                iconUrl="../../../../assets/images/leaflet/icon.png">

                <agm-info-window isOpen="true">
                    <strong>Honey Origin</strong>
                </agm-info-window>

            </agm-marker>
            <agm-marker (markerClick)="slide(4)" [latitude]="latb" [longitude]="lngb" [markerDraggable]="false"
                iconUrl="../../../../assets/images/leaflet/icon.png">

                <agm-info-window isOpen="true">
                    <strong>Collection center</strong>
                </agm-info-window>

            </agm-marker>
            <agm-polyline class="poly" [geodesic]="true" [strokeColor]="'rgba(00,00,00,0)'">

                <agm-polyline-point [latitude]="latA" [longitude]="lngA">
                </agm-polyline-point>
                <agm-polyline-point [latitude]="latb" [longitude]="lngb">
                </agm-polyline-point>
                <agm-polyline-point [latitude]="lat" [longitude]="lng">
                </agm-polyline-point>

                <agm-icon-sequence [strokeColor]="'#f80000'" [repeat]="'10px'" [offset]="0" [strokeWeight]="4"
                    [scale]="2" [strokeOpacity]="1" [path]="'M 0,-1 0,1'">
                </agm-icon-sequence>
            </agm-polyline>
            <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" [radius]="5000" [fillColor]="'red'"
[circleDraggable]="true" [editable]="true">
</agm-circle> -->

        </agm-map>
    </div>
</div>