<style>
  .ham {
    background-color: #987b59 !important
  }

  .ham::before {
    background-color: #987b59 !important
  }

  .ham::after {
    background-color: #987b59 !important
  }

  .w-auto {
    width: auto !important
  }

  .w-min {
    width: 100px !important;
  }
</style>
<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()">
  <div class="app-header__logo">
    <a routerLink="">
      <div style="width: auto;">
        <app-logo [sidebar]="globals.toggleSidebar"></app-logo>
      </div>
    </a>
    <div class="header__pane ms-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
        <span class="hamburger-box">
          <span class="hamburger-inner ham"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config" [scrollIndicators]="true"
        style="max-width: 600px; max-height: 100vh;">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion"
              activeIds="{{extraParameter}}">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header" style="color: #6a5c3e !important;">Main Navigation</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards" *ngIf="role === 'admin'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-config"></i>
                    <span class="vsm-title">Users</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/farmers" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Beekeepers</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/collectors" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Collectors</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/shippers" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Shippers</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/inspectors" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Quality Inspectors</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/botteling" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Bottling</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/users/admins" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Admins</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/register" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Add New User</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards" *ngIf="role === 'farmer'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ticket"></i>
                    <span class="vsm-title">Tickets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/farmer" routerLinkActive="active-item" [routerLinkActiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">My tickets</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/farmer/init-collectable" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Initiate ticket</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="profile" *ngIf="role === 'farmer'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">My profile</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="setUser_farmer()" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Update profile</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/forget_password" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Change password</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/farmer/privacy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">privacy policy</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="logout()" routerLinkActive="active-item" [routerLinkIctiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards" *ngIf="role === 'collector'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ticket"></i>
                    <span class="vsm-title">Tickets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/collector" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">My tickets</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="profile" *ngIf="role === 'collector'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">My profile</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="setUser_collector()" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Update profile</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/forget_password" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Change password</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/collector/privacy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">privacy policy</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="logout()" routerLinkActive="active-item" [routerLinkIctiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards" *ngIf="role === 'shipper'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ticket"></i>
                    <span class="vsm-title">Tickets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/shipper" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">My tickets</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="profile" *ngIf="role === 'shipper'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">My profile</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="setUser_shipper()" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Update profile</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/forget_password" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Change password</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/shipper/privacy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">privacy policy</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="logout()" routerLinkActive="active-item" [routerLinkIctiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> <ngb-panel id="dashboards" *ngIf="role === 'botteling'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ticket"></i>
                    <span class="vsm-title">Tickets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/botteling" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">My tickets</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="profile" *ngIf="role === 'botteling'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">My profile</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="setUser_botteling()" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Update profile</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/forget_password" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Change password</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/botteling/privacy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">privacy policy</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="logout()" routerLinkActive="active-item" [routerLinkIctiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboards" *ngIf="role === 'inspector'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-ticket"></i>
                    <span class="vsm-title">Tickets</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/inspector" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">My tickets</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="profile" *ngIf="role === 'inspector'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-user"></i>
                    <span class="vsm-title">My profile</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="setUser_quality()" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Update profile</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/forget_password" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">Change password</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLink="/inspector/privacy" routerLinkActive="active-item"
                          [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                          <span class="vsm-title">privacy policy</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a (click)="logout()" routerLinkActive="active-item" [routerLinkIctiveOptions]="{exact: true}"
                          class="vsm-link">
                          <span class="vsm-title">Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
          <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" #acc="ngbAccordion"
            activeIds="{{extraParameter}}">
            <ngb-panel id="tickets" *ngIf="role === 'admin'">
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="vsm-link">
                  <i class="vsm-icon pe-7s-ticket"></i>
                  <span class="vsm-title">Tickets</span>
                  <i class="vsm-arrow"></i>
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/tickets/history" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Track Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/tickets/confirmed" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Confirmed Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/tickets/blockchain" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Blockchain Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/get_qr" class="vsm-link" routerLinkActive="active-item">
                        <span class="vsm-title">Get QR Code </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/tickets/farmer" class="vsm-link" routerLinkActive="active-item">
                        <span class="vsm-title">Beekeeper Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="tickets/collector" routerLinkActive="active-item" class="vsm-link">
                        <span class="vsm-title">Collector Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="tickets/shipper" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Shipper Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="tickets/inspector" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Quality Inspection Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="tickets/botteling" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Bottling Tickets</span>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div class="vsm-dropdown">
                  <div class="vsm-list">
                    <div class="vsm-item">
                      <a routerLink="/farmer/#" routerLinkActive="active-item"
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link">
                        <span class="vsm-title">Coming soon...</span>
                      </a>
                    </div>
                  </div>
                </div> -->
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>