import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getrole } from 'src/app/utils/preserved-storage';

@Component({
  selector: 'app-collector',
  templateUrl: './collector.component.html',
  styleUrls: ['./collector.component.sass']
})
export class CollectorComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
    console.log(this.get_title())
  }

  get_title(): string[] {

    if (getrole() === 'admin') {
      switch (this.route.url) {
        case "/tickets/collector":
          return ["Collector Tickets", "Manage all your Collector tickets here"]
        case "/tickets/collector/init-collector":
          return ["Initiate tickets", "Generate new tickets here"]
        case "/tickets/collector/collect-collectable":
          return ["Collect honey", "Start Collecting honey from farmers here"]
        case "/tickets/collector/add":
          return ["Collect honey", "collect more honey batches to your ticket here"]
        case "/tickets/collector/update":
          return ["Add honey Information", "Add honey Information to your ticket here"]
        case "/tickets/collector/privacy":
          return ["Privacy & policy", "By using the following services you agree to these terms!"]
        default:
          return ["Unknown route", "Wrong url entered!"]
      }
    }
    switch (this.route.url) {
      case "/collector":
        return ["Tickets Initiated", "Manage all your tickets here"]
      case "/collector/init-collector":
        return ["Initiate tickets", "Generate new tickets here"]
      case "/collector/collect-collectable":
        return ["Collect honey", "Start Collecting honey from farmers here"]
      case "/collector/add":
        return ["Collect honey", "collect more honey batches to your ticket here"]
      case "/collector/update":
        return ["Add honey Information", "Add honey Information to your ticket here"]

      case "/collector/privacy":
        return ["Privacy & policy", "By using the following services you agree to these terms!"]
      default:
        return ["Unknown route", "Wrong url entered!"]
    }

  }
}
