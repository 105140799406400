import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getToken, removeToken, removeTokenType } from '../utils/preserved-storage';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class SharedService implements HttpInterceptor {
  authorizedToken: string = '';
  authorizedHeader = new HttpHeaders();
  constructor(
    public http: HttpClient,
    private router: Router
  ) { }
  decodeToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  getAuthToken(): void {
    const authToken = getToken();
    this.authorizedToken = authToken ? `JWT ${authToken}` : '';
    this.authorizedHeader = new HttpHeaders()
      .set('Authorization', this.authorizedToken);
  }

  post(url: string, dataToSend: any, extraHeaders?: any): any {
    this.getAuthToken();
    return this.http.post(url, dataToSend, extraHeaders ? extraHeaders : { headers: this.authorizedHeader });
  }

  put(url: string, dataToSend: any, extraHeaders?: any): any {
    this.getAuthToken();
    return this.http.put(url, dataToSend, extraHeaders ? extraHeaders : { headers: this.authorizedHeader });
  }

  get(url: string, extraHeaders?: any): any {
    this.getAuthToken();
    return this.http.get(url, extraHeaders ? extraHeaders : { headers: this.authorizedHeader });
  }

  delete(url: string, dataToSend?: any, extraHeaders?: any): any {
    this.getAuthToken();
    return this.http.delete(url, extraHeaders ? extraHeaders : { headers: this.authorizedHeader });

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = getToken();
    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken}`),
    });
    return next.handle(modifiedReq);
  }
  // Todo: What to do next when my session is about to expire in 5 minutes.
  // if (getToken()) {
  //   req = req.clone({
  //     headers: req.headers.set('Authorization', `JWT ${getToken()}`)
  //   });
  //   }
  //   return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
  //     if ((error.status === 401 || error.status === 403) && this.router.routerState.snapshot.url !== '') {
  //       console.log('logging out');
  //       removeToken();
  //       removeTokenType();
  //       // change to dispatch action
  //       localStorage.removeItem('state');
  //       // this.router.navigate(['/']);
  //       // this.router.navigate(['/login']);
  //       return throwError(() => '');
  //     } else {
  //       return throwError(() => error);
  //     }
  //   }));
  // }


}
