<style>
    .d-none {
        display: none;
    }

    .d-ab {
        display: block;
        position: absolute;
        width: 81%;
        min-height: 80vh;
        max-height: auto;
        top: 10%;
        background: #ececec;
        border-radius: 20px;
    }

    text-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .centered {
        font-family: sans-serif;
        font-size: 1.3em;
        height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .dropzone {
        height: 200px;
        display: table;
        width: 100%;
        background-color: #eee;
        border: dotted 1px #aaa;
    }

    input[type="file"] {
        display: none;
    }

    .textLink {
        background-color: #f45e61;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .textLink::before {
        content: "\f093";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: #fff;
        font-size: large;
        margin-right: 10px;
    }
</style>
<button class="btn bg-1" *ngIf="role != 'admin'"
    style="border-radius: 20px; font-size:medium;padding:10px 20px !important" (click)="initiate_ticket()">Start
    collecting honey for inspection
</button>
<div style="display: flex;flex-direction: column;">
    <div style="float: right; width:100%;text-align: right;margin-right: 5px; margin-bottom: 20px;">
        <input matInput class="form-control" style="width: 25%; float: right;" (keyup)="applyFilter($event)"
            placeholder="Filter...">
    </div>

    <div class="example-container mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource2" *ngIf="data">
            <ng-container matColumnDef="ticket_id">
                <mat-header-cell *matHeaderCellDef> ticket ID</mat-header-cell>
                <mat-cell *matCellDef="let row" title="copy ticket Id">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;"
                        (click)="copy(row.ticket_id)">
                        <p style="color: #5c5757;font-size: medium;font-weight: 600;">{{row.ticket_id}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="farmer_id">
                <mat-header-cell *matHeaderCellDef> Bottling Id</mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="update_current_ticket(row)">
                    <div style="display: flex;flex-direction: row;gap: 10px;">
                        <p style="color: #5c5757;font-size: medium;margin-top: 15px;" *ngIf="row.botteling_id">
                            {{row.botteling_id}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="collector_id">
                <mat-header-cell *matHeaderCellDef>collector Id</mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="update_current_ticket(row)">
                    <div style="display: flex;flex-direction: row;gap: 10px;">
                        <p style="color: #5c5757;font-size: medium;margin-top: 15px;">
                            {{row.collector_id}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_origin">
                <mat-header-cell *matHeaderCellDef> honey origin</mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="update_current_ticket(row)">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.honey_origin}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_weight">
                <mat-header-cell *matHeaderCellDef>honey weight</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;" *ngIf="row.honey_weight">{{row.honey_weight}} kg
                        </p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="honey_kind">
                <mat-header-cell *matHeaderCellDef> honey Type</mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="update_current_ticket(row)">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{row.honey_kind}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>status</mat-header-cell>
                <mat-cell *matCellDef="let row" (click)="update_current_ticket(row)">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">
                            <button class="btn-shadow btn btn-pill ps-3"
                                [ngClass]="row.blockchain === 'botteling' || row.status === 'botteling'?'bg-1':'bg-2'">{{row.status}}</button>
                        </p>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
                <mat-cell *matCellDef="let row">

                    <input type="file" style="display:none;" #img (change)="onFileSelected($event)"
                        accept=".png, .jpeg, .jpg">

                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 0px;">
                        <button title="add batch information" class="btn bg-3"
                            *ngIf="row.status==='botteling' && !row.cancelled_reason"
                            (click)="update_current_ticket(row)"><fa-icon [icon]="edit"></fa-icon></button>
                            <button title="add media" class="btn bg-3" *ngIf="row.status==='botteling' && !row.cancelled"
                            (click)="enable_dialog(row.ticket_id,row);addrow(row.ticket_id)"><fa-icon
                                [icon]="add_media"></fa-icon></button>
                        <button title="lock Data" class="btn bg-3"
                            *ngIf="row.blockchain === 'botteling' && row.status === 'botteling' && !row.cancelled_reason"
                            (click)="go_blockchain_botteling(row.ticket_id)"><fa-icon
                                [icon]="blockchain"></fa-icon></button>
                        <button class="btn btn-info" *ngIf="row.status === '111' && !row.cancelled_reason">Recorded on
                            blockchain</button>
                        <button title="add shipment information" class="btn btn-danger" *ngIf="row.cancelled"
                            (click)="showReason(row.cancelled_reason)">ticket has been cancelled by admin</button>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated">
                <mat-header-cell *matHeaderCellDef>Time Stamp</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div style="display: flex;flex-direction: row;gap: 10px;margin-top: 15px;">
                        <p style="color: #5c5757;font-size: medium;">{{formattedTimestamp(row.updated)}}</p>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <h2 style="padding: 20px;" *ngIf="!data">{{table_status}}</h2>
        <div class="card main-card mb-3">
            <div class="card-body">
                <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>

<div style="padding: 15px;" [ngClass]="!dialog?'d-none':'d-ab'">
    <div>
        <div class="uploadzone" appFileDragNDrop (filesChangeEmiter)="onFileChange($event)">
            <div class="text-wrapper">
                <div class="centered">
                    Drop here
                    <br>
                    (click to browse)
                </div>
            </div>
        </div>
    </div>
    <div>
        <mat-list>
            <h3 mat-subheader>Files: 
                (<span>Please specify one picture as a certificate</span>)
            </h3>
            <mat-list-item *ngFor="let file of files; let i = index">
                <img *ngIf="file.preview && !file.preview.includes('application/pdf')" [src]="file.preview"
                    alt="Image Preview" width="50" height="50" style="margin-right: 20px;">
                <div id="outerContainer" *ngIf="file.preview && file.preview.includes('application/')">
                    <button class="btn bg-3" style="margin-right: 20px;color: #fff;"
                        (click)="enable_dialog_2('','',file.preview)">View</button>
                </div>
                <h4 mat-line>{{ i+1}}</h4>
                <button mat-raised-button color="warn" (click)="deleteFile(i)">Delete</button>
                <mat-divider></mat-divider>
            </mat-list-item>
            <h2 mat-subheader> Uploaded media:</h2>
            <br>
            <mat-list-item *ngFor="let media of medias; let i = index">

                <button class="btn btn-success"
                    *ngIf="img_ticket.pdfs_botteling?.includes('https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media)"
                    style="margin-right: 20px;color: #fff;">Selected Certificate: </button>
                <button
                    *ngIf="img_ticket.pdfs_botteling?.includes('https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media)"
                    mat-raised-button color="warn" style="margin-right: 20px;color: #fff;"
                    (click)="remove_certificate(media)">
                    Deselect Certicate
                </button>
                <button class="btn bg-1" style="margin-right: 20px;color: #fff;"
                    *ngIf="!img_ticket.pdfs_botteling?.includes('https://honey-qsshrcodes.s3.ap-south-1.amazonaws.com/'+media)"
                    (click)="select_certificate(media)">Set
                    as Certificate</button>
                <div id="outerContainer" *ngIf="medias && media.includes('.pdf')">
                    <button class="btn bg-3" style="margin-right: 20px;color: #fff;"
                        (click)="enable_dialog_2('','','https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media)">
                        View
                    </button>
                </div>
                <img *ngIf="medias && !media.includes('.pdf')"
                    [src]="'https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media" alt="file" width="50" height="50"
                    style="margin-right: 20px;cursor: pointer;"
                    (click)="showimg('https://honey-qrcodes.s3.ap-south-1.amazonaws.com/'+media)">
                <button mat-raised-button style="margin-right: 20px;color: #fff;background-color: red;"
                    (click)="del_certificate(media)">
                    Delete media
                </button>
                <h4 mat-line>{{ i+1}}</h4>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
        <button class="btn bg-1" style="width: 80%;" (click)="add_media_fun();">Upload</button>
        <button class="btn btn-danger" style="width: 20%;" (click)="enable_dialog('','')">Cancel</button>
    </div>
</div>

<div style="padding: 15px;" [ngClass]="!dialog_2?'d-none':'d-ab'"
    style="height: 95vh !important;border: 1px solid #444;">
    <div id="outerContainer">
        <div class="pdf-container" style="margin-top: 30px;">
            <pdf-viewer [src]="current_pdf" [rotation]="0" [original-size]="false" [show-all]="true"
                [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false"
                [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
                style="width: 40vw; height: 80vh;">
            </pdf-viewer>
        </div>
        <div style="width: 100%;display:flex;justify-content: center;align-items: center;margin-top: 20px;">
            <button class="btn btn-danger" style="width: 20%;" (click)="enable_dialog_2('','','')">Go back</button>
        </div>
    </div>
</div>